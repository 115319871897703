import { useRouter } from 'next/router';

export const useGetRouterQueryValue = (): ((
  key: string,
) => string | undefined) => {
  const router = useRouter();

  return (key: string) => {
    const value = router.query[key];

    return Array.isArray(value) ? value[0] : value;
  };
};
