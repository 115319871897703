import { BarChart } from '@/src/components/foundations/Charts/BarChart';

import styles from './styles.module.scss';

import { Card } from '../Card';
import { Loading } from '../Loading';
import { CHART_SIZE } from '../const';

type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: Omit<React.ComponentProps<typeof BarChart>, 'size' | 'mode'>;
};

type Props = {
  searchContents: ContentsNormal | ContentsLoading;
};

export const AnnualReferralsCardPresenter: React.FC<Props> = (props) => (
  <Card>
    <div className={styles.header}>
      <div className={styles.title}>年次比較</div>
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.searchContents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={CHART_SIZE}
                {...props.searchContents.referrals}
                mode="stack"
              />
            );
        }
      })()}
    </div>
  </Card>
);
