import {
  PostChartVisitsRequestUnitTypeEnum,
  PostChartVisitsResponse,
} from '@/src/api/generated';
import { oneYearAgo, today } from '@/src/utils/date';

import { useReducer } from 'react';

type UnitType = Extract<
  PostChartVisitsRequestUnitTypeEnum,
  | PostChartVisitsRequestUnitTypeEnum.Month
  | PostChartVisitsRequestUnitTypeEnum.Year
>;

type Params = {
  unitType: UnitType;
  period: { startDate: Date; endDate: Date };
};

type State = {
  params: Params;
  visits: PostChartVisitsResponse['visits'] | null;
};

export const actionType = {
  setUnitType: 'setUnitType',
  setPeriod: 'setPeriod',
  setVisits: 'setVisits',
} as const;

type Action =
  | {
      type: typeof actionType.setUnitType;
      payload: Params['unitType'];
    }
  | {
      type: typeof actionType.setPeriod;
      payload: Params['period'];
    }
  | {
      type: typeof actionType.setVisits;
      payload: State['visits'];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setUnitType:
      return {
        params: {
          ...state.params,
          unitType: action.payload,
        },
        visits: null,
      };
    case actionType.setPeriod:
      return {
        params: {
          ...state.params,
          period: action.payload,
        },
        visits: null,
      };
    case actionType.setVisits:
      return {
        ...state,
        visits: action.payload,
      };
    default:
      return state;
  }
};

const createInitialState = (): State => {
  return {
    params: {
      unitType: PostChartVisitsRequestUnitTypeEnum.Month,
      period: {
        startDate: oneYearAgo(),
        endDate: today(),
      },
    },
    visits: null,
  };
};

export const useChartState = () => {
  const [state, dispatch] = useReducer(reducer, createInitialState());

  return { state, dispatch };
};
