import { FacilityTypeFilterChip } from '@/src/components/features/FilterChip/FacilityTypeFilterChip';
import { MedicalInstitutionLabelFilterChip } from '@/src/components/features/FilterChip/MedicalInstitutionLabelFilterChip';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { SaveAlt } from '@/src/components/foundations/DesignToken/Icons';
import { SelectBox } from '@/src/components/foundations/Forms/SelectBox';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { useAccount } from '@/src/hooks/useAccount';

import { State } from '../../reducer';

type Props = {
  filters: State['filters'];
  setFilters: (filters: State['filters']) => void;
  onClickCsvExport: VoidFunction;
};

export const FilterForm: React.FC<Props> = (props) => {
  const account = useAccount().account;
  const groupMedicalInstitutions =
    account.tenant?.groupMedicalInstitutions ?? [];

  return (
    <Stack align="stretch" gap={16} width="initial">
      <Cluster
        align="center"
        justify="space-between"
        wrap="nowrap"
        width="initial"
      >
        {/* 左側 */}
        <Cluster align="center" gap={16} wrap="nowrap" width="initial">
          <FacilityTypeFilterChip
            size="medium"
            selectedFacilityTypeIds={props.filters.facilityTypeIds.map(String)}
            onSubmit={(facilityTypeIds) =>
              props.setFilters({
                ...props.filters,
                facilityTypeIds: facilityTypeIds.map(Number),
              })
            }
          />
          <OwnDepartmentFilterChip
            size="medium"
            selectedOwnDepartmentIds={props.filters.ownDepartmentIds.map(
              String,
            )}
            onSubmit={(ownDepartmentIds) =>
              props.setFilters({
                ...props.filters,
                ownDepartmentIds: ownDepartmentIds.map(Number),
              })
            }
          />
          <MedicalInstitutionLabelFilterChip
            size="medium"
            labelFilterConditions={props.filters.labelFilterConditions}
            onSubmit={(labelSearchConditions) =>
              props.setFilters({
                ...props.filters,
                labelFilterConditions: labelSearchConditions,
              })
            }
          />
        </Cluster>
        {/* 右側 */}
        <Cluster align="center" gap={16} wrap="nowrap" width="initial">
          {groupMedicalInstitutions.length > 0 && (
            <SelectBox
              name="groupMedicalInstitution"
              width={343}
              value={props.filters.groupMedicalInstitutionId?.toString() ?? ''}
              onChange={(e) =>
                props.setFilters({
                  ...props.filters,
                  groupMedicalInstitutionId:
                    e.target.value === '' ? null : Number(e.target.value),
                })
              }
              placeHolder="グループ病院すべて"
              placeHolderDisabled={false}
              options={groupMedicalInstitutions.map((mi) => ({
                value: mi.id.toString(),
                label: mi.name,
              }))}
            />
          )}
          <Button
            color="basic"
            size="medium"
            onClick={props.onClickCsvExport}
            icon={{
              body: <SaveAlt />,
              position: 'left',
            }}
          >
            CSV出力
          </Button>
        </Cluster>
      </Cluster>
    </Stack>
  );
};
