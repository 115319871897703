import { groupsApi } from '@/src/api';
import {
  PostGroupsChartReferralsRequest as SearchParams,
  PostGroupsChartReferralsResponse as SearchResult,
  PostGroupsChartReferralsRequestUnitTypeEnum as UnitTypeEnum,
  GroupMedicalInstitution,
  NullableReferral,
  PostGroupsChartReferralsRequestUnitTypeEnum,
} from '@/src/api/generated';
import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { getColor } from '@/src/utils/chartColors';
import { currentYearAgo, today } from '@/src/utils/date';
import { formatDate } from '@/src/utils/formatDate';

import { AllReferralsCardPresenter } from './presenter';

import startOfMonth from 'date-fns/startOfMonth';
import { useState, useEffect } from 'react';

type BarChartProps = React.ComponentProps<typeof BarChart>;
type AllReferralsCardProps = React.ComponentProps<
  typeof AllReferralsCardPresenter
>;
type SearchContentsProps = AllReferralsCardProps['searchContents'];
type DateRangeProps = React.ComponentProps<typeof MonthRangePicker>;
type DateRangeOnSubmit = DateRangeProps['onChange'];
type SelectDateUnitProps = React.ComponentProps<
  typeof SelectDateUnit<'month' | 'year'>
>;
type SelectDateUnitOnSubmit = SelectDateUnitProps['onClickUnit'];

type Props = {
  groupMedicalIstitutions: GroupMedicalInstitution[];
  lastReferral: NullableReferral;
};

export const AllReferralsCard: React.FC<Props> = (props) => {
  const endDate = new Date(props.lastReferral.referralDate ?? today());
  const startDate = startOfMonth(currentYearAgo(endDate, 1));

  const INITIAL_VALUE_SEARCH_PARAMS = {
    groupMedicalInstitutionIds: props.groupMedicalIstitutions.map((m) => m.id),
    period: {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
    unitType: UnitTypeEnum.Month,
  } as const;

  const [searchParams, setSearchParams] = useState<
    SearchParams & {
      unitType: Exclude<
        PostGroupsChartReferralsRequestUnitTypeEnum,
        PostGroupsChartReferralsRequestUnitTypeEnum.Week
      >;
    }
  >(INITIAL_VALUE_SEARCH_PARAMS);
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  const throwFetchError = useFetchError();

  const createChartData = (referrals: SearchResult['referrals']) => {
    const data = referrals.map((r) => {
      return {
        name: r.label,
        stacks: r.items.reduce<Record<string, number>>((res, cur) => {
          res[cur.key] = cur.value;
          return res;
        }, {}),
      };
    });

    const { charts, payload } = props.groupMedicalIstitutions.reduce<
      Pick<Required<BarChartProps>, 'charts' | 'payload'>
    >(
      (res, cur, index) => {
        const color = getColor(index);

        res.charts.push({
          key: String(cur.id),
          name: cur.name,
          color,
        });

        res.payload.push({
          value: cur.name,
          color,
        });

        return res;
      },
      { charts: [], payload: [] },
    );

    return { data, charts, payload };
  };

  const request = async () => {
    if (searchParams === null) return;

    try {
      setSearchResult(null);
      const response = await groupsApi.postGroupsChartReferrals({
        postGroupsChartReferralsRequest: searchParams,
      });
      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  const dateRangeOnSubmit: DateRangeOnSubmit = (selectedRange) => {
    setSearchParams({
      ...searchParams,
      period: {
        startDate: formatDate(selectedRange.startDate),
        endDate: formatDate(selectedRange.endDate),
      },
    });
  };

  const dateRangeProps = (): DateRangeProps => {
    return {
      startDate: new Date(searchParams.period.startDate),
      endDate: new Date(searchParams.period.endDate),
      placement: 'bottomEnd',
      onChange: dateRangeOnSubmit,
    };
  };

  const selectDateUnitOnSubmit: SelectDateUnitOnSubmit = (selectedUnit) => {
    const unit = {
      month: UnitTypeEnum.Month,
      year: UnitTypeEnum.Year,
    } as const;
    setSearchParams({ ...searchParams, unitType: unit[selectedUnit] });
  };

  const selectDateUnitProps = (): SelectDateUnitProps => {
    return {
      selectedUnit: searchParams.unitType,
      onClickUnit: selectDateUnitOnSubmit,
      options: ['month', 'year'],
    };
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      groupMedicalInstitutionIds: props.groupMedicalIstitutions.map(
        (m) => m.id,
      ),
    });
  }, [props.groupMedicalIstitutions]);

  useEffect(() => {
    request();
  }, [searchParams]);

  const searchContentsProps = (): SearchContentsProps => {
    if (searchResult === null) {
      return {
        status: 'loading',
        dateRange: dateRangeProps(),
        selectDateUnit: selectDateUnitProps(),
      } as const;
    } else {
      return {
        status: 'normal',
        referrals: createChartData(searchResult.referrals),
        dateRange: dateRangeProps(),
        selectDateUnit: selectDateUnitProps(),
      } as const;
    }
  };

  return <AllReferralsCardPresenter searchContents={searchContentsProps()} />;
};
