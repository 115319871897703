import { medicalInstitutionReportsApi } from '@/src/api';
import {
  PostMedicalInstitutionReportAggregationResultsRequest,
  ReportAggregationResults,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

import { useAsyncFetch } from '../useAsyncFetch';
import { apiKey } from '../useAsyncFetch/apiKey';

export const usePostMedicalInstitutionReportAggregationResults = (
  postMedicalInstitutionReportAggregationResultsRequest: PostMedicalInstitutionReportAggregationResultsRequest,
): ReportAggregationResults | undefined => {
  const throwFetchError = useFetchError();

  const params = { postMedicalInstitutionReportAggregationResultsRequest };
  const response = useAsyncFetch(
    apiKey(
      'medicalInstitutionReportsApi',
      'postMedicalInstitutionReportAggregationResults',
      params,
    ),
    () =>
      medicalInstitutionReportsApi.postMedicalInstitutionReportAggregationResults(
        params,
      ),
    (error) => throwFetchError(error),
  );

  return response.data;
};
