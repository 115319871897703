import { PostChartReferralsComparisonResponse } from '@/src/api/generated';
import { today } from '@/src/utils/date';
import { fromFiscalYearStartToTargetDate } from '@/src/utils/date/fromFiscalYearStartToToday';

import { useReducer } from 'react';

type Params = {
  period: { startDate: Date; endDate: Date };
};

type State = {
  params: Params;
  allowedDateRange: { startDate: Date; endDate: Date };
  referrals: PostChartReferralsComparisonResponse['referrals'] | null;
};

export const actionType = {
  setPeriod: 'setPeriod',
  setReferrals: 'setReferrals',
} as const;

type Action =
  | {
      type: typeof actionType.setPeriod;
      payload: Params['period'];
    }
  | {
      type: typeof actionType.setReferrals;
      payload: PostChartReferralsComparisonResponse['referrals'] | null;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setPeriod:
      return {
        ...state,
        params: {
          period: action.payload,
        },
        referrals: null,
      };
    case actionType.setReferrals:
      return {
        ...state,
        referrals: action.payload,
      };
    default:
      return state;
  }
};

type InitialStateProps = {
  referralDate: string | null;
};
const createInitialState = (props: InitialStateProps): State => {
  const lastReferralDate = props.referralDate
    ? new Date(props.referralDate)
    : today();
  const initPeriod = fromFiscalYearStartToTargetDate(lastReferralDate);

  return {
    params: {
      period: initPeriod,
    },
    allowedDateRange: initPeriod,
    referrals: null,
  };
};

export const useChartState = (props: InitialStateProps) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
