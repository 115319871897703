import { dataAnalysisApi } from '@/src/api';
import {
  PostDataAnalysisRankingBothReferralsMonthlyRequest,
  PostDataAnalysisRankingBothReferralsMonthlyResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    params: PostDataAnalysisRankingBothReferralsMonthlyRequest,
    successCallback: (
      result: PostDataAnalysisRankingBothReferralsMonthlyResponse,
    ) => void,
  ) => {
    try {
      const response =
        await dataAnalysisApi.postDataAnalysisRankingBothReferralsMonthly({
          postDataAnalysisRankingBothReferralsMonthlyRequest: params,
        });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
