import { Schema } from './form';
import { State } from './reducer';

import compact from 'lodash.compact';

export const generateReportUrl = (
  params: { segmentIds: string[] } & Schema & State['filterParams'],
): string =>
  compact([
    '/medical_institutions_reports/new?template=segment',
    `segmentIds=${encodeURIComponent(JSON.stringify(params.segmentIds))}`,
    `period=${params.period}`,
    params.prefecture
      ? `prefecture=${encodeURIComponent(params.prefecture)}`
      : undefined,
    params.facilityTypeIds.length > 0
      ? `facilityTypeIds=${encodeURIComponent(
          JSON.stringify(params.facilityTypeIds),
        )}`
      : undefined,
    params.includeMedicalInstitutionWithNoReferral
      ? 'includeMedicalInstitutionWithNoReferral=true'
      : undefined,
  ]).join('&');
