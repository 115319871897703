import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { LIST } from '@/src/utils/chartColors';
import { isHalfYear, toHalfYearLabel } from '@/src/utils/date';

import styles from './styles.module.scss';

import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
} from 'recharts';

import { State } from '../../../../reducer';
import { toYearLabel } from '../../../../selector';
import { ChartData } from '../../../../types';

type Props = {
  dateUnit: State['dateUnit'];
  data: ChartData;
};
type XAxisTickProps = XAxisProps & { payload: { value: string } };

const X_AXIS_HEIGHT = 42;
const X_AXIS_MARGIN_BOTTOM = 16;
export const WIDTH_FOR_LEFT_Y_AXIS = 64;
export const WIDTH_FOR_RIGHT_Y_AXIS = 48;

export const Chart: React.FC<Props> = (props) => (
  <ResponsiveContainer width="100%">
    <ComposedChart
      data={props.data}
      style={{ fontSize: '14px' }}
      barSize={20}
      barGap={8}
      margin={{ top: 12 }}
      className={styles.svg}
    >
      <CartesianGrid vertical={false} cursor={'#ccc'} />
      <XAxis
        dataKey="period"
        axisLine={false}
        height={X_AXIS_HEIGHT + X_AXIS_MARGIN_BOTTOM}
        tickLine={false}
        tickMargin={6}
        tick={(tickProps: XAxisTickProps) => {
          const value = tickProps.payload.value;
          if (props.dateUnit === 'halfYear' && isHalfYear(value)) {
            const [labelYear, labelHalf] = toHalfYearLabel(value).split(' ');

            return (
              <text
                orientation={tickProps.orientation}
                height={tickProps.height}
                type={tickProps.type}
                stroke={tickProps.stroke}
                fill="var(--gray60-alpha)"
                textAnchor={tickProps.textAnchor}
              >
                {/* svgでは単純に改行させることはできないので、1行目と2行目を位置をずらして描画することで解決している */}
                <tspan x={tickProps.x} y={tickProps.y} dy="0.71em">
                  {labelYear}
                </tspan>
                <tspan
                  x={tickProps.x}
                  y={Number(tickProps.y ?? 0) + 18}
                  dy="0.71em"
                >
                  {labelHalf}
                </tspan>
              </text>
            );
          } else if (props.dateUnit === 'year') {
            return (
              <text
                orientation={tickProps.orientation}
                height={tickProps.height}
                type={tickProps.type}
                stroke={tickProps.stroke}
                fill="var(--gray60-alpha)"
                textAnchor={tickProps.textAnchor}
              >
                <tspan x={tickProps.x} y={tickProps.y} dy="0.71em">
                  {value}年度
                </tspan>
              </text>
            );
          } else {
            return <></>;
          }
        }}
        padding={{ left: 8, right: 8 }}
      />
      <YAxis
        yAxisId={1}
        orientation="left"
        tickFormatter={(v: number) => v.toLocaleString()}
        axisLine={false}
        tickLine={false}
        width={WIDTH_FOR_LEFT_Y_AXIS}
      />
      <YAxis
        yAxisId={2}
        orientation="right"
        unit="%"
        axisLine={false}
        tickLine={false}
        width={WIDTH_FOR_RIGHT_Y_AXIS}
      />
      <Tooltip
        labelFormatter={(v: string) => {
          if (props.dateUnit === 'halfYear' && isHalfYear(v)) {
            return toHalfYearLabel(v);
          } else if (props.dateUnit === 'year') {
            return toYearLabel(v);
          } else {
            return v;
          }
        }}
        formatter={(v: number) => {
          if (Number.isNaN(v)) return '-';
          return v.toLocaleString();
        }}
        // カーソルをグラフに載せたときの強調表示。デフォルトでは1pxの線だが、幅を伸ばすことで背景塗りつぶしとして見せている
        cursor={{ stroke: 'var(--gray5)', strokeWidth: 98 }}
      />
      <Legend
        // NOTE: BarChart同様に選択された項目デザインをカスタマイズしています。
        content={
          <Cluster
            gap={16}
            align="center"
            justify="center"
            wrap="nowrap"
            width="initial"
          >
            {[
              { key: 'referralCount', name: '紹介数' },
              { key: 'referralAdmissionCount', name: '紹介入院数' },
              { key: 'referralOperationCount', name: '紹介手術数' },
              { key: 'referralAdmissionRate', name: '紹介入院率' },
              { key: 'referralOperationRate', name: '紹介手術率' },
            ].map(({ key, name }, index) => (
              <Cluster
                key={key}
                gap={4}
                align="center"
                wrap="nowrap"
                width="initial"
              >
                <Surface width={16} height={16}>
                  <Symbols
                    cx={8}
                    cy={8}
                    size={260}
                    type="square"
                    fill={LIST[index]}
                  />
                </Surface>
                <span style={{ color: 'var(--gray60-alpha)' }}>{name}</span>
              </Cluster>
            ))}
          </Cluster>
        }
      />

      <Bar
        yAxisId={1}
        dataKey="referralCount"
        name="紹介数"
        label={{
          position: 'top',
          fontSize: 12,
          fill: LIST[0],
          formatter: (v: number) => v.toLocaleString(),
        }}
        fill={LIST[0]}
      />
      <Bar
        yAxisId={1}
        dataKey="referralAdmissionCount"
        name="紹介入院数"
        label={{
          position: 'top',
          fontSize: 12,
          fill: LIST[1],
          formatter: (v: number) => v.toLocaleString(),
        }}
        fill={LIST[1]}
      />
      <Bar
        yAxisId={1}
        dataKey="referralOperationCount"
        name="紹介手術数"
        label={{
          position: 'top',
          fontSize: 12,
          fill: LIST[2],
          formatter: (v: number) => v.toLocaleString(),
        }}
        fill={LIST[2]}
      />

      <Line
        yAxisId={2}
        dataKey="referralAdmissionRate"
        name="紹介入院率"
        fill={LIST[3]}
        stroke={LIST[3]}
        activeDot={false}
        unit="%"
        // Lineはlabel propsを受け取ることができるのだが、型定義に存在しない。
        // デストラクチャを利用することで型チェックを無効化してlabelを設定している。
        {...{
          label: {
            position: 'top',
            fontSize: 12,
            offset: 8,
            fill: LIST[3],
            formatter: (v: number) => `${v}%`,
          },
        }}
      />
      <Line
        yAxisId={2}
        dataKey="referralOperationRate"
        name="紹介手術率"
        fill={LIST[4]}
        stroke={LIST[4]}
        activeDot={false}
        unit="%"
        // Lineはlabel propsを受け取ることができるのだが、型定義に存在しない。
        // デストラクチャを利用することで型チェックを無効化してlabelを設定している。
        {...{
          label: {
            position: 'top',
            fontSize: 12,
            offset: 8,
            fill: LIST[4],
            formatter: (v: number) => `${v}%`,
          },
        }}
      />
    </ComposedChart>
  </ResponsiveContainer>
);
