import {
  PostChartVisitsResponse,
  PostCommunicationsSearchResultsRequestCommunicationTypeEnum as CommunicationTypeEnum,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { COLOR } from '@/src/utils/chartColors';

import { useRouter } from 'next/router';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const useCreateChartData = (
  visits: PostChartVisitsResponse['visits'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const router = useRouter();

  return {
    data: visits.map((v) => {
      return {
        name: v.label,
        stacks: {
          value: v.items[0]?.value ?? 0,
        },
        onClick: () => {
          router.push({
            pathname: '/communications',
            query: {
              startDate: v.startDate,
              endDate: v.endDate,
              communicationType: CommunicationTypeEnum.Visit,
            },
          });
        },
      };
    }),
    charts: [
      {
        key: 'value',
        name: '全担当者',
        color: COLOR.PRIMARY,
      },
    ],
    payload: [
      {
        value: '全担当者',
        color: COLOR.PRIMARY,
      },
    ],
  };
};
