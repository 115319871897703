import { NullableReferral } from '@/src/api/generated';
import { formatDate } from '@/src/utils/formatDate';

import { useAction } from './action';
import { DateRangeAnnualReferralsCardPresenter } from './presenter';
import { useChartState, actionType } from './reducer';

import { useEffect } from 'react';

type Props = {
  lastReferral: NullableReferral;
};

export const DateRangeAnnualReferralsCard: React.FC<Props> = (props) => {
  const { state, dispatch } = useChartState({
    referralDate: props.lastReferral.referralDate,
  });
  const { request } = useAction();

  useEffect(() => {
    request(
      {
        period: {
          startDate: formatDate(state.params.period.startDate),
          endDate: formatDate(state.params.period.endDate),
        },
      },
      (res) => {
        dispatch({
          type: actionType.setReferrals,
          payload: res.referrals,
        });
      },
    );
  }, [state.params]);

  return (
    <DateRangeAnnualReferralsCardPresenter
      dateRange={{
        ...state.params.period,
        allowedDateRange: state.allowedDateRange,
        onChange: (selectedRange) => {
          dispatch({
            type: actionType.setPeriod,
            payload: selectedRange,
          });
        },
      }}
      contents={
        state.referrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              referrals: state.referrals,
            }
      }
    />
  );
};
