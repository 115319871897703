import {
  PostChartReferralsComparisonMonthlyResponse,
  GetOwnDepartmentsResponse,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { getColor } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  referrals: PostChartReferralsComparisonMonthlyResponse['referrals'],
  departments: GetOwnDepartmentsResponse['departments'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const threeYearsData = referrals.flatMap((r) => {
    return r.items.map((item) => {
      return {
        name: item.key,
        value: item.value,
        label: r.label + '-' + String(item.department),
      };
    });
  });
  const departmentIdsList = departments.map((_) => _.key);

  return {
    data: Array.from(new Set(referrals[0].items.map((d) => d.key))).map(
      (item) => {
        const stacks = threeYearsData.reduce<Record<string, number>>(
          (res, cur) => {
            if (item === cur.name) {
              const k = cur.label;
              res[k] = cur.value;
            }

            return res;
          },
          {},
        );

        return {
          name: item,
          stacks,
        };
      },
    ),
    charts: Array.from(new Set(referrals.map((_) => _.label)))
      .reverse()
      .flatMap((label) => {
        const res = departmentIdsList.map((id) => {
          return {
            key: label + '-' + id,
            name: label,
            color: getColor(departmentIdsList.indexOf(String(id))),
          };
        });

        return res;
      }),
    payload: departments.map((item) => {
      return {
        value: item.value,
        color: getColor(departmentIdsList.indexOf(item.key)),
      };
    }),
  };
};
