import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonResponse,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { getColor } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  referrals: PostChartReferralsComparisonResponse['referrals'],
  departments: GetOwnDepartmentsResponse['departments'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const departmentIdsList = departments.map((_) => _.key);

  return {
    data: Array.from(new Set(referrals.map((i) => i.label)))
      .map((label) => {
        const stacks = referrals.reduce<Record<string, number>>((res, cur) => {
          if (label === cur.label) {
            const k = cur.key + ' - ' + String(cur.department);
            res[k] = cur.value;
          }
          return res;
        }, {});

        return {
          name: label,
          stacks,
        };
      })
      .reverse(),
    charts: referrals.map((r) => {
      return {
        key: r.key + ' - ' + String(r.department),
        name: r.key,
        color: getColor(departmentIdsList.indexOf(String(r.department))),
      };
    }),
    payload: departments.map((item) => {
      return {
        value: item.value,
        color: getColor(departmentIdsList.indexOf(item.key)),
      };
    }),
  };
};
