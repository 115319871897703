import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Pagination } from '@/src/components/foundations/Navigations/Pagination';

import { Header } from './components/Header';
import { Table } from './components/Table';

type Props = {
  header: React.ComponentProps<typeof Header>;
  table: React.ComponentProps<typeof Table>;
  pagination: React.ComponentProps<typeof Pagination>;
};

export const MonthlyMedicalInstitutionReferralsPresenter: React.FC<Props> = (
  props,
) => {
  return (
    <Section width="1170px">
      <Card>
        <Header {...props.header} />
        <Table {...props.table} />
        {props.pagination.lastPage !== 0 && (
          <Section padding="24px">
            <Cluster justify="flex-end">
              <Pagination {...props.pagination} />
            </Cluster>
          </Section>
        )}
      </Card>
    </Section>
  );
};
