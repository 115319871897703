import { PostChartVisitsResponse, Staff } from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { StaffFilterChip } from '@/src/components/features/FilterChip/StaffFilterChip';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: Omit<React.ComponentProps<typeof MonthRangePicker>, 'placement'>;
  dateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
  staffs: {
    selected: Staff[];
    onSubmit: React.ComponentProps<typeof StaffFilterChip>['onSubmit'];
  };
};

type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  visits: PostChartVisitsResponse['visits'];
};

type Props = BaseProps & {
  contents: ContentsLoading | ContentsNormal;
};

export const StaffVisitsCardPresenter: React.FC<Props> = (props) => {
  return (
    <Card>
      <div className={styles.header}>
        <div className={styles.title}>担当者別推移</div>
        <div className={styles.control}>
          <MonthRangePicker {...props.dateRange} placement="bottomEnd" />
        </div>
      </div>
      <div className={styles.subControls}>
        <StaffFilterChip
          selectedStaffIds={props.staffs.selected.map((_) => _.id.toString())}
          onSubmit={props.staffs.onSubmit}
        />
        <SelectDateUnit {...props.dateUnit} />
      </div>
      <div className={styles.chart}>
        {((): JSX.Element => {
          switch (props.contents.status) {
            case 'loading':
              return <Loading />;
            case 'normal':
              return (
                <BarChart
                  size={CHART_SIZE}
                  mode="stack"
                  {...createChartData(
                    props.contents.visits,
                    props.staffs.selected,
                  )}
                />
              );
          }
        })()}
      </div>
    </Card>
  );
};
