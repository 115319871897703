import { useGetOwnDepartments } from '@/src/hooks/fetcher/useGetOwnDepartments';
import { useAccount } from '@/src/hooks/useAccount';

export const useDefaultOwnDepartmentId = () => {
  const { account } = useAccount();
  const ownDepartments = useGetOwnDepartments();

  if (ownDepartments.data && ownDepartments.data.departments.length > 0) {
    const ownDepartmentIds = ownDepartments.data.departments.map((d) => d.key);
    const firstOwnDepartmentId = ownDepartmentIds[0];

    if (account.ownDepartmentId != null) {
      const ownDepartmentId = account.ownDepartmentId.toString();
      return ownDepartmentIds.includes(ownDepartmentId)
        ? ownDepartmentId
        : firstOwnDepartmentId;
    } else {
      return firstOwnDepartmentId;
    }
  } else {
    return null;
  }
};
