import { prefectures } from '@/src/utils/prefectures';

import { SearchFormInputMap } from '../../SearchForm/presenter';

export const checkIsSubmitDisabled = (
  inputMap: SearchFormInputMap,
): boolean => {
  if (!inputMap.prefecture) return false;

  return !prefectures.some(({ name }) => name === inputMap.prefecture);
};
