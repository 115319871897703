import { tenantsApi } from '@/src/api';
import { GetStaffsRequest } from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';

export const useGetStaffs = (request?: GetStaffsRequest) => {
  const throwFetchError = useFetchError();

  const response = useAsyncFetch(
    apiKey('tenantsApi', 'getStaffs', request ?? undefined),
    () => tenantsApi.getStaffs(request ?? undefined),
  );

  if (response.error != null) {
    throwFetchError(response.error);
  }

  return response;
};
