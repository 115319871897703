import { medicalInstitutionReportsApi } from '@/src/api';
import {
  NullableSegmentationSetting,
  PostMedicalInstitutionReportAggregationResultsRequest,
  ReportAggregationResults,
  Segment,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

import { ChartData } from './type';

export const useAction = (segmentationSetting: NullableSegmentationSetting) => {
  const throwFetchError = useFetchError();

  const getChartData = async (
    postMedicalInstitutionReportAggregationResultsRequest: PostMedicalInstitutionReportAggregationResultsRequest,
  ): Promise<ChartData> => {
    const params = { postMedicalInstitutionReportAggregationResultsRequest };
    try {
      return asChartData(
        segmentationSetting,
        await medicalInstitutionReportsApi.postMedicalInstitutionReportAggregationResults(
          params,
        ),
      );
    } catch (error) {
      return throwFetchError(500);
    }
  };

  return { getChartData };
};

const asChartData = (
  setting: NullableSegmentationSetting,
  data: ReportAggregationResults,
): ChartData => {
  const getStyle = createGetStyle(setting.segments);

  return {
    segments: setting.segments.map((segment) => ({
      label: segment.label,
      count: getValue(data, segment.id),
      xThreshold: segment.referralCountMoreEqual ?? 0,
      yThreshold: segment.referralAdmissionRateMoreEqual ?? undefined,
      ...getStyle(segment),
    })),
    restSegment: {
      label: setting.restSegmentLabel,
      count: getValue(data, ''),
      background: '#F3F3F4',
      fontColor: 'gray',
    },
  };
};

const getValue = (data: ReportAggregationResults, key: string): number => {
  const dataValue = data.results.find(({ rowValue }) => rowValue === key)
    ?.dataValues['total'][0];

  return dataValue ? Number(dataValue) : 0;
};

const RGB_PATTERNS = ['54, 162, 134', '78, 94, 242'];
const createGetStyle = (segments: Segment[]) => {
  const xThresholds = Array.from(
    new Set(segments.map((segment) => segment.referralCountMoreEqual ?? 0)),
  ).sort((a, b) => (a > b ? 1 : -1));
  const yThresholds = Array.from(
    new Set(
      segments.map((segment) => segment.referralAdmissionRateMoreEqual ?? 0),
    ),
  ).sort((a, b) => (a > b ? 1 : -1));
  const alphaMap = xThresholds.reduce<{ [key: number]: number }>(
    (acc, xThreshold, index) => ({
      ...acc,
      [xThreshold]: (1.0 / xThresholds.length) * (index + 1),
    }),
    {},
  );
  const rgbMap = yThresholds.reduce<{ [key: number]: string }>(
    (acc, yThreshold, index) => ({
      ...acc,
      [yThreshold]: RGB_PATTERNS[index % RGB_PATTERNS.length],
    }),
    {},
  );

  return (
    segment: Segment,
  ): { background: string; fontColor: 'gray' | 'white' } => ({
    background: `rgba(${
      rgbMap[segment.referralAdmissionRateMoreEqual ?? 0]
    }, ${alphaMap[segment.referralCountMoreEqual ?? 0].toFixed(1)})`,
    fontColor:
      alphaMap[segment.referralCountMoreEqual ?? 0] >= 0.8 ? 'white' : 'gray',
  });
};
