import { referralsApi } from '@/src/api';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';

export const useGetLastReferral = () => {
  const throwFetchError = useFetchError();

  const response = useAsyncFetch(
    apiKey('referralsApi', 'getLastReferral'),
    () => referralsApi.getLastReferral(),
  );

  if (response.error != null) {
    throwFetchError(response.error);
  }

  return response;
};
