import {
  PostDataAnalysisRankingBothReferralsMonthlyResponse as BothReferralsMonthlyResponse,
  PostDataAnalysisRankingBothReferralsMonthlyRequestSortEnum as Sort,
} from '@/src/api/generated';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Link } from '@/src/components/foundations/Utils/Link';
import { emptyText } from '@/src/utils/emptyText';
import { toDisplayFormat } from '@/src/utils/runningStatus';

import { monthCount, monthDifferenceCount } from './selector';
import styles from './styles.module.scss';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LocationOn from '@mui/icons-material/LocationOn';
import clsx from 'clsx';

type Loading = {
  status: 'loading';
};
type Normal = {
  status: 'normal';
  rankings: BothReferralsMonthlyResponse['rankings'];
};

type Props = {
  sort: {
    value: Sort;
    onChange: VoidFunction;
  };
  contents: Loading | Normal;
};

const COL_SPAN_COUNT = 4;

export const Table: React.FC<Props> = (props) => {
  return (
    <table className={styles.base}>
      <thead className={styles.head}>
        <tr className={styles.tr}>
          <th className={styles.medicalInstitution}>医療機関名</th>
          <th className={styles.referralCounts}>紹介</th>
          <th className={styles.reverseReferralCounts}>逆紹介</th>
          <th className={styles.differenceCounts}>
            <button
              onClick={props.sort.onChange}
              className={styles.differenceCountsSort}
            >
              <span>
                紹介と
                <br />
                逆紹介の差
              </span>
              {props.sort.value === Sort.Desc ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )}
            </button>
          </th>
        </tr>
      </thead>
      <tbody className={styles.body}>
        {(() => {
          switch (props.contents.status) {
            case 'loading':
              return (
                <tr>
                  <td colSpan={COL_SPAN_COUNT} className={styles.borderTop}>
                    <CenteringContainer className={styles.centeringContainer}>
                      <LoadingCircle />
                    </CenteringContainer>
                  </td>
                </tr>
              );
            case 'normal':
              return props.contents.rankings.length > 0 ? (
                props.contents.rankings.map((ranking, index) => (
                  <tr className={styles.tr} key={index}>
                    <td>
                      <div className={styles.medicalInstitutionNameText}>
                        {ranking.medicalInstitution.name ? (
                          <Link
                            href={`/crms/${ranking.medicalInstitution.id}`}
                            isOpenAnotherTab
                          >
                            {toDisplayFormat(
                              ranking.medicalInstitution.runningStatus,
                            )}
                            {ranking.medicalInstitution.name}
                          </Link>
                        ) : (
                          emptyText()
                        )}
                      </div>
                      <div className={styles.medicalInstitutionAddress}>
                        <LocationOn className={styles.locationIcon} />
                        <span className={styles.medicalInstitutionAddressText}>
                          {ranking.medicalInstitution.address
                            ? ranking.medicalInstitution.address
                            : emptyText()}
                        </span>
                      </div>
                    </td>
                    <td>
                      {ranking.referralCounts.map((c, index) => (
                        <p key={index}>{monthCount(c.month, c.value)}</p>
                      ))}
                    </td>
                    <td>
                      {ranking.reverseReferralCounts.map((c, index) => (
                        <p key={index}>{monthCount(c.month, c.value)}</p>
                      ))}
                    </td>
                    <td>
                      {ranking.differenceCounts.map((c, index) => (
                        <p
                          key={index}
                          className={clsx(
                            index === 0 && styles.differenceCountsText,
                          )}
                        >
                          {monthDifferenceCount(c.month, c.value)}
                        </p>
                      ))}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={COL_SPAN_COUNT} className={styles.borderTop}>
                    <h2 className={styles.emptyText}>
                      該当する医療機関がありません
                    </h2>
                  </td>
                </tr>
              );
          }
        })()}
      </tbody>
    </table>
  );
};
