import { Tab } from '@/src/components/foundations/Navigations/Tab';

import styles from './styles.module.scss';

export type Templates =
  | 'summary'
  | 'referrals'
  | 'visits'
  | 'segments'
  | 'referralAdmissions'
  | 'effectivenessVerifications'
  | 'reverseReferrals'
  | 'rankings'
  | 'groups';

type TabProps = {
  values: {
    label: string;
    value: Templates;
  }[];
  selectedValue: Templates;
  onClickValue: (value: Templates) => void;
  pathName: string;
}; // ここは、TABを参照する形にしたいが、ジェネリクスを使用している都合上、手動で定義するしかない。4.9系からある satisfies が使えると型の保証が可能になる

type Props = {
  dataAnalysisContents: TabProps;
  selectedTemplate: JSX.Element | undefined;
};

export const DataAnalysisPresenter: React.FC<Props> = (props) => {
  return (
    <>
      <div className={styles.tab}>
        <Tab<Templates> {...props.dataAnalysisContents} />
      </div>
      {props.selectedTemplate}
    </>
  );
};
