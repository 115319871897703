import {
  PostChartVisitsDoctorInterviewRatesRequestUnitTypeEnum as UnitType,
  PostChartVisitsDoctorInterviewRatesResponse,
} from '@/src/api/generated';
import { oneYearAgo, today } from '@/src/utils/date';

import { useReducer } from 'react';

type Params = {
  unitType: UnitType;
  period: { startDate: Date; endDate: Date };
};

type State = {
  params: Params;
  doctorInterviewRates:
    | PostChartVisitsDoctorInterviewRatesResponse['doctorInterviewRates']
    | null;
};

export const actionType = {
  setUnitType: 'setUnitType',
  setPeriod: 'setPeriod',
  setDoctorInterviewRates: 'setDoctorInterviewRates',
} as const;

type Action =
  | {
      type: typeof actionType.setUnitType;
      payload: Params['unitType'];
    }
  | {
      type: typeof actionType.setPeriod;
      payload: Params['period'];
    }
  | {
      type: typeof actionType.setDoctorInterviewRates;
      payload: State['doctorInterviewRates'];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setUnitType:
      return {
        params: {
          ...state.params,
          unitType: action.payload,
        },
        doctorInterviewRates: null,
      };
    case actionType.setPeriod:
      return {
        params: {
          ...state.params,
          period: action.payload,
        },
        doctorInterviewRates: null,
      };
    case actionType.setDoctorInterviewRates:
      return {
        ...state,
        doctorInterviewRates: action.payload,
      };
    default:
      return state;
  }
};

const createInitialState = (): State => {
  return {
    params: {
      unitType: UnitType.Month,
      period: {
        startDate: oneYearAgo(),
        endDate: today(),
      },
    },
    doctorInterviewRates: null,
  };
};

export const useChartState = () => {
  const [state, dispatch] = useReducer(reducer, createInitialState());

  return { state, dispatch };
};
