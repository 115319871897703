import { useResponsive } from '@/src/hooks/useResponsive';

import { CenteringContainerPresenter } from './presenter';
import { SpCenteringContainerPresenter } from './spPresenter';

type PcProps = React.ComponentProps<typeof CenteringContainerPresenter>;
type SpProps = React.ComponentProps<typeof SpCenteringContainerPresenter>;
type Props = PcProps & SpProps;

export const CenteringContainer: React.FC<Props> = (props) => {
  const responsive = useResponsive();

  return responsive.isSp ? (
    <SpCenteringContainerPresenter {...props} />
  ) : (
    <CenteringContainerPresenter {...props} />
  );
};
