import { groupsApi } from '@/src/api';
import {
  PostGroupsChartReferralsComparisonRequest as SearchParams,
  PostGroupsChartReferralsComparisonResponse as SearchResult,
  GroupMedicalInstitution,
  NullableReferral,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { getColor } from '@/src/utils/chartColors';
import { today } from '@/src/utils/date';
import { fromFiscalYearStartToTargetDate } from '@/src/utils/date/fromFiscalYearStartToToday';
import { formatDate } from '@/src/utils/formatDate';

import { DateRangeAnnualReferralsCardPresenter } from './presenter';

import { useState, useEffect } from 'react';

type BarChartProps = React.ComponentProps<typeof BarChart>;
type DateRangeAnnualReferralsCardProps = React.ComponentProps<
  typeof DateRangeAnnualReferralsCardPresenter
>;
type SearchContentsProps = DateRangeAnnualReferralsCardProps['searchContents'];
type DateRangeProps = React.ComponentProps<typeof MonthRangePicker>;
type DateRangeOnSubmit = DateRangeProps['onChange'];

type Props = {
  groupMedicalIstitutions: GroupMedicalInstitution[];
  lastReferral: NullableReferral;
};

export const DateRangeAnnualReferralsCard: React.FC<Props> = (props) => {
  const period = fromFiscalYearStartToTargetDate(
    new Date(props.lastReferral.referralDate ?? today()),
  );

  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    groupMedicalInstitutionIds: props.groupMedicalIstitutions.map((m) => m.id),
    period: {
      startDate: formatDate(period.startDate),
      endDate: formatDate(period.endDate),
    },
  });

  const throwFetchError = useFetchError();

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      groupMedicalInstitutionIds: props.groupMedicalIstitutions.map(
        (m) => m.id,
      ),
    });
  }, [props.groupMedicalIstitutions]);

  useEffect(() => {
    request();
  }, [searchParams]);

  const request = async () => {
    try {
      setSearchResult(null);

      const response = await groupsApi.postGroupsChartReferralsComparison({
        postGroupsChartReferralsComparisonRequest: searchParams,
      });

      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  const dateRangeOnSubmit: DateRangeOnSubmit = (selectedRange) => {
    setSearchParams({
      ...searchParams,
      period: {
        startDate: formatDate(selectedRange.startDate),
        endDate: formatDate(selectedRange.endDate),
      },
    });
  };

  const dateRangeProps = (): DateRangeProps => {
    return {
      startDate: new Date(searchParams.period.startDate),
      endDate: new Date(searchParams.period.endDate),
      placement: 'bottomEnd',
      onChange: dateRangeOnSubmit,
    };
  };

  const createChartData = (referrals: SearchResult['referrals']) => {
    const referralsDesc = referrals.reverse();

    const data = [...new Set(referrals.map((r) => r.label))].map((label) => {
      return {
        name: label,
        stacks: referralsDesc.reduce<Record<string, number>>((res, cur) => {
          if (cur.label === label) {
            res[cur.groupMedicalInstitutionId] = cur.value;
          }
          return res;
        }, {}),
      };
    });

    const { charts, payload } = props.groupMedicalIstitutions.reduce<
      Pick<Required<BarChartProps>, 'charts' | 'payload'>
    >(
      (res, cur, index) => {
        const color = getColor(index);

        res.charts.push({
          key: String(cur.id),
          name: cur.name,
          color,
        });

        res.payload.push({
          value: cur.name,
          color,
        });

        return res;
      },
      { charts: [], payload: [] },
    );

    return { data, charts, payload };
  };

  const searchContentsProps = (): SearchContentsProps => {
    if (searchResult === null) {
      return {
        status: 'loading',
        thisYearAprilFirstDate: period.startDate,
        dateRange: dateRangeProps(),
      } as const;
    } else {
      return {
        status: 'normal',
        thisYearAprilFirstDate: period.startDate,
        dateRange: dateRangeProps(),
        referrals: createChartData(searchResult.referrals),
      } as const;
    }
  };

  return (
    <DateRangeAnnualReferralsCardPresenter
      searchContents={searchContentsProps()}
    />
  );
};
