import { HalfYear } from '@/src/types/date';

import { currentFiscalYearAgo, today } from '.';

import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';

export const isHalfYear = (value: string): value is HalfYear => {
  return /^\d{4}H[12]$/.test(value);
};

export const currentHalfYear = (): HalfYear => toHalfYear(today());

export const toHalfYear = (date: Date): HalfYear => {
  const month = date.getMonth() + 1;

  return `${currentFiscalYearAgo(date, 0).getFullYear()}H${
    month >= 4 && month < 10 ? '1' : '2'
  }`;
};

export const halfYearsAgo = (value: HalfYear, halfYears: number): HalfYear => {
  const [year, half] = value.split('H');

  const remainder = halfYears % 2;
  if (remainder === 1) {
    return half === '1'
      ? `${Number(year) - (halfYears - remainder) / 2 - 1}H2`
      : `${Number(year) - (halfYears - remainder) / 2}H1`;
  } else if (remainder === -1) {
    return half === '1'
      ? `${Number(year) - (halfYears - remainder) / 2}H2`
      : `${Number(year) - (halfYears - remainder) / 2 + 1}H1`;
  } else {
    return half === '1'
      ? `${Number(year) - halfYears / 2}H1`
      : `${Number(year) - halfYears / 2}H2`;
  }
};

export const differenceInHalfYears = (
  left: HalfYear,
  right: HalfYear,
): number => {
  const [leftYear, leftHalf] = left.split('H');
  const [rightYear, rightHalf] = right.split('H');

  if (leftHalf === '1') {
    return rightHalf === '1'
      ? (Number(leftYear) - Number(rightYear)) * 2
      : (Number(leftYear) - Number(rightYear)) * 2 - 1;
  } else {
    return rightHalf === '1'
      ? (Number(leftYear) - Number(rightYear)) * 2 + 1
      : (Number(leftYear) - Number(rightYear)) * 2;
  }
};

export const toMonthRange = (
  value: HalfYear,
): { startMonth: string; endMonth: string } => {
  const [year, half] = value.split('H');

  return half === '1'
    ? { startMonth: `${year}-04`, endMonth: `${year}-09` }
    : { startMonth: `${year}-10`, endMonth: `${Number(year) + 1}-03` };
};

export const toHalfYearStartDate = (value: HalfYear): Date => {
  const [year, half] = value.split('H');

  return half === '1' ? new Date(`${year}-04-01`) : new Date(`${year}-10-01`);
};

export const toHalfYearLabel = (value: HalfYear): string => {
  const [year, half] = value.split('H');

  return `${year}年度 ${half === '1' ? '上期' : '下期'}`;
};

export const generateSelectableHelfYearOptions = (
  from: Date,
  to: Date,
): { label: string; value: HalfYear }[] =>
  generateHalfYearSequence(toHalfYear(from), toHalfYear(to)).map((value) => ({
    value,
    label: toHalfYearLabel(value),
  }));

export const generateHalfYearSequence = (
  from: HalfYear,
  to: HalfYear,
): HalfYear[] => {
  const firstHalfStartDate = toHalfYearStartDate(from);
  const lastHalfStartDate = toHalfYearStartDate(to);

  const halfYearStartDates = [];
  for (
    let date = firstHalfStartDate;
    isAfter(lastHalfStartDate, date) || isEqual(lastHalfStartDate, date);
    date = add(date, { months: 6 })
  ) {
    halfYearStartDates.push(new Date(date));
  }

  return halfYearStartDates.map((startDate) => toHalfYear(startDate));
};
