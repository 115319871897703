import {
  GetOwnDepartmentsResponse,
  PostChartBothReferralsComparisonResponse,
} from '@/src/api/generated';
import {
  Loading,
  WIDE_CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { BarChartTooltip } from '@/src/components/foundations/Charts/BarChartTooltip';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';

import { createChartData, createChartTooltipData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: Pick<
    React.ComponentProps<typeof MonthRangePicker>,
    'startDate' | 'endDate' | 'onChange'
  >;
  dateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
  departments: {
    selected: GetOwnDepartmentsResponse['departments'];
    onSubmit: React.ComponentProps<typeof OwnDepartmentFilterChip>['onSubmit'];
  };
};

type LoadingProps = {
  status: 'loading';
};
type NormalProps = {
  status: 'normal';
  bothReferrals: PostChartBothReferralsComparisonResponse['bothReferrals'];
};

type Props = BaseProps & {
  contents: LoadingProps | NormalProps;
};

export const BothReferralsCardPresenter: React.FC<Props> = (props) => (
  <div className={styles.card}>
    <div className={styles.header}>
      <div className={styles.title}>紹介数対比</div>
      <div className={styles.control}>
        <MonthRangePicker placement="bottomEnd" {...props.dateRange} />
      </div>
    </div>
    <div className={styles.subControls}>
      <OwnDepartmentFilterChip
        selectedOwnDepartmentIds={props.departments.selected.map((_) => _.key)}
        onSubmit={props.departments.onSubmit}
      />
      <SelectDateUnit {...props.dateUnit} />
    </div>
    <div className={styles.chart}>
      {(() => {
        switch (props.contents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={WIDE_CHART_SIZE}
                mode="simple"
                tooltip={{
                  content: (data) => (
                    <BarChartTooltip {...createChartTooltipData(data)} />
                  ),
                }}
                {...createChartData(props.contents.bothReferrals)}
              />
            );
        }
      })()}
    </div>
  </div>
);
