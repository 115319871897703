import { AnnualReferralRankingContainer } from './AnnualReferralRankingContainer';
import { MonthlyReferralRankingContainer } from './MonthlyReferralRankingContainer';
import { TotalCountRankingContainer } from './TotalCountRankingContainer';
import styles from './styles.module.scss';

export const RankingsPresenter: React.FC = () => (
  <>
    <div className={styles.totalCount}>
      <TotalCountRankingContainer />
    </div>
    <div className={styles.increaseCount}>
      <MonthlyReferralRankingContainer />
      <AnnualReferralRankingContainer />
    </div>
  </>
);
