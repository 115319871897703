import {
  MedicalInstitutionReportFilterCondition,
  MedicalInstitutionReportFilterConditionMatcher,
} from '@/src/api/generated';
import { MedicalInstitutionLabelFilterConditionSchema } from '@/src/types/medicalInstitutionLabel';

import { createMedicalInstitutionLabelReportItemDefinitionKey } from '../createMedicalInstitutionLabelReportItemDefinitionKey';

export const medicalInstitutionLabel2FilterCondition = (
  c: MedicalInstitutionLabelFilterConditionSchema,
): MedicalInstitutionReportFilterCondition => ({
  item: {
    key: createMedicalInstitutionLabelReportItemDefinitionKey(c.labelId),
  },
  matchingGroups: toMedicalInstitutionLabelMatchingGroups(c),
});

const toMedicalInstitutionLabelMatchingGroups = (
  c: MedicalInstitutionLabelFilterConditionSchema,
): MedicalInstitutionReportFilterConditionMatcher[] => {
  if (c.textCondition?.value) {
    return [{ contain: c.textCondition?.value }];
  } else if (c.integerCondition?.conditionType === 'moreEqual') {
    return [{ moreEqual: c.integerCondition?.value }];
  } else if (c.integerCondition?.conditionType === 'lessEqual') {
    return [{ lessEqual: c.integerCondition?.value }];
  } else if (c.selectionCondition?.conditionType === 'in') {
    return [{ _in: c.selectionCondition?.ids.map(Number) }];
  } else if (c.selectionCondition?.conditionType === 'notIn') {
    return [{ notIn: c.selectionCondition?.ids.map(Number) }];
  } else if (c.selectionCondition?.conditionType === 'haveAny') {
    return [{ haveAny: c.selectionCondition?.ids.map(Number) }];
  } else if (c.selectionCondition?.conditionType === 'haveAll') {
    return [{ haveAll: c.selectionCondition?.ids.map(Number) }];
  } else if (c.selectionCondition?.conditionType === 'notHaveAll') {
    return [{ notHaveAll: c.selectionCondition?.ids.map(Number) }];
  } else {
    throw new Error('invalid condition');
  }
};
