import { segmentsApi } from '@/src/api';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';

export const useGetSegmentSetting = () => {
  const throwFetchError = useFetchError();

  const response = useAsyncFetch(
    apiKey('segmentsApi', 'getSegmentSetting'),
    () => segmentsApi.getSegmentSetting(),
  );

  if (response.error != null) {
    throwFetchError(response.error);
  }

  return response;
};
