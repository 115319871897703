import {
  GetOwnDepartmentsResponse,
  PostChartReferralsRequestUnitTypeEnum,
  PostChartReferralsResponse,
} from '@/src/api/generated';
import { currentYearAgo, today } from '@/src/utils/date';

import startOfMonth from 'date-fns/startOfMonth';
import { useReducer } from 'react';

type UnitType = Extract<
  PostChartReferralsRequestUnitTypeEnum,
  | PostChartReferralsRequestUnitTypeEnum.Month
  | PostChartReferralsRequestUnitTypeEnum.Year
>;

type Params = {
  unitType: UnitType;
  period: { startDate: Date; endDate: Date };
  departments: GetOwnDepartmentsResponse['departments'];
};

type State = {
  params: Params;
  referrals: PostChartReferralsResponse['referrals'] | null;
};

export const actionType = {
  setUnitType: 'setUnitType',
  setPeriod: 'setPeriod',
  setDepartments: 'setDepartments',
  setReferrals: 'setReferrals',
} as const;

type Action =
  | {
      type: typeof actionType.setUnitType;
      payload: Params['unitType'];
    }
  | {
      type: typeof actionType.setPeriod;
      payload: Params['period'];
    }
  | {
      type: typeof actionType.setDepartments;
      payload: Params['departments'];
    }
  | {
      type: typeof actionType.setReferrals;
      payload: PostChartReferralsResponse['referrals'] | null;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setUnitType:
      return {
        params: {
          ...state.params,
          unitType: action.payload,
        },
        referrals: null,
      };
    case actionType.setPeriod:
      return {
        params: {
          ...state.params,
          period: action.payload,
        },
        referrals: null,
      };
    case actionType.setDepartments:
      return {
        params: {
          ...state.params,
          departments: action.payload,
        },
        referrals: null,
      };
    case actionType.setReferrals:
      return {
        ...state,
        referrals: action.payload,
      };
    default:
      return state;
  }
};

type InitialStateProps = {
  referralDate: string | null;
  departments: GetOwnDepartmentsResponse['departments'];
};
const createInitialState = (props: InitialStateProps): State => {
  const endDate = props.referralDate ? new Date(props.referralDate) : today();
  const startDate = startOfMonth(currentYearAgo(endDate, 1));

  return {
    params: {
      unitType: PostChartReferralsRequestUnitTypeEnum.Month,
      period: {
        startDate,
        endDate,
      },
      departments: props.departments,
    },
    referrals: null,
  };
};

export const useChartState = (props: InitialStateProps) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
