import { PostChartVisitsResponse } from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';

import { useCreateChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: Omit<React.ComponentProps<typeof MonthRangePicker>, 'placement'>;
  dateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
};

type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  visits: PostChartVisitsResponse['visits'];
};

type Props = BaseProps & {
  contents: ContentsLoading | ContentsNormal;
};

export const AllVisitsCardPresenter: React.FC<Props> = (props) => {
  const chartData = useCreateChartData(
    props.contents.status === 'normal' ? props.contents.visits : [],
  );

  return (
    <Card>
      <div className={styles.header}>
        <div className={styles.title}>全体推移</div>
        <div className={styles.control}>
          <MonthRangePicker {...props.dateRange} placement="bottomEnd" />
        </div>
      </div>
      <div className={styles.subControls}>
        <SelectDateUnit {...props.dateUnit} />
      </div>
      <div className={styles.chart}>
        {((): JSX.Element => {
          switch (props.contents.status) {
            case 'loading':
              return <Loading />;
            case 'normal':
              return <BarChart size={CHART_SIZE} mode="stack" {...chartData} />;
          }
        })()}
      </div>
    </Card>
  );
};
