import { PostChartVisitsDoctorInterviewRatesResponse } from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { LineChart } from '@/src/components/foundations/Charts/LineChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: Omit<React.ComponentProps<typeof MonthRangePicker>, 'placement'>;
  dateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
};

type ContentsLoading = {
  status: 'loading';
};

type ContentsNormal = {
  status: 'normal';
  doctorInterviewRates: PostChartVisitsDoctorInterviewRatesResponse['doctorInterviewRates'];
};

type Props = BaseProps & {
  contents: ContentsLoading | ContentsNormal;
};

export const AllDoctorInterviewRateCardPresenter: React.FC<Props> = (props) => (
  <Card>
    <div className={styles.header}>
      <div className={styles.title}>全体医師面談設定率</div>
      <div className={styles.control}>
        <MonthRangePicker {...props.dateRange} placement="bottomEnd" />
      </div>
    </div>
    <div className={styles.subControls}>
      <SelectDateUnit {...props.dateUnit} />
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.contents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <LineChart
                size={CHART_SIZE}
                {...createChartData(props.contents.doctorInterviewRates)}
              />
            );
        }
      })()}
    </div>
  </Card>
);
