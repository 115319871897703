import {
  MedicalInstitutionReportDefinitionAggregationDataAggregationMethodEnum,
  MedicalInstitutionReportFilterCondition,
  MedicalInstitutionReportItemEnabledAggregationMethodEnum,
  MedicalInstitutionReportItemKey,
  PostMedicalInstitutionReportAggregationResultsRequest,
  NullableSegmentationSetting,
  GetOwnDepartmentsResponseDepartments as OwnDepartment,
} from '@/src/api/generated';

import compact from 'lodash.compact';
import { v4 as uuid } from 'uuid';

export const toRequestParameter = (
  setting: NullableSegmentationSetting,
  ownDepartments: OwnDepartment[],
  params: {
    period: string;
    prefecture: string | null;
    facilityTypeIds: string[];
    includeMedicalInstitutionWithNoReferral: boolean;
  },
): PostMedicalInstitutionReportAggregationResultsRequest => {
  const {
    period,
    prefecture,
    facilityTypeIds,
    includeMedicalInstitutionWithNoReferral,
  } = params;
  const setmentIdItemKey = uuid();
  const { startMonth, endMonth } = period2StartMonthAndEndMonth(period);

  const excludedOwnDepartmentIdSet = new Set<string>(
    setting.excludedOwnDepartmentIds,
  );

  const filterOwnDepartmentIds =
    setting.excludedOwnDepartmentIds.length > 0
      ? ownDepartments
          .map((d) => d.key)
          .filter((id) => !excludedOwnDepartmentIdSet.has(id))
          .map((id) => Number(id))
      : undefined;

  return {
    displayItems: [
      {
        itemKey: setmentIdItemKey,
        key: MedicalInstitutionReportItemKey.SegmentId,
        aggregationConditions: { period },
      },
    ],
    filterConditions: compact<MedicalInstitutionReportFilterCondition>([
      // 都道府県検索
      prefecture
        ? {
            item: { key: MedicalInstitutionReportItemKey.Address },
            matchingGroups: [{ startWith: prefecture }],
          }
        : undefined,

      // "紹介数0件の医療機関を含む"
      includeMedicalInstitutionWithNoReferral
        ? undefined
        : {
            item: {
              key: MedicalInstitutionReportItemKey.ReferralCount,
              aggregationMethod:
                MedicalInstitutionReportItemEnabledAggregationMethodEnum.Sum,
              aggregationConditions: {
                startMonth,
                endMonth,
                ownDepartmentIds: filterOwnDepartmentIds,
              },
            },
            matchingGroups: [{ moreEqual: 1 }],
          },

      // 施設タイプ
      facilityTypeIds.length > 0
        ? {
            item: { key: MedicalInstitutionReportItemKey.FacilityTypeIds },
            matchingGroups: [{ haveAny: facilityTypeIds.map(Number) }],
          }
        : undefined,
    ]),
    aggregationRow: { itemKey: setmentIdItemKey },
    aggregationData: [
      {
        aggregationMethod:
          MedicalInstitutionReportDefinitionAggregationDataAggregationMethodEnum.Count,
      },
    ],
  };
};

const period2StartMonthAndEndMonth = (
  period: string,
): { startMonth: string; endMonth: string } => {
  const found = period.match(/(\d{4})H(\d)/);
  if (!found) throw new Error(`Invalid period: ${period}`);

  const fiscalYear = Number(found[1]);
  const half = Number(found[2]);

  return half === 1
    ? { startMonth: `${fiscalYear}-04`, endMonth: `${fiscalYear}-09` }
    : { startMonth: `${fiscalYear}-10`, endMonth: `${fiscalYear + 1}-03` };
};
