import {
  sub,
  format,
  addMonths,
  startOfMonth as dStartOfMonth,
  differenceInMonths as dDifferenceInMonths,
  lastDayOfMonth,
  add,
} from 'date-fns';

export * from './fromFiscalYearStartToToday';
export * from './halfYear';
export * from './fiscalYear';

export const today = (): Date => {
  return new Date();
};

export const oneYearAgo = (): Date => {
  return xYearsAgo(1);
};

export const xYearsAgo = (x: number): Date => {
  return sub(today(), { years: x });
};

export const currentYearAgo = (date: Date, years: number): Date => {
  return sub(date, { years });
};

export const currentFiscalYear = (): Date => {
  return currentFiscalYearAgo(today(), 0);
};

export const oneFiscalYearAgo = (): Date => {
  return sub(today(), { years: 1, months: 3 });
};

export const currentFiscalYearAgo = (date: Date, years: number): Date => {
  return sub(date, { years, months: 3 });
};

export const currentMonthLastDay = (): Date => {
  return sub(new Date(format(addMonths(today(), 1), 'yyyy-MM')), { days: 1 });
};

export const oneMonthAgo = (): Date => {
  return sub(today(), { months: 1 });
};

export const currentMonthAgo = (date: Date, months: number): Date => {
  return sub(date, { months });
};

export const startOfMonth = dStartOfMonth;
export const differenceInMonths = dDifferenceInMonths;

export const lastDateTimeOfMonth = () =>
  // ex: today: 2024-01-10 09:00:00 -> return: 2024-01-31 23:59:59
  add(lastDayOfMonth(today()), { days: 1, seconds: -1 });
