import { Staff } from '@/src/api/generated';
import { CheckboxList } from '@/src/components/blocks/CheckboxList';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';
import { AllToggleAvailableFormProps } from '@/src/types/form';

import styles from './styles.module.scss';

export type ModalInputMap = {
  staffIds: string[];
};

type Props = {
  form: Omit<
    AllToggleAvailableFormProps<ModalInputMap, 'staffIds'>,
    'isDisabled'
  >;
  isShown: boolean;
  onClose: VoidFunction;
  staffOptions: Staff[];
};

export const StaffFilterModalPresenter: React.FC<Props> = (props) => (
  <ModalDialog
    isShown={props.isShown}
    size="wide"
    title="担当者"
    okButton={{
      children: '保存する',
      onClick: props.form.onSubmit,
    }}
    cancelButton={{
      children: '閉じる',
      onClick: props.onClose,
      color: 'basic',
    }}
    subActionButton={{
      children: props.form.staffIds.isAllSelected
        ? props.form.staffIds.allClear.label
        : props.form.staffIds.allSelect.label,
      onClick: props.form.staffIds.isAllSelected
        ? props.form.staffIds.allClear.onClick
        : props.form.staffIds.allSelect.onClick,
    }}
  >
    <form>
      <div className={styles.checkboxesWrapper}>
        <CheckboxList
          options={props.staffOptions.map((o) => ({
            label: o.name,
            value: String(o.id),
          }))}
          gap={{ row: 16, column: 16 }}
          {...props.form.staffIds}
        />
      </div>
    </form>
  </ModalDialog>
);
