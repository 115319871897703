export const ITEM_TYPES = [
  'referralCount',
  'referralAdmissionCount',
  'referralOperationCount',
  'referralAdmissionRate',
  'referralOperationRate',
] as const;

export const TABLE_ROW_TYPES = [
  'hospital',
  'clinic',
  'others',
  'total',
] as const;

type Result = { period: string } & Record<(typeof ITEM_TYPES)[number], number>;

export type ChartData = Result[];
export type TableData = Record<(typeof TABLE_ROW_TYPES)[number], Result[]>;
