import { chartsApi } from '@/src/api';
import {
  PostChartVisitsNextActionRatesRequest,
  PostChartVisitsNextActionRatesResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    params: PostChartVisitsNextActionRatesRequest,
    successCallback: (result: PostChartVisitsNextActionRatesResponse) => void,
  ) => {
    if (params.staffIds === null || params.staffIds.length === 0) return;

    try {
      const response = await chartsApi.postChartVisitsNextActionRates({
        postChartVisitsNextActionRatesRequest: params,
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
