import { PostChartReferralsComparisonAnnualResponse } from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { COLOR, PREVIOUS_COLOR } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  referrals: PostChartReferralsComparisonAnnualResponse['referrals'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  return {
    data: referrals
      .map((r) => {
        const stacks = r.items.reduce<Record<string, number>>((res, cur) => {
          if (cur.key) {
            res[cur.key] = cur.value;
          }

          return res;
        }, {});

        return {
          name: r.label,
          stacks,
        };
      })
      .reverse(),
    charts: referrals.flatMap((r) => {
      if (r.items.length === 1) {
        return {
          key: r.items[0].key,
          name: r.items[0].key,
          color: COLOR.PRIMARY,
        };
      } else {
        return r.items.map((item) => ({
          key: item.key,
          name: item.key,
          color: item.isRemainingPeriod
            ? PREVIOUS_COLOR.TIME_BEFORE_LAST
            : PREVIOUS_COLOR.LAST_TIME,
        }));
      }
    }),
    payload: [
      {
        value: '全診療科',
        color: COLOR.PRIMARY,
      },
    ],
  };
};
