export * from './Card';
export * from './Loading';

export const CHART_SIZE = {
  height: 251,
  width: 529,
  legendHeight: 36,
};

export const WIDE_CHART_SIZE = {
  height: 251,
  width: 1122,
  legendHeight: 36,
};
