import {
  MedicalInstitutionReportItemEnabledAggregationMethodEnum,
  MedicalInstitutionReportItemForAggregation,
  MedicalInstitutionReportItemKey,
  PostMedicalInstitutionReportAggregationResultsRequest,
} from '@/src/api/generated';

import {
  createAggregationData,
  createFilterConditions,
} from './toRequestParameterCommon';

import { State } from '../reducer';

export const toRequestParameterForFiscalYear = (
  fiscalYears: number[],
  filters: State['filters'],
): PostMedicalInstitutionReportAggregationResultsRequest => {
  const dataItems = createDataItemsForFiscalYearUnit(fiscalYears, filters);

  return {
    displayItems: [
      // 集計行用の項目
      {
        itemKey: MedicalInstitutionReportItemKey.OneFacilityTypeId,
        key: MedicalInstitutionReportItemKey.OneFacilityTypeId,
      },
      ...dataItems,
    ],
    filterConditions: createFilterConditions(filters),
    aggregationRow: {
      itemKey: MedicalInstitutionReportItemKey.OneFacilityTypeId,
    },
    aggregationData: createAggregationData(dataItems),
  };
};

const createDataItemsForFiscalYearUnit = (
  fiscalYears: number[],
  filters: State['filters'],
): MedicalInstitutionReportItemForAggregation[] =>
  fiscalYears.flatMap((year) =>
    [
      MedicalInstitutionReportItemKey.ReferralCount,
      MedicalInstitutionReportItemKey.ReferralAdmissionCount,
      MedicalInstitutionReportItemKey.ReferralOperationCount,
    ].map(
      (key): MedicalInstitutionReportItemForAggregation => ({
        itemKey: toItemKey(year.toString(), key),
        key,
        aggregationMethod:
          MedicalInstitutionReportItemEnabledAggregationMethodEnum.Sum,
        aggregationConditions: {
          fiscalYear: year.toString(),
          ...(filters.ownDepartmentIds.length >= 1
            ? { ownDepartmentIds: filters.ownDepartmentIds }
            : {}),
          ...(filters.groupMedicalInstitutionId !== null
            ? {
                tenantMedicalInstitutionIds: [
                  filters.groupMedicalInstitutionId,
                ],
              }
            : {}),
        },
      }),
    ),
  );

const toItemKey = (
  period: string,
  key: MedicalInstitutionReportItemKey,
): string => `${period}-${key}`;
