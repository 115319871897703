import { PostChartReferralsComparisonMonthlyResponse } from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { COLOR, PREVIOUS_COLOR } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

const LOCAL_COLORS = [
  PREVIOUS_COLOR.TIME_BEFORE_LAST,
  PREVIOUS_COLOR.LAST_TIME,
  COLOR.PRIMARY,
];

export const createChartData = (
  referrals: PostChartReferralsComparisonMonthlyResponse['referrals'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const threeYearsData = referrals.flatMap((r) => {
    return r.items.map((item) => {
      return {
        name: item.key,
        value: item.value,
        label: r.label,
      };
    });
  });

  return {
    data: referrals[0].items.map((item) => {
      const stacks = threeYearsData.reduce<Record<string, number>>(
        (res, cur) => {
          if (item.key === cur.name) {
            const k = cur.label;
            res[k] = cur.value;
          }
          return res;
        },
        {},
      );

      return {
        name: item.key,
        stacks,
      };
    }),
    charts: Array.from(new Set(referrals.map((i) => i.label)))
      .reverse()
      .flatMap((label, index) => {
        return {
          key: label,
          name: label,
          color: LOCAL_COLORS[index],
        };
      }),
    payload: [
      {
        value: '全診療科',
        color: COLOR.PRIMARY,
      },
    ],
  };
};
