import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonMonthlyResponse,
} from '@/src/api/generated';

import { useReducer } from 'react';

type Params = {
  departments: GetOwnDepartmentsResponse['departments'];
};

type State = {
  params: Params;
  referrals: PostChartReferralsComparisonMonthlyResponse['referrals'] | null;
};

export const actionType = {
  setDepartments: 'setDepartments',
  setReferrals: 'setReferrals',
} as const;

type Action =
  | {
      type: typeof actionType.setDepartments;
      payload: Params['departments'];
    }
  | {
      type: typeof actionType.setReferrals;
      payload: PostChartReferralsComparisonMonthlyResponse['referrals'] | null;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setDepartments:
      return {
        params: {
          departments: action.payload,
        },
        referrals: null,
      };
    case actionType.setReferrals:
      return {
        ...state,
        referrals: action.payload,
      };
    default:
      return state;
  }
};

type InitialStateProps = {
  departments: GetOwnDepartmentsResponse['departments'];
};
const createInitialState = (props: InitialStateProps): State => {
  return {
    params: {
      departments: props.departments,
    },
    referrals: null,
  };
};

export const useChartState = (props: InitialStateProps) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
