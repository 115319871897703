import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonMonthlyResponse,
} from '@/src/api/generated';
import {
  Loading,
  WIDE_CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: PostChartReferralsComparisonMonthlyResponse['referrals'];
};

type Props = {
  contents: ContentsNormal | ContentsLoading;
  departments: {
    selected: GetOwnDepartmentsResponse['departments'];
    onSubmit: React.ComponentProps<typeof OwnDepartmentFilterChip>['onSubmit'];
  };
};

export const MonthlyOwnDepartmentsUnitReferralsCardPresenter: React.FC<
  Props
> = (props) => (
  <div className={styles.card}>
    <div className={styles.header}>
      <div className={styles.title}>診療科別月次比較</div>
      <div className={styles.control}>
        <OwnDepartmentFilterChip
          selectedOwnDepartmentIds={props.departments.selected.map(
            (_) => _.key,
          )}
          onSubmit={props.departments.onSubmit}
        />
      </div>
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.contents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={WIDE_CHART_SIZE}
                mode="mix"
                {...createChartData(
                  props.contents.referrals,
                  props.departments.selected,
                )}
              />
            );
        }
      })()}
    </div>
  </div>
);
