import { Staff } from '@/src/api/generated';

import { AllDoctorInterviewRateCard } from './components/AllDoctorInterviewRateCard';
import { AllNextActionRateCard } from './components/AllNextActionRateCard';
import { AllVisitsCard } from './components/AllVisitsCard';
import { DoctorInterviewRateCard } from './components/DoctorInterviewRateCard';
import { NextActionRateCard } from './components/NextActionRateCard';
import { StaffVisitsCard } from './components/StaffVisitsCard';
import styles from './styles.module.scss';

type Props = {
  staffs: Staff[];
};

export const VisitsPresenter: React.FC<Props> = (props) => (
  <div className={styles.base}>
    <div className={styles.cardsWrapper}>
      <AllVisitsCard />
      <div className={styles.marginRight} />
      <StaffVisitsCard {...props} />
    </div>
    <div className={styles.cardsWrapper}>
      <AllNextActionRateCard />
      <div className={styles.marginRight} />
      <NextActionRateCard {...props} />
    </div>
    <div className={styles.cardsWrapper}>
      <AllDoctorInterviewRateCard />
      <div className={styles.marginRight} />
      <DoctorInterviewRateCard {...props} />
    </div>
  </div>
);
