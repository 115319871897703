import { PostChartReferralsResponse } from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: Pick<
    React.ComponentProps<typeof MonthRangePicker>,
    'startDate' | 'endDate' | 'onChange'
  >;
  dateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
};
type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: PostChartReferralsResponse['referrals'];
};

type Props = BaseProps & {
  searchContents: ContentsLoading | ContentsNormal;
};

export const AllReferralsCardPresenter: React.FC<Props> = (props) => (
  <Card>
    <div className={styles.header}>
      <div className={styles.title}>全体推移</div>
      <div className={styles.control}>
        <MonthRangePicker placement="bottomEnd" {...props.dateRange} />
      </div>
    </div>
    <div className={styles.subControls}>
      <SelectDateUnit {...props.dateUnit} />
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.searchContents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={CHART_SIZE}
                mode="stack"
                {...createChartData(props.searchContents.referrals)}
              />
            );
        }
      })()}
    </div>
  </Card>
);
