import { useForm } from '@/src/hooks/useForm';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const schema = z.object({
  prefecture: z.string().nullable(),
  area: z.string().nullable(),
});
export type Schema = z.infer<typeof schema>;

type Props = {
  prefecture?: string;
};

export const useSearchForm = (props: Props) => {
  const { form } = useForm(schema, {
    shouldUnregister: false,
    defaultValues: {
      prefecture: props.prefecture ?? null,
      area: null,
    },
    resolver: zodResolver(schema),
  });

  return { form };
};
