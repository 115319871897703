import { VISIBLE_ITEMS_COUNT } from './const';

import { useReducer } from 'react';

type State = {
  itemsCount: number;
  currentItemIndex: number;
};

type Action = { type: 'SLIDE_NEXT' } | { type: 'SLIDE_PREV' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SLIDE_NEXT':
      return {
        ...state,
        currentItemIndex: Math.min(
          state.currentItemIndex + 1,
          state.itemsCount - VISIBLE_ITEMS_COUNT,
        ),
      };
    case 'SLIDE_PREV':
      return {
        ...state,
        currentItemIndex: Math.max(state.currentItemIndex - 1, 0),
      };
    default:
      return state;
  }
};

export const useCurrentItemIndexState = (itemsCount: number) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(itemsCount));
  return { state, dispatch };
};

const createInitialState = (itemsCount: number): State => ({
  itemsCount,
  currentItemIndex: 0,
});
