import {
  Loading,
  WIDE_CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { ChartSlider } from '@/src/components/foundations/Charts/ChartSlider';

import styles from './styles.module.scss';

import React from 'react';

type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  chartData: {
    data: React.ComponentProps<typeof BarChart>['data'];
    payload: React.ComponentProps<typeof BarChart>['payload'];
    charts: React.ComponentProps<typeof BarChart>['charts'];
  };
  chartSlider: React.ComponentProps<typeof ChartSlider>;
};

type Props = {
  contents: ContentsNormal | ContentsLoading;
};

export const DepartmentUnitCardPresenter: React.FC<Props> = (props) => {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.title}>診療科別比較</div>
        <div className={styles.control}>
          {props.contents.status === 'normal' && (
            <ChartSlider {...props.contents.chartSlider} />
          )}
        </div>
      </div>
      <div className={styles.chart}>
        {((): JSX.Element => {
          switch (props.contents.status) {
            case 'loading':
              return <Loading />;
            case 'normal':
              return (
                <BarChart
                  size={WIDE_CHART_SIZE}
                  mode="mixStack"
                  data={props.contents.chartData.data}
                  payload={props.contents.chartData.payload}
                  charts={props.contents.chartData.charts}
                  shortenNameLength
                />
              );
          }
        })()}
      </div>
    </div>
  );
};
