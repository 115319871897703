import { ChartData } from './type';

import { useReducer } from 'react';

export type State = {
  filterParams: {
    period: string;
    facilityTypeIds: string[];
    includeMedicalInstitutionWithNoReferral: boolean;
  };
  chartData: ChartData | undefined;
};

export const actionType = {
  setPeriod: 'setPeriod',
  setFacilityTypeIds: 'setFacilityTypeIds',
  toggleIncludeMedicalInstitutionWithNoReferral:
    'toggleIncludeMedicalInstitutionWithNoReferral',
  setChartData: 'setChartData',
  resetChartData: 'resetChartData',
} as const;

type Action =
  | {
      type: typeof actionType.setFacilityTypeIds;
      payload: { facilityTypeIds: string[] };
    }
  | {
      type: typeof actionType.toggleIncludeMedicalInstitutionWithNoReferral;
    }
  | {
      type: typeof actionType.setPeriod;
      payload: { period: string };
    }
  | {
      type: typeof actionType.setChartData;
      payload: { chartData: ChartData };
    }
  | {
      type: typeof actionType.resetChartData;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setFacilityTypeIds:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          facilityTypeIds: action.payload.facilityTypeIds,
        },
      };
    case actionType.toggleIncludeMedicalInstitutionWithNoReferral:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          includeMedicalInstitutionWithNoReferral:
            !state.filterParams.includeMedicalInstitutionWithNoReferral,
        },
      };
    case actionType.setPeriod:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          period: action.payload.period,
        },
      };
    case actionType.setChartData:
      return {
        ...state,
        chartData: action.payload.chartData,
      };
    case actionType.resetChartData:
      return {
        ...state,
        chartData: undefined,
      };
    default:
      assertIsNever(action);
  }
};

type Props = {
  facilityTypeIds: string[];
  period: string;
};

export const useSegmentState = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    filterParams: {
      period: props.period,
      facilityTypeIds: props.facilityTypeIds,
      includeMedicalInstitutionWithNoReferral: false,
    },
    chartData: undefined,
  });

  return { state, dispatch };
};
