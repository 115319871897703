import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { Pagination } from '@/src/components/foundations/Navigations/Pagination';

import { Header } from './Header';
import { Table } from './Table';
import styles from './styles.module.scss';

type Props = {
  visitTable: React.ComponentProps<typeof Table>;
  header: React.ComponentProps<typeof Header>;
  pagination: React.ComponentProps<typeof Pagination>;
};

export const EffectivenessVerificationsPresenter: React.FC<Props> = (props) => (
  <>
    <div className={styles.cardWrapper}>
      <Card>
        <div className={styles.headWrapper}>
          <Header {...props.header} />
        </div>
        <Table {...props.visitTable} />
      </Card>
    </div>

    {props.pagination.lastPage !== 0 && (
      <div className={styles.paginationWrapper}>
        <Pagination {...props.pagination} />
      </div>
    )}
  </>
);
