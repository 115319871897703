import {
  PostChartVisitsRequestUnitTypeEnum,
  PostChartVisitsResponse,
  Staff,
} from '@/src/api/generated';
import { AccountState } from '@/src/hooks/useAccount';
import { oneYearAgo, today } from '@/src/utils/date';

import { useReducer } from 'react';

type UnitType = Extract<
  PostChartVisitsRequestUnitTypeEnum,
  | PostChartVisitsRequestUnitTypeEnum.Month
  | PostChartVisitsRequestUnitTypeEnum.Year
>;

type Params = {
  unitType: UnitType;
  period: { startDate: Date; endDate: Date };
  staffs: Staff[];
};

type State = {
  params: Params;
  visits: PostChartVisitsResponse['visits'] | null;
};

export const actionType = {
  setUnitType: 'setUnitType',
  setPeriod: 'setPeriod',
  setStaffs: 'setStaffs',
  setVisits: 'setVisits',
} as const;

type Action =
  | {
      type: typeof actionType.setUnitType;
      payload: Params['unitType'];
    }
  | {
      type: typeof actionType.setPeriod;
      payload: Params['period'];
    }
  | {
      type: typeof actionType.setStaffs;
      payload: Params['staffs'];
    }
  | {
      type: typeof actionType.setVisits;
      payload: State['visits'];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setUnitType:
      return {
        params: {
          ...state.params,
          unitType: action.payload,
        },
        visits: null,
      };
    case actionType.setPeriod:
      return {
        params: {
          ...state.params,
          period: action.payload,
        },
        visits: null,
      };
    case actionType.setStaffs:
      return {
        params: {
          ...state.params,
          staffs: action.payload,
        },
        visits: null,
      };
    case actionType.setVisits:
      return {
        ...state,
        visits: action.payload,
      };
    default:
      return state;
  }
};

type Props = {
  account: AccountState;
  staffs: Staff[];
};

const createInitialState = (props: Props): State => {
  return {
    params: {
      unitType: PostChartVisitsRequestUnitTypeEnum.Month,
      period: {
        startDate: oneYearAgo(),
        endDate: today(),
      },
      //NOTE: ログインユーザーを初期値とし、なければスタッフの先頭の要素を初期値とする
      staffs: [
        props.staffs.find((s) => (props.account?.id ?? 0) === s.id) ??
          props.staffs[0],
      ],
    },
    visits: null,
  };
};

export const useChartState = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
