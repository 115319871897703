import { PrefectureSearchTextField } from '@/src/components/blocks/PrefectureSearchTextField';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Input } from '@/src/components/foundations/Forms/Input';
import { FormProps } from '@/src/types/form';

import styles from './styles.module.scss';

import Search from '@mui/icons-material/Search';

export type SearchFormInputMap = {
  prefecture: string;
  area: string;
};

type Props = {
  form: FormProps<SearchFormInputMap> & {
    prefecture: {
      value: SearchFormInputMap['prefecture'];
      setValue: (value: SearchFormInputMap['prefecture']) => void;
    };
  };
};

export const SearchFormPresenter: React.FC<Props> = (props) => (
  <form className={styles.base}>
    <div className={styles.prefecture}>
      <PrefectureSearchTextField
        optionIdPrefix="prefecture"
        mode="normal"
        {...props.form.prefecture}
      />
    </div>
    <div className={styles.area}>
      <Input placeholder="地域" type="text" {...props.form.area} />
    </div>
    <Button
      color="primary"
      icon={{ body: <Search />, position: 'left' }}
      isDisabled={props.form.isDisabled}
      onClick={props.form.onSubmit}
    >
      検索
    </Button>
  </form>
);
