import { PostDataAnalysisRankingReferralMonthlyRequest as SearchRequest } from '@/src/api/generated';

import { RankingContainer } from '../RankingContainer';

type SearchParams = Omit<SearchRequest, 'month'> & { month: Date };
type RankingContainerProps = React.ComponentProps<typeof RankingContainer>;

type Props = {
  searchParams: SearchParams;
  onChangeSearchParams: (newSearchParams: SearchParams) => void;
} & Pick<RankingContainerProps, 'isSearching' | 'rankingTable' | 'monthPicker'>;

export const MonthlyReferralRankingContainerPresenter: React.FC<Props> = (
  props,
) => <RankingContainer<SearchParams> {...props} />;
