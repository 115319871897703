import { groupsApi } from '@/src/api';
import {
  PostGroupsChartReferralsComparisonMonthlyResponse as SearchResult,
  GroupMedicalInstitution,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { getColor } from '@/src/utils/chartColors';

import { MonthlyReferralsCardPresenter } from './presenter';

import { useState, useEffect } from 'react';

type BarChartProps = React.ComponentProps<typeof BarChart>;
type Payload = BarChartProps['payload'];
type MonthlyReferralsCardProps = React.ComponentProps<
  typeof MonthlyReferralsCardPresenter
>;
type SearchContentsProps = MonthlyReferralsCardProps['searchContents'];

type Props = {
  groupMedicalIstitutions: GroupMedicalInstitution[];
};

export const MonthlyReferralsCard: React.FC<Props> = (props) => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  const throwFetchError = useFetchError();

  useEffect(() => {
    request();
  }, [props.groupMedicalIstitutions]);

  const request = async () => {
    try {
      setSearchResult(null);

      const response =
        await groupsApi.postGroupsChartReferralsComparisonMonthly({
          postGroupsChartReferralsComparisonMonthlyRequest: {
            groupMedicalInstitutionIds: props.groupMedicalIstitutions.map(
              (_) => _.id,
            ),
          },
        });

      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  const createChartData = (referrals: SearchResult['referrals']) => {
    const threeYearsData = referrals.flatMap((r) => {
      return r.items.map((item) => {
        return {
          name: item.key,
          value: item.value,
          label: r.label + '-' + String(item.groupMedicalInstitutionId),
        };
      });
    });

    const months = Array.from(new Set(referrals[0].items.map((d) => d.key)));

    const data = months.map((month) => {
      const stacks = threeYearsData.reduce<Record<string, number>>(
        (res, cur) => {
          if (month === cur.name) {
            const k = cur.label;
            res[k] = cur.value;
          }

          return res;
        },
        {},
      );

      return {
        name: month,
        stacks,
      };
    });

    const groupMedicalInstitutionIds: number[] =
      props.groupMedicalIstitutions.map((item) => item.id);

    const labels = Array.from(new Set(referrals.map((i) => i.label))).reverse();

    const charts = labels.flatMap((label) => {
      const res = groupMedicalInstitutionIds.map((id) => {
        return {
          key: label + '-' + String(id),
          name: label,
          color: getColor(groupMedicalInstitutionIds.indexOf(id)),
        };
      });

      return res;
    });

    const payload: Payload = props.groupMedicalIstitutions.map((item) => {
      return {
        value: item.name,
        color: getColor(groupMedicalInstitutionIds.indexOf(item.id)),
      };
    });

    return { data, charts, payload };
  };

  const searchContentsProps = (): SearchContentsProps => {
    if (searchResult === null) {
      return {
        status: 'loading',
      } as const;
    } else {
      return {
        status: 'normal',
        referrals: createChartData(searchResult.referrals),
      } as const;
    }
  };

  return (
    <MonthlyReferralsCardPresenter searchContents={searchContentsProps()} />
  );
};
