import { dataAnalysisApi } from '@/src/api';
import {
  PostDataAnalysisRankingReferralAnnualResponse as SearchResult,
  PostDataAnalysisRankingReferralAnnualRequestSortEnum as SearchSort,
  PostDataAnalysisRankingReferralAnnualRequest as SearchRequest,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAccount } from '@/src/hooks/useAccount';
import {
  oneYearAgo,
  oneFiscalYearAgo,
  currentFiscalYearAgo,
} from '@/src/utils/date';
import { formatDateYear } from '@/src/utils/formatDate';

import { AnnualReferralRankingContainerPresenter } from './presenter';

import { useState, useEffect } from 'react';

type SearchParams = SearchRequest;
type AnnualReferralRankingContainerPresenterProps = React.ComponentProps<
  typeof AnnualReferralRankingContainerPresenter
>;

export const AnnualReferralRankingContainer: React.FC = () => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  const { account } = useAccount();

  const INITIAL_CURRENT_PAGE = 1;
  const INITIAL_SORT = SearchSort.Desc;
  const INITIAL_FACILITY_TYPE_IDS: SearchRequest['facilityTypeIds'] = [];
  const INITIAL_DEPARTMENT_IDS: SearchRequest['departmentIds'] = [];

  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: INITIAL_CURRENT_PAGE,
    prefecture: account.tenant?.prefecture ?? '',
    area: '',
    facilityTypeIds: INITIAL_FACILITY_TYPE_IDS,
    departmentIds: INITIAL_DEPARTMENT_IDS,
    sort: INITIAL_SORT,
  });

  const throwFetchError = useFetchError();

  const annualReferralRankingRequest = async () => {
    try {
      setSearchResult(null);

      const response =
        await dataAnalysisApi.postDataAnalysisRankingReferralAnnual({
          postDataAnalysisRankingReferralAnnualRequest: searchParams,
        });

      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  useEffect(() => {
    annualReferralRankingRequest();
  }, [searchParams]);

  const onChangeSortType = () => {
    setSearchParams({
      ...searchParams,
      sort:
        searchParams.sort === SearchSort.Desc
          ? SearchSort.Asc
          : SearchSort.Desc,
      page: INITIAL_CURRENT_PAGE,
    });
  };

  const onChangeSearchParams = (newSearchParams: SearchParams) => {
    setSearchParams(newSearchParams);
  };

  const rankingTableProps =
    (): AnnualReferralRankingContainerPresenterProps['rankingTable'] => {
      return {
        mode: 'annual',
        isLoading: Boolean(!searchResult),
        ranking: searchResult,
        before: formatDateYear(currentFiscalYearAgo(oneYearAgo(), 1)),
        current: formatDateYear(oneFiscalYearAgo()),
        sort: searchParams.sort,
        onChangeSortType,
      } as const;
    };

  return (
    <AnnualReferralRankingContainerPresenter
      rankingTable={rankingTableProps()}
      searchParams={searchParams}
      onChangeSearchParams={onChangeSearchParams}
      isSearching={searchResult === null}
    />
  );
};
