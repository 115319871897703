export const monthCount = (month: string, value: number): string => {
  return `${month}/${value}件`;
};

export const monthDifferenceCount = (month: string, value: number): string => {
  return `${month}/${_differenceCountText(value)}件`;
};

const _differenceCountText = (differenceCount: number) => {
  if (differenceCount > 0) return `+${differenceCount}`;

  return String(differenceCount);
};
