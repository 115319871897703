import {
  ReportAggregationResults,
  ReportAggregationResultsResults,
} from '@/src/api/generated';
import { HalfYear } from '@/src/types/date';

import { State } from './reducer';
import { ChartData, TABLE_ROW_TYPES, ITEM_TYPES, TableData } from './types';

import groupBy from 'lodash.groupby';

// hospitalとclinicはmisのfacility_typesのIDに合致する
const ROW_VALUE_HOSPITAL = '1';
const ROW_VALUE_CLINIC = '2';
const ROW_VALUE_TOTAL = 'total';

// COLUMN方向の集計のキー。COLUMNの集計は指定していないので total のみが入る
const COLUMN_AGG_KEY = 'total';

export const toChartData = (
  source: ReportAggregationResults | undefined,
  periods: HalfYear[] | number[],
): ChartData | undefined => {
  if (source === undefined) return undefined;

  const totals =
    source.results.find((r) => r.rowValue === ROW_VALUE_TOTAL)?.dataValues?.[
      'total'
    ] ?? [];

  return periods.map((period, i): ChartData[number] => {
    const referralCount = Number(totals[i * 3] ?? 0);
    const referralAdmissionCount = Number(totals[i * 3 + 1] ?? 0);
    const referralOperationCount = Number(totals[i * 3 + 2] ?? 0);

    return {
      period: period.toString(),
      referralCount,
      referralAdmissionCount,
      referralOperationCount,
      referralAdmissionRate: Math.round(
        (referralAdmissionCount / referralCount) * 100,
      ),
      referralOperationRate: Math.round(
        (referralOperationCount / referralCount) * 100,
      ),
    };
  });
};

/* eslint-disable no-irregular-whitespace */
/**
 * レポート集計データをテーブルデータに変換する。以下のようなルールでレポート集計データの変換を行っている
 *
 * [
 *   // row_value = 1 の場合、"hospital"にマッピング（紹介入院率、紹介手術率は計算して算出）
 *   //                                            | n期                      　| n + 1期       　| ...
 *   //                                            | 紹介数, 紹介入院数, 紹介手術数 | ...
 *   { "row_value": "1", "data_values": { "total": [   127,       27,        8,   277,   75,  26,   283,   63,  16,   281,   71,  29,  105,   25,   6 ] } },
 *
 *   // row_value = 2 の場合、"clinic"にマッピング
 *   { "row_value": "2", "data_values": { "total": [ 4972, 1191, 403, 11961, 2841, 970, 12229, 2684, 933, 11616, 2617, 947, 5254, 1174, 312 ] } },
 *
 *   // row_value が "1", "2", "total" 以外の場合、値を合算して"others"にマッピング
 *   { "row_value": "3",    "data_values": { "total": [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] } },
 *   { "row_value": "3001", "data_values": { "total": [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] } },
 *   ...
 *
 *   // row_value = "total" の場合、"total"にマッピング
 *   { "row_value": "total", "data_values": { "total": [ 5104, 1218, 411, 12256, 2918, 996, 12526, 2750, 951, 11908, 2689, 976, 5365, 1199, 318 ] } }
 * ]
 *   ↓↓↓↓↓
 * {
 *   hospital: [
 *     { period:   <n>, referralCount:  127, referralAdmissionCount:   27, referralOperationCount:   8, referralAdmissionRate: 21, referralOperationRate: 6 },
 *     { period: <n+1>, referralCount:  277, referralAdmissionCount:   75, referralOperationCount:  26, referralAdmissionRate: 27, referralOperationRate: 9 },
 *     ...
 *   ],
 *   clinic: [
 *     { period:   <n>, referralCount: 4972, referralAdmissionCount: 1191, referralOperationCount: 403, referralAdmissionRate: 24, referralOperationRate: 8 },
 *     ...
 *   ],
 *   others: [...],
 *   total: [...]
 * }
 */
/* eslint-enable no-irregular-whitespace */
export const toTableData = (
  source: ReportAggregationResults | undefined,
  periods: HalfYear[] | number[],
): TableData | undefined => {
  if (source === undefined) return undefined;

  const grouped = groupBy(source.results, (r) => {
    switch (r.rowValue) {
      case ROW_VALUE_HOSPITAL:
        return 'hospital';
      case ROW_VALUE_CLINIC:
        return 'clinic';
      case ROW_VALUE_TOTAL:
        return 'total';
      default:
        return 'others';
    }
  });

  return {
    hospital: toTableRow(periods, grouped['hospital'] ?? []),
    clinic: toTableRow(periods, grouped['clinic'] ?? []),
    others: toTableRow(periods, grouped['others'] ?? []),
    total: toTableRow(periods, grouped['total'] ?? []),
  };
};

const toTableRow = (
  periods: (number | HalfYear)[],
  results: ReportAggregationResultsResults[],
): TableData['hospital' | 'clinic' | 'others' | 'total'] => {
  return periods.map((period, i) => {
    const referralCount = results.reduce(
      (acc, r): number => acc + Number(r.dataValues[COLUMN_AGG_KEY][i * 3]),
      0,
    );
    const referralAdmissionCount = results?.reduce(
      (acc, r): number => acc + Number(r.dataValues[COLUMN_AGG_KEY][i * 3 + 1]),
      0,
    );
    const referralOperationCount = results?.reduce(
      (acc, r): number => acc + Number(r.dataValues[COLUMN_AGG_KEY][i * 3 + 2]),
      0,
    );

    return {
      period: period.toString(),
      referralCount,
      referralAdmissionCount,
      referralOperationCount,
      referralAdmissionRate: Math.round(
        (referralAdmissionCount / referralCount) * 100,
      ),
      referralOperationRate: Math.round(
        (referralOperationCount / referralCount) * 100,
      ),
    };
  });
};

export const facilityTypeToLabel = (
  facilityType: (typeof TABLE_ROW_TYPES)[number],
): string => {
  switch (facilityType) {
    case 'hospital':
      return '病院';
    case 'clinic':
      return 'クリニック';
    case 'others':
      return 'その他';
    case 'total':
      return '総計';
  }
};

export const itemTypeToPostfix = (
  itemType: (typeof ITEM_TYPES)[number],
): string => {
  switch (itemType) {
    case 'referralAdmissionRate':
    case 'referralOperationRate':
      return '%';
    default:
      return '';
  }
};

export const itemTypeToLabel = (
  itemType: (typeof ITEM_TYPES)[number],
): string => {
  switch (itemType) {
    case 'referralCount':
      return '紹介数';
    case 'referralAdmissionCount':
      return '紹介入院数';
    case 'referralOperationCount':
      return '紹介手術数';
    case 'referralAdmissionRate':
      return '紹介入院率';
    case 'referralOperationRate':
      return '紹介手術率';
  }
};

export const toYearLabel = (year: string | number): string => `${year}年度`;

export const facilityTypeFiltersToVisibleTableRows = (
  facilityTypeFilters: State['filters']['facilityTypeIds'],
): (typeof TABLE_ROW_TYPES)[number][] => {
  if (facilityTypeFilters.length === 0) return [...TABLE_ROW_TYPES]; // readonly回避のためにスプレッド演算子を使用

  return [
    ...(facilityTypeFilters.includes(Number(ROW_VALUE_HOSPITAL))
      ? (['hospital'] as const)
      : []),
    ...(facilityTypeFilters.includes(Number(ROW_VALUE_CLINIC))
      ? (['clinic'] as const)
      : []),
    ...(facilityTypeFilters.some(
      (t) => t !== Number(ROW_VALUE_HOSPITAL) && t !== Number(ROW_VALUE_CLINIC),
    )
      ? (['others'] as const)
      : []),
    'total',
  ];
};
