import {
  GetOwnDepartmentsResponse,
  NullableReferral,
} from '@/src/api/generated';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Stack } from '@/src/components/foundations/Layouts/Stack';

import { AllReferralsCard } from './components/AllReferralsCard';
import { AnnualDepartmentsUnitReferralsCard } from './components/AnnualDepartmentsUnitReferralsCard';
import { AnnualReferralsCard } from './components/AnnualReferralsCard';
import { DateRangeAnnualOwnDepartmentsUnitReferralsCard } from './components/DateRangeAnnualOwnDepartmentsUnitReferralsCard';
import { DateRangeAnnualReferralsCard } from './components/DateRangeAnnualReferralsCard';
import { DepartmentUnitCard } from './components/DepartmentUnitCard';
import { MonthlyOwnDepartmentsUnitReferralsCard } from './components/MonthlyOwnDepartmentsUnitReferralsCard';
import { MonthlyReferralsCard } from './components/MonthlyReferralsCard';
import { OwnDepartmentsUnitReferralsCard } from './components/OwnDepartmentsUnitReferralsCard';
import styles from './styles.module.scss';

type Departments = GetOwnDepartmentsResponse['departments'];

type Props = {
  lastReferral: NullableReferral;
  defaultDepartments: Departments;
  departments: Departments;
};

export const ReferralsPresenter: React.FC<Props> = (props) => (
  <div className={styles.base}>
    <Stack gap="16px" align="flex-start">
      <Cluster gap="16px">
        <AllReferralsCard lastReferral={props.lastReferral} />
        <OwnDepartmentsUnitReferralsCard {...props} />
      </Cluster>
      <Cluster gap="16px">
        <AnnualReferralsCard />
        <AnnualDepartmentsUnitReferralsCard
          defaultDepartments={props.defaultDepartments}
          departments={props.departments}
        />
      </Cluster>
      <MonthlyReferralsCard />
      <MonthlyOwnDepartmentsUnitReferralsCard
        defaultDepartments={props.defaultDepartments}
        departments={props.departments}
      />
      <Cluster gap="16px">
        <DateRangeAnnualReferralsCard lastReferral={props.lastReferral} />
        <DateRangeAnnualOwnDepartmentsUnitReferralsCard
          lastReferral={props.lastReferral}
          defaultDepartments={props.defaultDepartments}
          departments={props.departments}
        />
      </Cluster>
      <DepartmentUnitCard departments={props.departments} />
    </Stack>
  </div>
);
