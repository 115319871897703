import { chartsApi } from '@/src/api';
import {
  PostChartVisitsRequest,
  PostChartVisitsResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    params: PostChartVisitsRequest,
    successCallback: (result: PostChartVisitsResponse) => void,
  ) => {
    if (params.staffIds === null || params.staffIds.length === 0) return;

    try {
      const response = await chartsApi.postChartVisits({
        postChartVisitsRequest: params,
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
