import {
  GetOwnDepartmentsResponse,
  PostChartBothReferralsComparisonRequestUnitTypeEnum,
  PostChartBothReferralsComparisonResponse,
} from '@/src/api/generated';
import { currentYearAgo, today } from '@/src/utils/date';

import { startOfMonth, endOfMonth } from 'date-fns';
import { useReducer } from 'react';

type UnitType = PostChartBothReferralsComparisonRequestUnitTypeEnum;

type Params = {
  unitType: UnitType;
  period: { startDate: Date; endDate: Date };
  departments: GetOwnDepartmentsResponse['departments'];
};

type State = {
  params: Params;
  bothReferrals:
    | PostChartBothReferralsComparisonResponse['bothReferrals']
    | null;
};

export const actionType = {
  setUnitType: 'setUnitType',
  setPeriod: 'setPeriod',
  setDepartments: 'setDepartments',
  setBothReferrals: 'setBothReferrals',
} as const;

type Action =
  | {
      type: typeof actionType.setUnitType;
      payload: Params['unitType'];
    }
  | {
      type: typeof actionType.setPeriod;
      payload: Params['period'];
    }
  | {
      type: typeof actionType.setDepartments;
      payload: Params['departments'];
    }
  | {
      type: typeof actionType.setBothReferrals;
      payload: PostChartBothReferralsComparisonResponse['bothReferrals'];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setUnitType:
      return {
        params: {
          ...state.params,
          unitType: action.payload,
        },
        bothReferrals: null,
      };
    case actionType.setPeriod:
      return {
        params: {
          ...state.params,
          period: action.payload,
        },
        bothReferrals: null,
      };
    case actionType.setDepartments:
      return {
        params: {
          ...state.params,
          departments: action.payload,
        },
        bothReferrals: null,
      };
    case actionType.setBothReferrals:
      return {
        ...state,
        bothReferrals: action.payload,
      };
    default:
      return state;
  }
};

type InitialStateProps = {
  reverseReferralDate: string | null;
  departments: GetOwnDepartmentsResponse['departments'];
};
const createInitialState = (props: InitialStateProps): State => {
  const endDate = endOfMonth(
    props.reverseReferralDate ? new Date(props.reverseReferralDate) : today(),
  );
  const startDate = startOfMonth(currentYearAgo(endDate, 1));

  return {
    params: {
      unitType: PostChartBothReferralsComparisonRequestUnitTypeEnum.Month,
      period: {
        startDate,
        endDate,
      },
      departments: props.departments,
    },
    bothReferrals: null,
  };
};

export const useChartState = (props: InitialStateProps) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
