
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/data_analysis",
      function () {
        return require("private-next-pages/data_analysis/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/data_analysis"])
      });
    }
  