import {
  PostDataAnalysisRankingBothReferralsMonthlyRequest,
  PostDataAnalysisRankingBothReferralsMonthlyResponse,
  PostDataAnalysisRankingBothReferralsMonthlyRequestSortEnum as SortType,
} from '@/src/api/generated';

import { useReducer } from 'react';

const INITIAL_CURRENT_PAGE = 1;

type Params = Omit<
  PostDataAnalysisRankingBothReferralsMonthlyRequest,
  'prefecture' | 'area' | 'month'
> & {
  month: Date;
};

type State = {
  params: Params;
  searchResults: PostDataAnalysisRankingBothReferralsMonthlyResponse | null;
};

export const actionType = {
  setPage: 'setPage',
  setMonth: 'setMonth',
  setFacilityTypeIds: 'setFacilityTypeIds',
  setOwnDepartmentIds: 'setOwnDepartmentIds',
  switchSortType: 'switchSortType',
  setSearchResults: 'setSearchResults',
  resetSearchResults: 'resetSearchResults',
} as const;

type Action =
  | {
      type: typeof actionType.setPage;
      payload: Params['page'];
    }
  | {
      type: typeof actionType.setMonth;
      payload: Params['month'];
    }
  | {
      type: typeof actionType.setFacilityTypeIds;
      payload: Params['facilityTypeIds'];
    }
  | {
      type: typeof actionType.setOwnDepartmentIds;
      payload: Params['ownDepartmentIds'];
    }
  | {
      type: typeof actionType.switchSortType;
    }
  | {
      type: typeof actionType.setSearchResults;
      payload: State['searchResults'];
    }
  | {
      type: typeof actionType.resetSearchResults;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setPage:
      return {
        params: {
          ...state.params,
          page: action.payload,
        },
        searchResults: null,
      };
    case actionType.setMonth:
      return {
        params: {
          ...state.params,
          month: action.payload,
          page: INITIAL_CURRENT_PAGE,
        },
        searchResults: null,
      };
    case actionType.setFacilityTypeIds:
      return {
        params: {
          ...state.params,
          facilityTypeIds: action.payload,
          page: INITIAL_CURRENT_PAGE,
        },
        searchResults: null,
      };
    case actionType.setOwnDepartmentIds:
      return {
        params: {
          ...state.params,
          ownDepartmentIds: action.payload,
          page: INITIAL_CURRENT_PAGE,
        },
        searchResults: null,
      };
    case actionType.switchSortType:
      return {
        params: {
          ...state.params,
          sort:
            state.params.sort === SortType.Asc ? SortType.Desc : SortType.Asc,
          page: INITIAL_CURRENT_PAGE,
        },
        searchResults: null,
      };
    case actionType.setSearchResults:
      return {
        ...state,
        searchResults: action.payload,
      };
    case actionType.resetSearchResults:
      return {
        params: {
          ...state.params,
          page: INITIAL_CURRENT_PAGE,
        },
        searchResults: null,
      };
    default:
      return state;
  }
};

type InitialStateProps = {
  reverseReferralDate: string | null;
};

const createInitialState = (props: InitialStateProps): State => {
  return {
    params: {
      page: INITIAL_CURRENT_PAGE,
      month: props.reverseReferralDate
        ? new Date(props.reverseReferralDate)
        : new Date(),
      facilityTypeIds: [],
      ownDepartmentIds: [],
      sort: SortType.Asc,
    },
    searchResults: null,
  };
};

export const useSearchState = (props: InitialStateProps) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
