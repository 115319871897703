import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonAnnualResponse,
} from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';

import { createChartData } from './selector';
import styles from './styles.module.scss';

import React from 'react';

type BaseProps = {
  departments: {
    selected: GetOwnDepartmentsResponse['departments'];
    onSubmit: React.ComponentProps<typeof OwnDepartmentFilterChip>['onSubmit'];
  };
};
type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: PostChartReferralsComparisonAnnualResponse['referrals'];
};

type Props = BaseProps & {
  contents: ContentsLoading | ContentsNormal;
};

export const AnnualDepartmentsUnitReferralsCardPresenter: React.FC<Props> = (
  props,
) => (
  <Card>
    <div className={styles.header}>
      <div className={styles.title}>診療科別年次比較</div>
      <div className={styles.control}>
        <OwnDepartmentFilterChip
          selectedOwnDepartmentIds={props.departments.selected.map(
            (_) => _.key,
          )}
          onSubmit={props.departments.onSubmit}
        />
      </div>
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.contents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={CHART_SIZE}
                mode="stack"
                {...createChartData(
                  props.contents.referrals,
                  props.departments.selected,
                )}
              />
            );
        }
      })()}
    </div>
  </Card>
);
