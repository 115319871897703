import { PostChartReferralsComparisonResponse } from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { COLOR, PREVIOUS_COLOR } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

const LOCAL_COLORS = [
  COLOR.PRIMARY,
  PREVIOUS_COLOR.LAST_TIME,
  PREVIOUS_COLOR.TIME_BEFORE_LAST,
];

export const createChartData = (
  referrals: PostChartReferralsComparisonResponse['referrals'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  return {
    data: referrals
      .map((r) => {
        const stacks: Record<string, number> = {};
        if (r.key) {
          stacks[r.key] = r.value;
        }

        return {
          name: r.label,
          stacks,
        };
      })
      .reverse(),
    charts: referrals.flatMap((r, index) => {
      return {
        key: r.key,
        name: r.key,
        color: LOCAL_COLORS[index],
      };
    }),
    payload: [
      {
        value: '全診療科',
        color: COLOR.PRIMARY,
      },
    ],
  };
};
