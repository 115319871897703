import { PostChartReferralsComparisonMonthlyResponse } from '@/src/api/generated';

import { useAction } from './action';
import { MonthlyReferralsCardPresenter } from './presenter';

import { useState, useEffect } from 'react';

export const MonthlyReferralsCard: React.FC = () => {
  const [referrals, setReferrals] = useState<
    PostChartReferralsComparisonMonthlyResponse['referrals'] | null
  >(null);
  const { request } = useAction();

  useEffect(() => {
    request((res) => {
      setReferrals(res.referrals);
    });
  }, []);

  return (
    <MonthlyReferralsCardPresenter
      contents={
        referrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              referrals,
            }
      }
    />
  );
};
