import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { useGetLastReferral } from '@/src/hooks/fetcher/useGetLastReferral';
import { useGetOwnDepartments } from '@/src/hooks/fetcher/useGetOwnDepartments';
import { useDefaultOwnDepartmentId } from '@/src/hooks/useDefaultOwnDepartmentId';

import { ReferralsPresenter } from './presenter';

export const Referrals: React.FC = () => {
  const lastReferralResponse = useGetLastReferral();
  const defaultOwnDepartmentId = useDefaultOwnDepartmentId();
  const ownDepartments = useGetOwnDepartments();

  return lastReferralResponse.data &&
    ownDepartments.data &&
    defaultOwnDepartmentId ? (
    <ReferralsPresenter
      lastReferral={lastReferralResponse.data}
      defaultDepartments={ownDepartments.data.departments.filter(
        (_) => _.key === defaultOwnDepartmentId,
      )}
      departments={ownDepartments.data.departments ?? []}
    />
  ) : (
    <CenteringContainer>
      <LoadingCircle />
    </CenteringContainer>
  );
};
