import {
  GetOwnDepartmentsResponse,
  PostChartReverseReferralsComparisonMonthlyResponse,
} from '@/src/api/generated';

import { useReducer } from 'react';

type Params = {
  departments: GetOwnDepartmentsResponse['departments'];
};

type State = {
  params: Params;
  reverseReferrals:
    | PostChartReverseReferralsComparisonMonthlyResponse['reverseReferrals']
    | null;
};

export const actionType = {
  setDepartments: 'setDepartments',
  setReverseReferrals: 'setReverseReferrals',
} as const;

type Action =
  | {
      type: typeof actionType.setDepartments;
      payload: Params['departments'];
    }
  | {
      type: typeof actionType.setReverseReferrals;
      payload:
        | PostChartReverseReferralsComparisonMonthlyResponse['reverseReferrals']
        | null;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setDepartments:
      return {
        params: {
          departments: action.payload,
        },
        reverseReferrals: null,
      };
    case actionType.setReverseReferrals:
      return {
        ...state,
        reverseReferrals: action.payload,
      };
    default:
      return state;
  }
};

type InitialStateProps = {
  departments: GetOwnDepartmentsResponse['departments'];
};
const createInitialState = (props: InitialStateProps): State => {
  return {
    params: {
      departments: props.departments,
    },
    reverseReferrals: null,
  };
};

export const useChartState = (props: InitialStateProps) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
