import type {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';

import { useAction } from './action';
import { VISIBLE_ITEMS_COUNT } from './const';
import { DepartmentUnitCardPresenter } from './presenter';
import { useCurrentItemIndexState } from './reducer';
import { createChartData } from './selector';

import { useState, useEffect, useMemo } from 'react';

type Props = {
  departments: GetOwnDepartmentsResponse['departments'];
};

export const DepartmentUnitCard: React.FC<Props> = (props) => {
  const [referrals, setReferrals] = useState<
    PostChartReferralsComparisonDepartmentsResponse['referrals'] | null
  >(null);

  const { request } = useAction();

  useEffect(() => {
    request((res) => {
      setReferrals(res.referrals);
    });
  }, []);

  const chartData = useMemo(
    () =>
      referrals !== null ? createChartData(referrals, props.departments) : null,
    [referrals],
  );

  const currentItemIndexState = useCurrentItemIndexState(
    props.departments.length,
  );

  return (
    <DepartmentUnitCardPresenter
      contents={
        referrals !== null && chartData !== null
          ? {
              status: 'normal',
              chartData: {
                charts: chartData.charts,
                payload: chartData.payload,
                data: chartData.displayData(
                  currentItemIndexState.state.currentItemIndex,
                ),
              },
              chartSlider: {
                visibleItemsCount: VISIBLE_ITEMS_COUNT,
                itemsCount: props.departments.length,
                currentItemIndex: currentItemIndexState.state.currentItemIndex,
                onClickPrev: () =>
                  currentItemIndexState.dispatch({ type: 'SLIDE_PREV' }),
                onClickNext: () =>
                  currentItemIndexState.dispatch({ type: 'SLIDE_NEXT' }),
              },
            }
          : {
              status: 'loading',
            }
      }
    />
  );
};
