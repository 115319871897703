import {
  GetOwnDepartmentsResponse,
  PostChartReverseReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';
import { LIST } from '@/src/utils/chartColors';

import { VISIBLE_ITEMS_COUNT } from './const';

const BAR_COUNT = 3;

const COLOR_LIST = LIST.slice(0, BAR_COUNT).reverse();

export const createChartData = (
  reverseReferrals: PostChartReverseReferralsComparisonDepartmentsResponse['reverseReferrals'],
  departments: GetOwnDepartmentsResponse['departments'],
) => {
  const charts = reverseReferrals.map((r, index) => {
    return {
      key: r.label,
      name: r.label,
      color: COLOR_LIST[index % COLOR_LIST.length],
    };
  });
  const payload = charts.map((c) => ({
    value: c.name,
    color: c.color,
  }));

  // NOTE: 最後のラベルをソート基準ラベルとして特定する
  const _sortKeyLable = reverseReferrals.slice(-1)[0].label;

  // NOTE: 診療科目ごとの月次データを作る
  const _data = departments
    .map((department) => {
      return {
        name: department.value,
        stacks: reverseReferrals.reduce<Record<string, number>>((res, cur) => {
          // NOTE: { [対象月]: [対象の診療科目を検索した値] } を作る
          res[`${cur.label}`] =
            cur.items.find((item) => String(item.key) === department.key)
              ?.value ?? 0;
          return res;
        }, {}),
      };
    })
    // NOTE: ソート基準ラベルの値で降順にソート
    .sort((a, b) => {
      return a.stacks[_sortKeyLable] > b.stacks[_sortKeyLable] ? -1 : 1;
    });

  return {
    charts,
    payload,
    displayData: (currentItemIndex: number) => {
      return _data.slice(
        currentItemIndex,
        currentItemIndex + VISIBLE_ITEMS_COUNT,
      );
    },
  };
};
