export type NumberFormatOptions = {
  signed?: boolean;
  percent?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  noUseGrouping?: true;
};

export const numberFormat = (
  number: number | string | undefined | null,
  option: NumberFormatOptions = {},
): string | undefined => {
  const n = Number(number);
  if (number === null || number === undefined || isNaN(n)) return undefined;

  return new Intl.NumberFormat('ja-JP', {
    signDisplay: option.signed ? 'exceptZero' : 'auto',
    style: option.percent ? 'percent' : 'decimal',
    minimumFractionDigits: option.minimumFractionDigits,
    // 既存の挙動を踏襲し、percent指定時は小数点1桁で四捨五入する挙動を維持。
    // これは maximumFractionDigits 指定で上書き可能
    maximumFractionDigits:
      option.maximumFractionDigits ?? (option.percent ? 0 : 20),
    // 3桁区切りの記号をデフォルトで使用するが、オプションで無効化可能
    useGrouping: !option.noUseGrouping,
  }).format(n);
};
