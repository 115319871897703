import { groupsApi } from '@/src/api';
import {
  PostGroupsChartReferralsComparisonAnnualResponse as SearchResult,
  GroupMedicalInstitution,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { getColor } from '@/src/utils/chartColors';

import { AnnualReferralsCardPresenter } from './presenter';

import { useState, useEffect } from 'react';

type BarChartProps = React.ComponentProps<typeof BarChart>;
type AnnualReferralsCardProps = React.ComponentProps<
  typeof AnnualReferralsCardPresenter
>;
type SearchContentsProps = AnnualReferralsCardProps['searchContents'];

type Props = {
  groupMedicalIstitutions: GroupMedicalInstitution[];
};

export const AnnualReferralsCard: React.FC<Props> = (props) => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  const throwFetchError = useFetchError();

  useEffect(() => {
    request();
  }, [props.groupMedicalIstitutions]);

  const request = async () => {
    try {
      setSearchResult(null);

      const response = await groupsApi.postGroupsChartReferralsComparisonAnnual(
        {
          postGroupsChartReferralsComparisonAnnualRequest: {
            groupMedicalInstitutionIds: props.groupMedicalIstitutions.map(
              (m) => m.id,
            ),
          },
        },
      );

      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  const createChartData = (referrals: SearchResult['referrals']) => {
    const data = referrals.reverse().map((r) => {
      return {
        name: r.label,
        stacks: r.items.reduce<Record<string, number>>((res, cur) => {
          res[cur.key] = cur.value;
          return res;
        }, {}),
      };
    });

    const { charts, payload } = props.groupMedicalIstitutions.reduce<
      Pick<Required<BarChartProps>, 'charts' | 'payload'>
    >(
      (res, cur, index) => {
        const color = getColor(index);

        res.charts.push({
          key: String(cur.id),
          name: cur.name,
          color,
        });

        res.payload.push({
          value: cur.name,
          color,
        });

        return res;
      },
      { charts: [], payload: [] },
    );

    return { data, charts, payload };
  };

  const searchContentsProps = (): SearchContentsProps => {
    if (searchResult === null) {
      return {
        status: 'loading',
      } as const;
    } else {
      return {
        status: 'normal',
        referrals: createChartData(searchResult.referrals),
      } as const;
    }
  };

  return (
    <AnnualReferralsCardPresenter searchContents={searchContentsProps()} />
  );
};
