import { FacilityTypeFilterChip } from '@/src/components/features/FilterChip/FacilityTypeFilterChip';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';

import styles from './styles.module.scss';

type Props = {
  facilityTypeFilter: React.ComponentProps<typeof FacilityTypeFilterChip>;
  ownDepartmentFilter: React.ComponentProps<typeof OwnDepartmentFilterChip>;
};

export const SearchFilterPresenter: React.FC<Props> = (props) => (
  <div className={styles.base}>
    <div className={styles.facilityTypeFilterChip}>
      <FacilityTypeFilterChip {...props.facilityTypeFilter} />
    </div>
    <OwnDepartmentFilterChip {...props.ownDepartmentFilter} />
  </div>
);
