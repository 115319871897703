import { chartsApi } from '@/src/api';
import {
  PostChartBothReferralsComparisonRequest,
  PostChartBothReferralsComparisonResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    params: PostChartBothReferralsComparisonRequest,
    successCallback: (result: PostChartBothReferralsComparisonResponse) => void,
  ) => {
    try {
      const response = await chartsApi.postChartBothReferralsComparison({
        postChartBothReferralsComparisonRequest: params,
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
