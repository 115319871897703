import styles from './styles.module.scss';

import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const CenteringContainerPresenter: React.FC<Props> = (props) => {
  const style = clsx(styles.base, props.className);

  return <div className={style}>{props.children}</div>;
};
