import { PostChartVisitsResponse, Staff } from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { getColor } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  visits: PostChartVisitsResponse['visits'],
  selectedStaffs: Staff[],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  return {
    data: visits.map((v) => {
      const stacks = v.items.reduce<Record<string, number>>((res, cur) => {
        if (cur.key) {
          res[cur.key] = cur.value;
        }
        return res;
      }, {});

      return {
        name: v.label,
        stacks,
      };
    }),
    charts: selectedStaffs.map((item, index) => {
      return {
        key: item.id.toString(),
        name: item.name,
        color: getColor(index),
      };
    }),
    payload: selectedStaffs.map((item, index) => {
      return {
        value: item.name,
        color: getColor(index),
      };
    }),
  };
};
