import { FacilityTypeFilterChip } from '@/src/components/features/FilterChip/FacilityTypeFilterChip';
import { TextButton } from '@/src/components/foundations/Buttons/TextButton';
import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { Label } from '@/src/components/foundations/DataDisplays/Label';
import {
  ChevronRight,
  Help,
} from '@/src/components/foundations/DesignToken/Icons';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { Checkbox } from '@/src/components/foundations/Forms/Checkbox';
import { SelectBox } from '@/src/components/foundations/Forms/SelectBox';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Stack } from '@/src/components/foundations/Layouts/Stack';

import { AreaForm } from './components/AreaForm';
import { Chart } from './components/Chart';
import { ExplainModal } from './components/ExplainModal';
import { GoToReportModal } from './components/GoToReportModal';
import { Schema } from './form';
import { ChartData } from './type';

import React, { ComponentProps } from 'react';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  chartData: ChartData | undefined;
  filters: {
    area: {
      form: UseFormReturn<Schema>;
      onSubmit: VoidFunction;
    };
    period: {
      value: string;
      options: ComponentProps<typeof SelectBox>['options'];
      onChange: (value: string) => void;
    };
    facilityTypeIds: {
      values: string[];
      onChange: (values: string[]) => void;
    };
    includeMedicalInstitutionWithNoReferral: {
      value: boolean;
      onChange: VoidFunction;
    };
  };
  explainModal: ComponentProps<typeof ExplainModal> & {
    show: VoidFunction;
  };
  goToReportModal: ComponentProps<typeof GoToReportModal> & {
    show: VoidFunction;
  };
};

export const SegmentsPresenter: React.FC<Props> = (props) => {
  return (
    <>
      <Stack align="stretch" gap={24}>
        <Cluster justify="space-between" wrap="nowrap" width="initial">
          <AreaForm
            form={props.filters.area.form}
            onSubmit={props.filters.area.onSubmit}
          />
          <Cluster gap={16} wrap="nowrap" width="initial">
            <FacilityTypeFilterChip
              size="medium"
              selectedFacilityTypeIds={props.filters.facilityTypeIds.values}
              onSubmit={props.filters.facilityTypeIds.onChange}
            />
            <Label
              text="紹介数0件の医療機関を含む"
              position="right"
              distance={4}
              typography={{
                fontSize: 14,
                fontWeight: 'normal',
              }}
            >
              <Checkbox
                isRaw
                isChecked={
                  props.filters.includeMedicalInstitutionWithNoReferral.value
                }
                onChange={
                  props.filters.includeMedicalInstitutionWithNoReferral.onChange
                }
              />
            </Label>
          </Cluster>
        </Cluster>
        <Card>
          <Section padding="24px">
            <Stack align="stretch" gap={16}>
              <Cluster justify="space-between" wrap="nowrap" width="initial">
                <Cluster gap={24} wrap="nowrap" width="initial">
                  <TextButton
                    variant="primary"
                    size="medium"
                    prefix={<Help />}
                    onClick={props.explainModal.show}
                  >
                    連携先セグメントについて
                  </TextButton>
                </Cluster>
                <SelectBox
                  name="period"
                  width={192}
                  value={props.filters.period.value}
                  onChange={(e) =>
                    props.filters.period.onChange(e.target.value)
                  }
                  options={props.filters.period.options}
                />
              </Cluster>
              <Section height={440}>
                {props.chartData ? (
                  <Chart
                    segments={props.chartData.segments}
                    restSegment={props.chartData.restSegment}
                    xThresholdFactor={0.5}
                  />
                ) : (
                  <CenteringContainer>
                    <LoadingCircle />
                  </CenteringContainer>
                )}
              </Section>
              <Cluster justify="flex-end">
                <TextButton
                  variant="primary"
                  size="medium"
                  suffix={<ChevronRight />}
                  onClick={props.goToReportModal.show}
                >
                  セグメントから連携先レポートを作成
                </TextButton>
              </Cluster>
            </Stack>
          </Section>
        </Card>
      </Stack>
      <ExplainModal {...props.explainModal} />
      <GoToReportModal {...props.goToReportModal} />
    </>
  );
};
