export const outputCSV = (
  filename: string,
  headers: string[],
  values: string[][],
): void => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

  // NOTE: データ内にダブルクオーテーションが含まれていればシングルクォーテーションに置換する
  // prettier-ignore
  const replaceToData = (data: string) => `"${data.replace(/"/g, '\'')}"`;

  const blob = new Blob(
    [
      bom,
      [headers.map(replaceToData).join(',')]
        .concat(values.map((v) => v.map(replaceToData).join(',')))
        .join('\n'),
    ],
    { type: 'text/csv' },
  );

  const downloadLink = document.createElement('a');
  downloadLink.download = `${filename}.csv`;
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.click();
};
