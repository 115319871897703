import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';
import { COLOR, PREVIOUS_COLOR } from '@/src/utils/chartColors';

import { VISIBLE_ITEMS_COUNT } from './const';

export const createChartData = (
  referrals: PostChartReferralsComparisonDepartmentsResponse['referrals'],
  departments: GetOwnDepartmentsResponse['departments'],
) => {
  const keys = referrals
    .reduce<string[]>((result, ref) => {
      return result.concat(
        Array.from(new Set(ref.items.map((item) => item.key))),
      );
    }, [])
    .sort((a, b) => (a > b ? 1 : -1));

  const payload =
    referrals.length > 0
      ? [
          {
            value: referrals[0].label,
            color: COLOR.PRIMARY,
          },
        ]
      : undefined;

  const getColor = (key: string, index: number) => {
    // NOTE: stack棒グラフの上下のデータの判別
    if (index % 2 === 0 && keys) {
      return key === keys.slice(-1)[0]
        ? COLOR.PRIMARY
        : PREVIOUS_COLOR.LAST_TIME;
    } else {
      return PREVIOUS_COLOR.TIME_BEFORE_LAST;
    }
  };

  const charts = keys.map((key, index) => {
    return {
      key,
      name: key,
      color: getColor(key, index),
      stackId: (() => {
        // 各keyの始まり月をとってくる
        const startMonthNum = Number(key.slice(5, 7));
        // 各keyの始まり年をとってくる
        const tmpStackIdNum = Number(key.slice(0, 4));

        // 各keyの始まりの年度を算出している
        return String(
          [1, 2, 3].includes(startMonthNum) ? tmpStackIdNum - 1 : tmpStackIdNum,
        );
      })(),
    };
  });

  const _threeYearsData = referrals.flatMap((r) => {
    return r.items.map((item) => {
      return {
        name: item.key,
        value: item.value,
        label: r.label,
        department: item.department,
      };
    });
  });

  const _data = departments
    .map((department) => {
      const stacks = _threeYearsData.reduce<Record<string, number>>(
        (res, cur) => {
          if (department.key === String(cur.department)) {
            const k = cur.name;
            res[k] = cur.value;
          }
          return res;
        },
        {},
      );

      return {
        name: department.value,
        stacks,
      };
    })
    // NOTE: 本年度の紹介数を基準に降順にソート
    .sort((a, b) => {
      return a.stacks[keys.slice(-1)[0]] > b.stacks[keys.slice(-1)[0]] ? -1 : 1;
    });

  return {
    charts,
    payload,
    displayData: (currentItemIndex: number) => {
      return _data.slice(
        currentItemIndex,
        currentItemIndex + VISIBLE_ITEMS_COUNT,
      );
    },
  };
};
