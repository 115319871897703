import { CHART_PROPERTIES, useChart } from '@/src/hooks/useChart';
import { ChartProps } from '@/src/types/chart';

import styles from './styles.module.scss';

import React from 'react';
import {
  LineChart as ReLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Surface,
  Symbols,
} from 'recharts';

type Props = {
  data: { name: string; values: { [key: string]: number | null } }[];
} & ChartProps;

export const LineChart: React.FC<Props> = (props) => {
  const { createPayload } = useChart();

  return (
    <ReLineChart
      width={props.size.width}
      height={props.size.height + props.size.legendHeight}
      data={props.data.map((d) => {
        return { name: d.name, ...d.values };
      })}
      style={{ fontSize: '14px' }}
      className={styles.lineChart}
    >
      <XAxis
        axisLine={CHART_PROPERTIES.xaxislLne}
        tickLine={CHART_PROPERTIES.yaxisLine}
        dataKey="name"
        padding={{ right: 32, left: 32 }}
        tickMargin={8}
      />
      <CartesianGrid vertical={false} />
      <YAxis
        axisLine={CHART_PROPERTIES.xaxislLne}
        tickLine={CHART_PROPERTIES.yaxisLine}
        tickMargin={8}
        tickCount={6}
        domain={[0, 100]}
      />
      <Tooltip />
      {props.charts.map((c) => (
        <Line
          key={c.key}
          dataKey={c.key}
          name={c.name}
          stroke={c.color}
          strokeWidth={2}
          dot={{ fill: c.color, strokeWidth: 2 }}
          connectNulls
        />
      ))}
      <Legend
        chartHeight={props.size.height}
        payload={createPayload(props.payload)}
        // NOTE: 選択された項目デザインをカスタマイズしています。
        content={(() => (
          <ul className={styles.legend}>
            {props.payload?.map((entry, index) => (
              <li key={index} className={styles.listItem}>
                <Surface width={16} height={16} className={styles.surface}>
                  <Symbols
                    cx={8}
                    cy={8}
                    size={260}
                    type="square"
                    fill={entry.color}
                  />
                </Surface>
                <span className={styles.text}>{entry.value}</span>
              </li>
            ))}
          </ul>
        ))()}
      />
    </ReLineChart>
  );
};
