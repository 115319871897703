import { PostDataAnalysisRankingReferralAnnualRequest as SearchRequest } from '@/src/api/generated';

import { RankingContainer } from '../RankingContainer';

type SearchParams = SearchRequest;
type RankingContainerProps = React.ComponentProps<typeof RankingContainer>;

type Props = {
  searchParams: SearchParams;
  onChangeSearchParams: (newSearchParams: SearchParams) => void;
} & Pick<RankingContainerProps, 'isSearching' | 'rankingTable'>;

export const AnnualReferralRankingContainerPresenter: React.FC<Props> = (
  props,
) => <RankingContainer<SearchParams> {...props} />;
