import { useGetStaffs } from '@/src/hooks/fetcher/useGetStaffs';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';

import { StaffFilterModalPresenter } from './presenter';

type Props = Pick<
  React.ComponentProps<typeof StaffFilterModalPresenter>,
  'isShown' | 'onClose'
> & {
  selectedStaffIds: string[];
  onSubmit: (staffIds: string[]) => void;
};

export const StaffFilterModal: React.FC<Props> = (props) => {
  const form = useFormWrapper({
    mode: 'all',
    defaultValues: {
      staffIds: props.selectedStaffIds,
    },
  });

  const staffsResponse = useGetStaffs();

  const inputMap = form.watch();

  return staffsResponse.data?.staffs ? (
    <StaffFilterModalPresenter
      isShown={props.isShown}
      onClose={props.onClose}
      staffOptions={staffsResponse.data.staffs}
      form={{
        onSubmit: form.handleSubmit((data) => {
          props.onSubmit(data.staffIds);
        }),
        staffIds: {
          ...form.register('staffIds'),
          errorMessages: [],
          allSelect: {
            label: '全てを選択',
            onClick: () => {
              if (staffsResponse.data) {
                form.setValue(
                  'staffIds',
                  staffsResponse.data.staffs.map((staffOption) =>
                    String(staffOption.id),
                  ),
                );
              }
            },
          },
          allClear: {
            label: '全てを解除',
            onClick: () => form.setValue('staffIds', []),
          },
          isAllSelected:
            inputMap.staffIds.length === staffsResponse.data.staffs.length,
        },
      }}
    />
  ) : null;
};
