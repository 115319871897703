import { chartsApi } from '@/src/api';
import {
  PostChartVisitsDoctorInterviewRatesRequest,
  PostChartVisitsDoctorInterviewRatesResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    params: Omit<PostChartVisitsDoctorInterviewRatesRequest, 'staffIds'>,
    successCallback: (
      result: PostChartVisitsDoctorInterviewRatesResponse,
    ) => void,
  ) => {
    try {
      const response = await chartsApi.postChartVisitsDoctorInterviewRates({
        postChartVisitsDoctorInterviewRatesRequest: {
          ...params,
          staffIds: null,
        },
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
