import { PostChartReferralsComparisonResponse } from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';
import { DeepRequired } from '@/src/types/utilities';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: DeepRequired<
    Pick<
      React.ComponentProps<typeof MonthRangePicker>,
      'startDate' | 'endDate' | 'onChange' | 'allowedDateRange'
    >
  >;
};
type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: PostChartReferralsComparisonResponse['referrals'];
};

type Props = BaseProps & {
  contents: ContentsNormal | ContentsLoading;
};

export const DateRangeAnnualReferralsCardPresenter: React.FC<Props> = (
  props,
) => (
  <Card>
    <div className={styles.header}>
      <div className={styles.title}>期間指定比較</div>
      <div className={styles.control}>
        <MonthRangePicker {...props.dateRange} placement="bottomEnd" />
      </div>
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.contents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={CHART_SIZE}
                mode="stack"
                {...createChartData(props.contents.referrals)}
              />
            );
        }
      })()}
    </div>
  </Card>
);
