import { NullableReverseReferral } from '@/src/api/generated';
import { useAccount } from '@/src/hooks/useAccount';
import { lastDateTimeOfMonth } from '@/src/utils/date';
import { formatDateMonth } from '@/src/utils/formatDate';

import { useAction } from './action';
import { Schema, useSearchForm } from './form';
import { MonthlyMedicalInstitutionReferralsPresenter } from './presenter';
import { useSearchState, actionType } from './reducer';

import isAfter from 'date-fns/isAfter';
import { useEffect } from 'react';

type Props = {
  lastReverseReferral: NullableReverseReferral;
};

export const MonthlyMedicalInstitutionReferrals: React.FC<Props> = (props) => {
  const { account } = useAccount();
  const { form } = useSearchForm({
    prefecture: account.tenant?.prefecture,
  });

  const { state, dispatch } = useSearchState({
    reverseReferralDate: props.lastReverseReferral.reverseReferralDate,
  });

  const { request } = useAction();

  const search = (schema: Schema) => {
    request(
      {
        ...state.params,
        month: formatDateMonth(state.params.month),
        prefecture: schema.prefecture,
        area: schema.area,
      },
      (res) => {
        dispatch({
          type: actionType.setSearchResults,
          payload: res,
        });
      },
    );
  };

  useEffect(() => {
    search({
      prefecture: form.getValues('prefecture'),
      area: form.getValues('area'),
    });
  }, [state.params]);

  return (
    <MonthlyMedicalInstitutionReferralsPresenter
      header={{
        form,
        onSearch: form.handleSubmit((schema) => {
          dispatch({
            type: actionType.resetSearchResults,
          });

          search(schema);
        }),
        monthPicker: {
          value: state.params.month,
          onChange: (selectedMonthDate: Date) => {
            dispatch({
              type: actionType.setMonth,
              payload: selectedMonthDate,
            });
          },
          disabledDate: (date) => {
            return date ? isAfter(date, lastDateTimeOfMonth()) : false;
          },
        },
        facilityTypeFilter: {
          selectedFacilityTypeIds: state.params.facilityTypeIds ?? [],
          onSubmit: (data) => {
            dispatch({
              type: actionType.setFacilityTypeIds,
              payload: data,
            });
          },
        },
        ownDepartmentFilter: {
          selectedOwnDepartmentIds: state.params.ownDepartmentIds ?? [],
          onSubmit: (data) => {
            dispatch({
              type: actionType.setOwnDepartmentIds,
              payload: data,
            });
          },
        },
      }}
      table={{
        sort: {
          value: state.params.sort,
          onChange: () => {
            dispatch({
              type: actionType.switchSortType,
            });
          },
        },
        contents:
          state.searchResults === null
            ? {
                status: 'loading',
              }
            : {
                status: 'normal',
                rankings: state.searchResults.rankings,
              },
      }}
      pagination={{
        currentPage: state.params.page,
        lastPage: state.searchResults?.totalPage ?? 0,
        onClickCallback: (page: number) => {
          if (page > (state.searchResults?.totalPage ?? 0)) return;

          dispatch({
            type: actionType.setPage,
            payload: page,
          });
        },
      }}
    />
  );
};
