import { NullableSegmentationSetting } from '@/src/api/generated';
import { Label } from '@/src/components/foundations/DataDisplays/Label';
import { Checkbox } from '@/src/components/foundations/Forms/Checkbox';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';
import { useModal } from '@/src/hooks/useModal';

import { useForm, useWatch } from 'react-hook-form';

type Props = {
  modal: ReturnType<typeof useModal>;
  segmentationSetting: NullableSegmentationSetting;
  onSubmit: (segmentIds: string[]) => void;
  onDismiss: VoidFunction;
};

export const GoToReportModal: React.FC<Props> = (props) => {
  const form = useForm({ defaultValues: { segmentids: [] } });
  const segmentIds = useWatch({ control: form.control, name: 'segmentids' });
  const yThresholds = Array.from(
    new Set(
      props.segmentationSetting.segments.map(
        (s) => s.referralAdmissionRateMoreEqual,
      ),
    ),
  ).sort((a, b) => (b === null ? 1 : a === null ? -1 : a > b ? 1 : -1));

  return (
    <ModalDialog
      size="wide"
      isShown={props.modal.isShown}
      title="セグメントから連携先レポートを作成"
      okButton={{
        isDisabled: segmentIds.length === 0,
        children: 'レポート作成画面へ移動する',
        onClick: form.handleSubmit(() => props.onSubmit(segmentIds)),
      }}
      cancelButton={{
        children: 'キャンセル',
        onClick: props.onDismiss,
      }}
    >
      <Stack gap={24} align="stretch">
        <div>
          レポート化したいセグメントを選択してください。
          <br />
          セグメントの絞り込み条件も作成するレポートに反映されます。
        </div>

        {/* チェックボックスの並べ方が特殊なのでCheckBoxListを利用せず自前で配置している */}
        <Stack gap={16} align="stretch">
          {yThresholds.reverse().map((y, i) => (
            <Cluster key={y} gap={16} align="stretch">
              {props.segmentationSetting.segments
                .filter((s) => s.referralAdmissionRateMoreEqual === y)
                .map((s) => (
                  <Label
                    key={s.id}
                    text={s.label}
                    position="right"
                    distance={4}
                    typography={{ fontSize: 14, fontWeight: 'normal' }}
                  >
                    <Checkbox {...form.register('segmentids')} value={s.id} />
                  </Label>
                ))}
              {/* 最後の行にその他相当のセグメントを追加 */}
              {i === yThresholds.length - 1 && (
                <Label
                  text={props.segmentationSetting.restSegmentLabel}
                  position="right"
                  distance={4}
                  typography={{ fontSize: 14, fontWeight: 'normal' }}
                >
                  <Checkbox {...form.register('segmentids')} value="" />
                </Label>
              )}
            </Cluster>
          ))}
          {/* MEMO: 同じ ref 属性を持つ checkbox が 1 つのみとなる場合、RHF は boolean を返してしまう */}
          {/* このコンポーネントが期待したい string[] として返す為には、ダミー要素を用意し checkbox を必ず 2 つ以上にする */}
          <div style={{ display: 'none' }}>
            {/* ダミー要素は非表示 */}
            <Checkbox
              {...form.register('segmentids')}
              value=""
              onChange={() => {}}
              isDisabled
              tabIndex={-1}
            />
          </div>
        </Stack>
      </Stack>
    </ModalDialog>
  );
};
