import { dataAnalysisApi, referralsApi } from '@/src/api';
import {
  PostDataAnalysisRankingReferralMonthlyResponse as SearchResult,
  PostDataAnalysisRankingReferralMonthlyRequestSortEnum as SearchSort,
  PostDataAnalysisRankingReferralMonthlyRequest as SearchRequest,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAccount } from '@/src/hooks/useAccount';
import { currentMonthAgo, lastDateTimeOfMonth, today } from '@/src/utils/date';
import { formatDateMonth } from '@/src/utils/formatDate';

import { MonthlyReferralRankingContainerPresenter } from './presenter';

import isAfter from 'date-fns/isAfter';
import { useState, useEffect } from 'react';

type SearchParams = Omit<SearchRequest, 'month'> & { month: Date };
type MonthlyReferralRankingContainerPresenterProps = React.ComponentProps<
  typeof MonthlyReferralRankingContainerPresenter
>;

export const MonthlyReferralRankingContainer: React.FC = () => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
  const { account } = useAccount();

  const INITIAL_CURRENT_PAGE = 1;
  const INITIAL_SORT = SearchSort.Desc;
  const INITIAL_FACILITY_TYPE_IDS: SearchRequest['facilityTypeIds'] = [];
  const INITIAL_DEPARTMENT_IDS: SearchRequest['departmentIds'] = [];

  const [searchParams, setSearchParams] = useState<SearchParams>({
    page: INITIAL_CURRENT_PAGE,
    // 最新紹介日の取得までに時間がかかるため、初期値は今月とする
    month: today(),
    prefecture: account.tenant?.prefecture ?? '',
    area: '',
    facilityTypeIds: INITIAL_FACILITY_TYPE_IDS,
    departmentIds: INITIAL_DEPARTMENT_IDS,
    sort: INITIAL_SORT,
  });

  const throwFetchError = useFetchError();

  const monthlyReferralRankingRequest = async () => {
    try {
      setSearchResult(null);

      const formatSearchParams = {
        ...searchParams,
        month: formatDateMonth(searchParams.month),
      };
      const response =
        await dataAnalysisApi.postDataAnalysisRankingReferralMonthly({
          postDataAnalysisRankingReferralMonthlyRequest: formatSearchParams,
        });

      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  const initializeMonthParam = async () => {
    try {
      const lastReferralDate = (await referralsApi.getLastReferral())
        ?.referralDate;

      if (lastReferralDate) {
        setSearchParams({
          ...searchParams,
          month: new Date(lastReferralDate),
        });
      }
      // 最新紹介がない場合(lastReferralDate === null)は、今月とするが初期値は今月としているため、特になにかする必要はない
    } catch (error) {
      throwFetchError(500);
    }
  };
  useEffect(() => {
    initializeMonthParam();
  }, []);

  useEffect(() => {
    monthlyReferralRankingRequest();
  }, [searchParams]);

  const onChangeSortType = () => {
    setSearchParams({
      ...searchParams,
      sort:
        searchParams.sort === SearchSort.Desc
          ? SearchSort.Asc
          : SearchSort.Desc,
      page: INITIAL_CURRENT_PAGE,
    });
  };

  const onChangeSearchParams = (newSearchParams: SearchParams) => {
    setSearchParams(newSearchParams);
  };

  const rankingTableProps =
    (): MonthlyReferralRankingContainerPresenterProps['rankingTable'] => {
      return {
        mode: 'monthly',
        isLoading: Boolean(!searchResult),
        ranking: searchResult,
        before: formatDateMonth(currentMonthAgo(searchParams.month, 1)),
        current: formatDateMonth(searchParams.month),
        sort: searchParams.sort,
        onChangeSortType,
      } as const;
    };

  const monthPickerProps =
    (): MonthlyReferralRankingContainerPresenterProps['monthPicker'] => {
      return {
        value: searchParams.month,
        placement: 'bottomStart',
        onChange: (date: Date) => {
          onChangeSearchParams({
            ...searchParams,
            month: date,
            page: INITIAL_CURRENT_PAGE,
          });
        },
        disabledDate: (date?: Date) => {
          return date ? isAfter(date, lastDateTimeOfMonth()) : false;
        },
      };
    };

  return (
    <MonthlyReferralRankingContainerPresenter
      rankingTable={rankingTableProps()}
      searchParams={searchParams}
      onChangeSearchParams={onChangeSearchParams}
      monthPicker={monthPickerProps()}
      isSearching={searchResult === null}
    />
  );
};
