import {
  GetOwnDepartmentsResponseDepartments as OwnDepartment,
  NullableSegmentationSetting,
} from '@/src/api/generated';
import { useGetLastReferral } from '@/src/hooks/fetcher/useGetLastReferral';
import { useGetOwnDepartments } from '@/src/hooks/fetcher/useGetOwnDepartments';
import { useGetSegmentSetting } from '@/src/hooks/fetcher/useGetgetSegmentSetting';
import { useAccount } from '@/src/hooks/useAccount';
import { useModal } from '@/src/hooks/useModal';
import { generateSelectableHelfYearOptions } from '@/src/utils/date';

import { useAction } from './action';
import { useSearchForm } from './form';
import { toRequestParameter } from './mapper';
import { SegmentsPresenter } from './presenter';
import { actionType, useSegmentState } from './reducer';
import { generateReportUrl } from './selector';

import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export const Segments: React.FC = () => {
  const segmentSettingResponse = useGetSegmentSetting();
  const lastReferralResponse = useGetLastReferral();
  const ownDepartmentsResponse = useGetOwnDepartments();

  if (
    segmentSettingResponse.data === undefined ||
    lastReferralResponse.data === undefined
  )
    return null;

  // セグメント分け設定、紹介未投入の場合は通常考慮の必要がない。"紹介入院セグメント"同様に表示なしとする
  if (
    segmentSettingResponse.data.segmentationSetting === null ||
    !lastReferralResponse.data?.referralDate ||
    ownDepartmentsResponse.data === undefined
  ) {
    return null;
  }

  return (
    <SegmentsContainer
      segmentationSetting={segmentSettingResponse.data.segmentationSetting}
      lastReferralDate={lastReferralResponse.data.referralDate}
      ownDepartments={ownDepartmentsResponse.data.departments}
    />
  );
};

const SegmentsContainer: React.FC<{
  segmentationSetting: NullableSegmentationSetting;
  lastReferralDate: string;
  ownDepartments: OwnDepartment[];
}> = (props) => {
  const router = useRouter();
  const { getChartData: getSegMapData } = useAction(props.segmentationSetting);
  const { account } = useAccount();
  const explainModal = useModal();
  const goToReportModal = useModal();

  const selectablePeriods = generateSelectableHelfYearOptions(
    new Date('2019-04-01'),
    new Date(props.lastReferralDate),
  );

  // 単純なフォームになっているエリア検索部分をformで、それ以外の施設タイプなどをstateで管理している
  const { form } = useSearchForm({
    prefecture: account.tenant?.prefecture,
  });
  const { state, dispatch } = useSegmentState({
    period: selectablePeriods[Math.max(selectablePeriods.length - 2, 0)].value,
    facilityTypeIds: [],
  });

  const startRequest = async () => {
    dispatch({ type: actionType.resetChartData });
    const chartData = await getSegMapData(
      toRequestParameter(props.segmentationSetting, props.ownDepartments, {
        period: state.filterParams.period,
        facilityTypeIds: state.filterParams.facilityTypeIds,
        includeMedicalInstitutionWithNoReferral:
          state.filterParams.includeMedicalInstitutionWithNoReferral,
        prefecture: form.getValues().prefecture,
      }),
    );
    dispatch({ type: actionType.setChartData, payload: { chartData } });
  };

  // フィルタ条件が変更されたらセグマップデータを再取得
  useEffect(() => {
    startRequest();
  }, [state.filterParams]);

  return (
    <SegmentsPresenter
      chartData={state.chartData}
      filters={{
        area: {
          form,
          onSubmit: form.handleSubmit(() => startRequest()),
        },
        facilityTypeIds: {
          values: state.filterParams.facilityTypeIds,
          onChange: (facilityTypeIds) =>
            dispatch({
              type: actionType.setFacilityTypeIds,
              payload: { facilityTypeIds },
            }),
        },
        includeMedicalInstitutionWithNoReferral: {
          value: state.filterParams.includeMedicalInstitutionWithNoReferral,
          onChange: () =>
            dispatch({
              type: actionType.toggleIncludeMedicalInstitutionWithNoReferral,
            }),
        },
        period: {
          value: state.filterParams.period,
          options: generateSelectableHelfYearOptions(
            new Date('2019-04-01'),
            new Date(props.lastReferralDate),
          ),
          onChange: (period) =>
            dispatch({
              type: actionType.setPeriod,
              payload: { period },
            }),
        },
      }}
      explainModal={{
        modal: explainModal,
        onDismiss: explainModal.hide,
        show: explainModal.show,
      }}
      goToReportModal={{
        segmentationSetting: props.segmentationSetting,
        modal: goToReportModal,
        onSubmit: (segmentIds) => {
          window.open(
            `${router.basePath}${generateReportUrl({
              segmentIds,
              ...form.getValues(),
              ...state.filterParams,
            })}`,
            '_blank',
          );
          goToReportModal.hide();
        },
        onDismiss: goToReportModal.hide,
        show: goToReportModal.show,
      }}
    />
  );
};
