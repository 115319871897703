import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Section } from '@/src/components/foundations/Layouts/Section';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { numberFormat } from '@/src/utils/numberFormat';

import styles from './styles.module.scss';

import clsx from 'clsx';
import groupBy from 'lodash.groupby';
import React, { useEffect, useState } from 'react';

import { ChartData } from '../../type';

type Props = {
  /** 紹介数のしきい値を年度換算から集計期間換算に変換するための係数 */
  xThresholdFactor: number;
} & ChartData;

const X_SCALE_AXIS_HEIGHT = 71;
const Y_SCALE_AXIS_WIDTH = 24 + 16 + 17;

export const Chart: React.FC<Props> = (props) => {
  const chartRef = React.useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number>(0);
  const xThresholds = Array.from(
    new Set(props.segments.map((s) => s.xThreshold ?? 0)),
  ).sort((a, b) => (a > b ? 1 : -1));
  const yThresholds = Array.from(
    new Set(props.segments.map((s) => s.yThreshold ?? 0)),
  ).sort((a, b) => (a > b ? 1 : -1));
  const segmentsByXThreshold = groupBy(
    props.segments,
    (s) => s.xThreshold ?? 0,
  );

  useEffect(() => {
    const onResize = () => {
      if (chartRef.current)
        setChartWidth(chartRef.current.clientWidth - Y_SCALE_AXIS_WIDTH);
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [chartRef.current]);

  return (
    <Section padding={'24px 24px 0 24px'}>
      <div ref={chartRef} style={{ width: '100%', height: 0 }} />
      <Cluster gap={8} wrap="nowrap" width="initial" align="stretch">
        {/* y軸 */}
        <Cluster
          gap={16}
          wrap="nowrap"
          width="initial"
          align="stretch"
          height={`calc(100% - ${X_SCALE_AXIS_HEIGHT}px)`}
        >
          <YAxis />
          <YAxisScale thresholds={yThresholds} />
        </Cluster>

        <Stack gap={8} align="stretch">
          {/* 本体 */}
          <Cluster gap={4} wrap="nowrap" width="initial" height={346}>
            <SegmentsColumn segments={[props.restSegment]} />
            {xThresholds
              .filter((threshold) => threshold > 0)
              .map((threshold) => (
                <SegmentsColumn
                  key={threshold}
                  segments={segmentsByXThreshold[threshold]}
                />
              ))}
          </Cluster>

          {/* x軸 */}
          <Stack gap={16} align="stretch">
            <XAxisScale
              thresholds={xThresholds}
              factor={props.xThresholdFactor}
            />
            {chartWidth && <XAxis width={chartWidth} />}
          </Stack>
        </Stack>
      </Cluster>
    </Section>
  );
};

const YAxisScale: React.FC<{ thresholds: number[] }> = (props) => (
  <Stack gap={4} align="flex-start" width="17px">
    {props.thresholds
      .filter((threshold) => threshold > 0)
      .reverse()
      .map((threshold) => (
        <div key={threshold} className={styles.axisScaleWrapperY}>
          <div className={styles.axisScaleY}>{threshold * 100}</div>
        </div>
      ))}
    <div className={styles.axisScaleWrapperY}>{/* spacer */}</div>
  </Stack>
);

const YAxis: React.FC = () => (
  <svg
    width="26"
    height="348"
    viewBox="0 0 26 348"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <text
      fill="#08151A"
      fillOpacity="0.6"
      fontSize="14"
      fontWeight="bold"
      letterSpacing="0.5em"
      writingMode="vertical-rl"
    >
      <tspan x="10" y="50%" textAnchor="middle">
        紹介入院率（％）
      </tspan>
    </text>
    <path d="M24 346L24 0L16 9" stroke="#08151A" strokeOpacity="0.6" />
  </svg>
);

const XAxisScale: React.FC<{
  thresholds: number[];
  factor: number;
}> = (props) => (
  <Cluster gap={4} wrap="nowrap" width="initial">
    <span className={styles.axisScaleWrapperX}>{/* spacer */}</span>
    {props.thresholds.map((threshold) => (
      <div key={threshold} className={styles.axisScaleWrapperX}>
        <div className={styles.axisScaleX}>{threshold * props.factor}</div>
      </div>
    ))}
  </Cluster>
);

const XAxis: React.FC<{ width: number }> = (props) => (
  <svg
    viewBox={`0 0 ${props.width} 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M0 1H${props.width}L${props.width - 9} 10`}
      stroke="#08151A"
      strokeOpacity="0.6"
    />
    <text
      fill="#08151A"
      fillOpacity="0.6"
      fontSize="14"
      fontWeight="bold"
      letterSpacing="0.04em"
    >
      <tspan x="50%" y="21.6" textAnchor="middle">
        紹介数（件）
      </tspan>
    </text>
  </svg>
);

const SegmentsColumn: React.FC<{
  segments: Pick<
    ChartData['segments'][number],
    'label' | 'count' | 'fontColor' | 'background'
  >[];
}> = (props) => (
  <div className={styles.segmentsColumn}>
    {props.segments.map((segment) => (
      <div
        key={segment.label}
        className={clsx(
          styles.segment,
          segment.fontColor === 'white' ? styles.fontWhite : styles.fontGray,
        )}
        style={{ background: segment.background }}
      >
        <div className={styles.segmentLabel}>{segment.label}</div>
        <div className={styles.segmentDescription}>
          {numberFormat(segment.count)}施設
        </div>
      </div>
    ))}
  </div>
);
