import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { MonthPicker } from '@/src/components/foundations/Forms/MonthPicker';
import { Pagination } from '@/src/components/foundations/Navigations/Pagination';

import { RankingTable } from './RankingTable';
import styles from './styles.module.scss';

import clsx from 'clsx';

import { SearchFilter } from '../SearchFilter';
import { SearchForm } from '../SearchForm';

type Props = {
  monthPicker?: React.ComponentProps<typeof MonthPicker>;
  searchForm: React.ComponentProps<typeof SearchForm>;
  searchFilter: React.ComponentProps<typeof SearchFilter>;
  rankingTable: React.ComponentProps<typeof RankingTable>;
  pagination: React.ComponentProps<typeof Pagination>;
};

export const RankingContainerPresenter: React.FC<Props> = (props) => {
  const title = props.rankingTable.mode === 'monthly' ? '月次' : '年次';
  return (
    <div className={styles.base}>
      <Card>
        <div className={styles.header}>
          <div className={styles.title}>紹介増減数 {title}</div>
          {props.monthPicker && (
            <div
              className={clsx([styles.searchFormPeriod, styles.monthPicker])}
            >
              <MonthPicker {...props.monthPicker} isInForm />
            </div>
          )}
          <div className={styles.searchFormArea}>
            <SearchForm {...props.searchForm} />
          </div>
          <div className={styles.searchFilter}>
            <SearchFilter {...props.searchFilter} />
          </div>
        </div>
        <RankingTable {...props.rankingTable} />
        {props.pagination.lastPage !== 0 && (
          <div className={styles.paginationWrapper}>
            <Pagination {...props.pagination} />
          </div>
        )}
      </Card>
    </div>
  );
};
