import { PrefectureSearchTextField } from '@/src/components/blocks/PrefectureSearchTextField';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Search } from '@/src/components/foundations/DesignToken/Icons';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { UseFormReturn } from '@/src/hooks/useForm';

import { Schema } from '../../form';

type Props = {
  form: UseFormReturn<Schema>;
  onSubmit: VoidFunction;
};

export const AreaForm: React.FC<Props> = (props) => {
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Cluster gap={16} align="stretch" wrap="nowrap" width="initial">
        <PrefectureSearchTextField
          optionIdPrefix="prefecture"
          mode="normal"
          width={192}
          {...props.form.register('prefecture')}
          value={props.form.watch('prefecture') ?? ''}
          setValue={(value) =>
            props.form.setValue('prefecture', value, { shouldValidate: true })
          }
        />
        <Button
          color="primary"
          icon={{ body: <Search />, position: 'left' }}
          onClick={props.onSubmit}
          isDisabled={!props.form.formState.isValid}
          submitType="submit"
        >
          検索
        </Button>
      </Cluster>
    </form>
  );
};
