import { dataAnalysisApi, referralsApi } from '@/src/api';
import {
  PostDataAnalysisEffectivenessVerificationCommunicationsResponse as SearchResult,
  PostDataAnalysisEffectivenessVerificationCommunicationsRequestSortEnum as SearchSort,
  CommunicationType,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAccount } from '@/src/hooks/useAccount';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';
import { currentMonthAgo, today, lastDateTimeOfMonth } from '@/src/utils/date';
import { formatDateMonth } from '@/src/utils/formatDate';

import { InputMap as HeaderInputMap } from './Header/presenter';
import { EffectivenessVerificationsPresenter } from './presenter';

import isAfter from 'date-fns/isAfter';
import { useState, useEffect } from 'react';

type IsDoctorAttended = 'all' | 'isDoctorAttended' | 'isNotDoctorAttended';

type InputMap = HeaderInputMap & {
  communicationType: CommunicationType;
  isDoctorAttended: IsDoctorAttended;
  currentPage: number;
  month: Date;
  sort: SearchSort;
};

const INITIAL_CURRENT_PAGE = 1;
const INITIAL_AGGREGATION_PERIOD = 30;
const INITIAL_IS_DOCTOR_ATTENDED = 'all';
const INITIAL_SORT = SearchSort.Desc;
const ONE_PAGE_MAX_COUNT = 50;

export const EffectivenessVerifications: React.FC = () => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);
  const defaultOwnDepartmentId = useAccount().account.ownDepartmentId;

  const form = useFormWrapper<InputMap>({
    mode: 'all',
    defaultValues: {
      aggregationPeriod: INITIAL_AGGREGATION_PERIOD,
      prefecture: '',
      area: '',
      isDoctorAttended: INITIAL_IS_DOCTOR_ATTENDED,
      // 最新紹介日の取得までに時間がかかるため、初期値は今月とする
      month: today(),
      currentPage: INITIAL_CURRENT_PAGE,
      sort: INITIAL_SORT,
      communicationType: CommunicationType.Visit,
      ownDepartmentIds: [],
    },
  });
  const inputMap = form.watch();

  const setPrefecture = (value: string) => {
    form.setValue('prefecture', value);
  };
  const resetPage = () => {
    form.setValue('currentPage', INITIAL_CURRENT_PAGE);
  };

  const throwFetchError = useFetchError();

  const request = form.handleSubmit(async (values) => {
    try {
      setSearchResult(null);

      const requestBodies = {
        ...values,
        month: formatDateMonth(values.month),
        page: values.currentPage,
        isDoctorAttended: (() => {
          if (values.communicationType !== CommunicationType.Visit) {
            return null;
          }

          switch (values.isDoctorAttended) {
            case 'all':
              return null;
            case 'isDoctorAttended':
              return true;
            case 'isNotDoctorAttended':
              return false;
          }
        })(),
        communicationType: values.communicationType,
        departmentIds: values.ownDepartmentIds,
      };

      const response =
        await dataAnalysisApi.postDataAnalysisEffectivenessVerificationCommunications(
          {
            postDataAnalysisEffectivenessVerificationCommunicationsRequest:
              requestBodies,
          },
        );

      setSearchResult(response);
    } catch (error) {
      throwFetchError(500);
    }
  });

  const requestWithResetPage = () => {
    resetPage();
    request();
  };

  const onChangeSortType = () => {
    const nextSort =
      inputMap.sort === SearchSort.Desc ? SearchSort.Asc : SearchSort.Desc;
    form.setValue('sort', nextSort);
    requestWithResetPage();
  };

  const initializeMonthForm = async () => {
    try {
      const lastReferralDate = (await referralsApi.getLastReferral())
        ?.referralDate;

      if (lastReferralDate) {
        // month 前後の30日間分の集計を表示するため、最終紹介日にすると、後の30日間分の紹介数はかならず 0 になる。そのため、-1 月している
        // - ref: https://www.notion.so/medup/92293c940dbe4d62911bfb14f88ff951?pvs=4#7a82cde4b63d42fa93d06b4fe743023e
        form.setValue('month', currentMonthAgo(new Date(lastReferralDate), 1));
      }
      // 最新紹介がない場合(lastReferralDate === null)は、今月とするが初期値は今月としているため、特になにかする必要はない
    } catch (error) {
      throwFetchError(500);
    }
  };
  useEffect(() => {
    initializeMonthForm();
  }, []);

  useEffect(() => {
    if (defaultOwnDepartmentId !== null) {
      form.setValue('ownDepartmentIds', [defaultOwnDepartmentId.toString()]);
    }
  }, [defaultOwnDepartmentId]);

  useEffect(() => {
    requestWithResetPage();
  }, [
    inputMap.aggregationPeriod,
    inputMap.isDoctorAttended,
    inputMap.ownDepartmentIds,
    inputMap.month,
  ]);

  return (
    <EffectivenessVerificationsPresenter
      visitTable={{
        ...inputMap,
        isLoading: Boolean(!searchResult),
        communications: searchResult,
        onePageMaxCount: ONE_PAGE_MAX_COUNT,
        onChangeSortType,
      }}
      header={{
        monthPicker: {
          value: inputMap.month,
          placement: 'bottomEnd',
          onChange: (date: Date) => {
            form.setValue('month', date);
          },
          disabledDate: (date?: Date) => {
            return date ? isAfter(date, lastDateTimeOfMonth()) : false;
          },
        },
        form: {
          onSubmit: requestWithResetPage,
          isDisabled: false,
          isDoctorAttended: {
            ...form.register('isDoctorAttended'),
            errorMessages: [],
            options: [
              { label: '医師面談すべて', value: 'all' },
              { label: '医師面談あり', value: 'isDoctorAttended' },
              { label: '医師面談なし', value: 'isNotDoctorAttended' },
            ],
          },
          aggregationPeriod: {
            ...form.register('aggregationPeriod', { valueAsNumber: true }),
            errorMessages: [],
            options: [
              { label: '30日間', value: 30 },
              { label: '60日間', value: 60 },
              { label: '90日間', value: 90 },
            ],
          },
          prefecture: {
            ...form.register('prefecture'),
            errorMessages: [],
            value: inputMap.prefecture,
            setValue: setPrefecture,
          },
          area: {
            ...form.register('area'),
            errorMessages: [],
          },
          ownDepartmentIds: {
            ...form.register('ownDepartmentIds'),
            errorMessages: [],
            selectedOwnDepartmentIds: inputMap.ownDepartmentIds,
            onSubmit: (ownDepartmentIds) => {
              form.setValue('ownDepartmentIds', ownDepartmentIds);
            },
          },
        },
        tab: {
          selectedValue: inputMap.communicationType,
          onClickValue: (value) => {
            form.setValue('communicationType', value);
            requestWithResetPage();
          },
        },
        isCommunicationTypeVisit:
          inputMap.communicationType === CommunicationType.Visit,
      }}
      pagination={{
        currentPage: inputMap.currentPage,
        lastPage: searchResult?.totalPage ?? 0,
        onClickCallback: (page: number) => {
          form.setValue('currentPage', page);
          request();
        },
      }}
    />
  );
};
