import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonResponse,
} from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';
import { DeepRequired } from '@/src/types/utilities';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: DeepRequired<
    Pick<
      React.ComponentProps<typeof MonthRangePicker>,
      'startDate' | 'endDate' | 'onChange' | 'allowedDateRange'
    >
  >;
  departments: {
    selected: GetOwnDepartmentsResponse['departments'];
    onSubmit: React.ComponentProps<typeof OwnDepartmentFilterChip>['onSubmit'];
  };
};
type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: PostChartReferralsComparisonResponse['referrals'];
};

type Props = BaseProps & {
  contents: ContentsNormal | ContentsLoading;
};

export const DateRangeAnnualOwnDepartmentsUnitReferralsCardPresenter: React.FC<
  Props
> = (props) => (
  <Card>
    <div className={styles.header}>
      <div className={styles.title}>診療科別期間指定比較</div>
      <div className={styles.control}>
        <MonthRangePicker {...props.dateRange} placement="bottomEnd" />
      </div>
    </div>
    <div className={styles.subControls}>
      <OwnDepartmentFilterChip
        selectedOwnDepartmentIds={props.departments.selected.map((_) => _.key)}
        onSubmit={props.departments.onSubmit}
      />
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.contents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={CHART_SIZE}
                mode="stack"
                {...createChartData(
                  props.contents.referrals,
                  props.departments.selected,
                )}
              />
            );
        }
      })()}
    </div>
  </Card>
);
