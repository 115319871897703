import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { useGetLastReferral } from '@/src/hooks/fetcher/useGetLastReferral';
import { useAccount } from '@/src/hooks/useAccount';

import { SelectGroupMedicalInstitutions } from './SelectGroupMedicalInstitutions';
import { GroupsPresenter } from './presenter';

import { useState } from 'react';

type SelectMedicalInstitution = React.ComponentProps<
  typeof SelectGroupMedicalInstitutions
>['groupMedicalInstitutions'][0];

export const Groups: React.FC = () => {
  const lastReferralResponse = useGetLastReferral();

  const initialGroups =
    useAccount().account.tenant?.groupMedicalInstitutions?.map((g) => ({
      ...g,
      isSelected: true,
    }));

  const [groups, setGroups] = useState<SelectMedicalInstitution[]>(
    initialGroups ?? [],
  );

  const handleSelected = (selectedGroup: SelectMedicalInstitution) => {
    const newSelectedGroups = groups.map((g) => ({
      ...g,
      isSelected: g.id === selectedGroup.id ? !g.isSelected : g.isSelected,
    }));
    setGroups(newSelectedGroups);
  };

  if (!lastReferralResponse.data) return <LoadingCircle />;

  return (
    <GroupsPresenter
      groupMedicalInstitutions={groups}
      selectGroupMedicalInstitution={(g: SelectMedicalInstitution) =>
        handleSelected(g)
      }
      lastReferral={lastReferralResponse.data}
    />
  );
};
