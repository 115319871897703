import { chartsApi } from '@/src/api';
import {
  PostChartReferralsRequest,
  PostChartReferralsResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    searchParams: Omit<PostChartReferralsRequest, 'ownDepartmentIds'>,
    successCallback: (result: PostChartReferralsResponse) => void,
  ) => {
    try {
      const response = await chartsApi.postChartReferrals({
        postChartReferralsRequest: {
          ...searchParams,
          ownDepartmentIds: null,
        },
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
