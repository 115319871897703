import { reverseReferralsApi } from '@/src/api';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';

export const useGetLastReverseReferral = () => {
  const throwFetchError = useFetchError();

  const response = useAsyncFetch(
    apiKey('reverseReferralsApi', 'getLastReverseReferral'),
    () => reverseReferralsApi.getLastReverseReferral(),
  );

  if (response.error != null) {
    throwFetchError(response.error);
  }

  return response;
};
