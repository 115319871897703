import {
  GetOwnDepartmentsResponse,
  PostChartReferralsComparisonAnnualResponse,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { getColor, getStackColor } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  referrals: PostChartReferralsComparisonAnnualResponse['referrals'],
  selectedOwnDepartments: GetOwnDepartmentsResponse['departments'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const departmentIdsList = selectedOwnDepartments.map((item) => item.key);

  return {
    data: referrals
      .map((r) => {
        const stacks = r.items.reduce<Record<string, number>>((res, cur) => {
          if (cur.key) {
            const k = cur.key + ' - ' + String(cur.department);
            res[k] = cur.value;
          }
          return res;
        }, {});

        return {
          name: r.label,
          stacks,
        };
      })
      .reverse(),
    charts: referrals.flatMap((r) => {
      const passedItems = r.items.filter((i) => !i.isRemainingPeriod);
      const remainingItems = r.items.filter((i) => i.isRemainingPeriod);

      const res = passedItems.concat(remainingItems).map((item) => {
        if (item.isRemainingPeriod) {
          return {
            key: item.key + ' - ' + String(item.department),
            name: item.key,
            color: getStackColor(
              departmentIdsList.indexOf(String(item.department)),
            ),
          };
        } else {
          return {
            key: item.key + ' - ' + String(item.department),
            name: item.key,
            color: getColor(departmentIdsList.indexOf(String(item.department))),
          };
        }
      });

      return res;
    }),
    payload: selectedOwnDepartments.map((item) => {
      return {
        value: item.value,
        color: getColor(departmentIdsList.indexOf(item.key)),
      };
    }),
  };
};
