import {
  PostChartReferralsRequestUnitTypeEnum,
  NullableReferral,
} from '@/src/api/generated';
import { formatDate } from '@/src/utils/formatDate';

import { useAction } from './action';
import { AllReferralsCardPresenter } from './presenter';
import { useChartState, actionType } from './reducer';

import { useEffect } from 'react';

const UNIT_TYPE = {
  month: PostChartReferralsRequestUnitTypeEnum.Month,
  year: PostChartReferralsRequestUnitTypeEnum.Year,
} as const;

type Props = {
  lastReferral: NullableReferral;
};

export const AllReferralsCard: React.FC<Props> = (props) => {
  const { state, dispatch } = useChartState({
    referralDate: props.lastReferral.referralDate,
  });
  const { request } = useAction();

  const reloadData = () => {
    request(
      {
        unitType: state.params.unitType,
        period: {
          startDate: formatDate(state.params.period.startDate),
          endDate: formatDate(state.params.period.endDate),
        },
      },
      (res) => {
        dispatch({
          type: actionType.setReferrals,
          payload: res.referrals,
        });
      },
    );
  };

  useEffect(reloadData, [state.params]);

  return (
    <AllReferralsCardPresenter
      dateRange={{
        ...state.params.period,
        onChange: (selectedRange) => {
          dispatch({
            type: actionType.setPeriod,
            payload: selectedRange,
          });
        },
      }}
      dateUnit={{
        selectedUnit: state.params.unitType,
        options: ['month', 'year'],
        onClickUnit: (selectedUnitType) => {
          dispatch({
            type: actionType.setUnitType,
            payload: UNIT_TYPE[selectedUnitType],
          });
        },
      }}
      searchContents={
        state.referrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              referrals: state.referrals,
            }
      }
    />
  );
};
