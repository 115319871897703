import { chartsApi } from '@/src/api';
import {
  PostChartVisitsRequest,
  PostChartVisitsResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    params: Omit<PostChartVisitsRequest, 'staffIds'>,
    successCallback: (result: PostChartVisitsResponse) => void,
  ) => {
    try {
      const response = await chartsApi.postChartVisits({
        postChartVisitsRequest: {
          ...params,
          staffIds: null,
        },
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
