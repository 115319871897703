import { PostChartReferralsResponse } from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { COLOR } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  referrals: PostChartReferralsResponse['referrals'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  return {
    data: referrals.map((r) => ({
      name: r.label,
      stacks: {
        value: r.items[0]?.value ?? 0,
      },
    })),
    charts: [
      {
        key: 'value',
        name: '全診療科',
        color: COLOR.PRIMARY,
      },
    ],
    payload: [
      {
        value: '全診療科',
        color: COLOR.PRIMARY,
      },
    ],
  };
};
