import {
  GetOwnDepartmentsResponse,
  NullableReverseReferral,
  PostChartBothReferralsComparisonRequestUnitTypeEnum,
} from '@/src/api/generated';
import { formatDate } from '@/src/utils/formatDate';

import { useAction } from './action';
import { BothReferralsCardPresenter } from './presenter';
import { useChartState, actionType } from './reducer';

import { useEffect } from 'react';

const UNIT_TYPE = {
  month: PostChartBothReferralsComparisonRequestUnitTypeEnum.Month,
  year: PostChartBothReferralsComparisonRequestUnitTypeEnum.Year,
} as const;

type Props = {
  lastReverseReferral: NullableReverseReferral;
  defaultDepartments: GetOwnDepartmentsResponse['departments'];
  departments: GetOwnDepartmentsResponse['departments'];
};

export const BothReferralsCard: React.FC<Props> = (props) => {
  const { state, dispatch } = useChartState({
    reverseReferralDate: props.lastReverseReferral.reverseReferralDate,
    departments: props.defaultDepartments,
  });
  const { request } = useAction();

  useEffect(() => {
    request(
      {
        unitType: state.params.unitType,
        period: {
          startDate: formatDate(state.params.period.startDate),
          endDate: formatDate(state.params.period.endDate),
        },
        ownDepartmentIds: state.params.departments.map((_) => _.key),
      },
      (result) => {
        dispatch({
          type: actionType.setBothReferrals,
          payload: result.bothReferrals,
        });
      },
    );
  }, [state.params]);

  return (
    <BothReferralsCardPresenter
      dateRange={{
        ...state.params.period,
        onChange: (selectedRange) => {
          dispatch({
            type: actionType.setPeriod,
            payload: selectedRange,
          });
        },
      }}
      dateUnit={{
        selectedUnit: state.params.unitType,
        options: ['month', 'year'],
        onClickUnit: (selectedUnitType) => {
          dispatch({
            type: actionType.setUnitType,
            payload: UNIT_TYPE[selectedUnitType],
          });
        },
      }}
      departments={{
        selected: state.params.departments,
        onSubmit: (departments) => {
          dispatch({
            type: actionType.setDepartments,
            payload: props.departments.filter((_) =>
              departments.includes(_.key),
            ),
          });
        },
      }}
      contents={
        state.bothReferrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              bothReferrals: state.bothReferrals,
            }
      }
    />
  );
};
