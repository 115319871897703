import { NullableReferral } from '@/src/api/generated';

import { AllReferralsCard } from './AllReferralsCard';
import { AnnualReferralsCard } from './AnnualReferralsCard';
import { DateRangeAnnualReferralsCard } from './DateRangeAnnualReferralsCard';
import { MonthlyReferralsCard } from './MonthlyReferralsCard';
import { SelectGroupMedicalInstitutions } from './SelectGroupMedicalInstitutions';
import styles from './styles.module.scss';

type Props = React.ComponentProps<typeof SelectGroupMedicalInstitutions> & {
  lastReferral: NullableReferral;
};

export const GroupsPresenter: React.FC<Props> = (props) => {
  const groupMedicalInstitutions = props.groupMedicalInstitutions.filter(
    (m) => m.isSelected,
  );

  return (
    <div>
      <SelectGroupMedicalInstitutions {...props} />
      <div className={styles.charts}>
        <AllReferralsCard
          groupMedicalIstitutions={groupMedicalInstitutions}
          lastReferral={props.lastReferral}
        />
        <div className={styles.sideBySide}>
          <div className={styles.annualReferralsCard}>
            <AnnualReferralsCard
              groupMedicalIstitutions={groupMedicalInstitutions}
            />
          </div>
          <DateRangeAnnualReferralsCard
            groupMedicalIstitutions={groupMedicalInstitutions}
            lastReferral={props.lastReferral}
          />
        </div>
        <MonthlyReferralsCard
          groupMedicalIstitutions={groupMedicalInstitutions}
        />
      </div>
    </div>
  );
};
