import {
  GetOwnDepartmentsResponse,
  PostChartReverseReferralsComparisonDepartmentsResponse,
} from '@/src/api/generated';

import { useAction } from './action';
import { VISIBLE_ITEMS_COUNT } from './const';
import { OwnDepartmentReverseReferralsCardPresenter } from './presenter';
import { useCurrentItemIndexState } from './reducer';
import { createChartData } from './selector';

import { useState, useEffect, useMemo } from 'react';

type Props = {
  departments: GetOwnDepartmentsResponse['departments'];
};

export const OwnDepartmentReverseReferralsCard: React.FC<Props> = (props) => {
  const [reverseReferrals, setReverseReferrals] = useState<
    | PostChartReverseReferralsComparisonDepartmentsResponse['reverseReferrals']
    | null
  >(null);

  const { request } = useAction();

  useEffect(() => {
    request((result) => {
      setReverseReferrals(result.reverseReferrals);
    });
  }, []);

  const chartData = useMemo(
    () =>
      reverseReferrals !== null
        ? createChartData(reverseReferrals, props.departments)
        : null,
    [reverseReferrals],
  );

  const currentItemIndexState = useCurrentItemIndexState(
    props.departments.length,
  );

  return (
    <OwnDepartmentReverseReferralsCardPresenter
      contents={
        reverseReferrals !== null && chartData !== null
          ? {
              status: 'normal',
              chartData: {
                charts: chartData.charts,
                payload: chartData.payload,
                data: chartData.displayData(
                  currentItemIndexState.state.currentItemIndex,
                ),
              },
              chartSlider: {
                visibleItemsCount: VISIBLE_ITEMS_COUNT,
                itemsCount: props.departments.length,
                currentItemIndex: currentItemIndexState.state.currentItemIndex,
                onClickNext: () =>
                  currentItemIndexState.dispatch({ type: 'SLIDE_NEXT' }),
                onClickPrev: () =>
                  currentItemIndexState.dispatch({ type: 'SLIDE_PREV' }),
              },
            }
          : {
              status: 'loading',
            }
      }
    />
  );
};
