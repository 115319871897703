import { currentFiscalYearAgo } from '.';

import { generateSequence } from '../generateSequence';

export const toFiscalYear = (date: Date): number =>
  currentFiscalYearAgo(date, 0).getFullYear();

export const toFiscalYearStartDate = (fiscalYear: number): Date =>
  new Date(`${fiscalYear}-04-01`);

export const generateFiscalYearSequence = (from: Date, to: Date): number[] =>
  generateSequence(toFiscalYear(from), toFiscalYear(to));
