import { chartsApi } from '@/src/api';
import {
  PostChartReferralsRequest,
  PostChartReferralsResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    searchParams: PostChartReferralsRequest,
    successCallback: (result: PostChartReferralsResponse) => void,
  ) => {
    if (
      searchParams.ownDepartmentIds === null ||
      searchParams.ownDepartmentIds.length === 0
    )
      return;

    try {
      const response = await chartsApi.postChartReferrals({
        postChartReferralsRequest: searchParams,
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
