import {
  WIDTH_FOR_LEFT_Y_AXIS,
  WIDTH_FOR_RIGHT_Y_AXIS,
} from './components/Chart';
import { ChartWithControlPresenter } from './presenter';
import { useCurrentItemIndexState } from './reducer';

import { useEffect, useRef } from 'react';

import { State } from '../../reducer';
import { ChartData } from '../../types';

type Props = {
  dateUnit: State['dateUnit'];
  setDateUnit: (dateUnit: State['dateUnit']) => void;
  data: ChartData | undefined;
};

const MENU_WIDTH = 190;
const MIN_WINDOW_WIDTH = 1250 + MENU_WIDTH;
const MAIN_CONTENT_HORIZONTAL_PADDING = 80;
const CONTAINER_HORIZONTAL_PADDING = 48;

const MIN_VISIBLE_ITEMS_COUNT = 10;

// 画面の最小サイズで10個のデータを表示する
const WIDTH_PER_COUNT = Math.floor(
  (MIN_WINDOW_WIDTH -
    MENU_WIDTH -
    MAIN_CONTENT_HORIZONTAL_PADDING -
    CONTAINER_HORIZONTAL_PADDING -
    WIDTH_FOR_LEFT_Y_AXIS -
    WIDTH_FOR_RIGHT_Y_AXIS) /
    MIN_VISIBLE_ITEMS_COUNT,
);

export const ChartWithControl: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const currentItemIndexState = useCurrentItemIndexState(
    props.data?.length ?? 0,
    MIN_VISIBLE_ITEMS_COUNT,
  );
  const visibleItemsCount = ref.current
    ? Math.floor(
        (ref.current.clientWidth -
          CONTAINER_HORIZONTAL_PADDING -
          WIDTH_FOR_LEFT_Y_AXIS -
          WIDTH_FOR_RIGHT_Y_AXIS) /
          WIDTH_PER_COUNT,
      )
    : MIN_VISIBLE_ITEMS_COUNT;

  // windowのりサイズなどで表示範囲の終端がデータ数を超えた場合に表示範囲を調整する
  useEffect(() => {
    if (props.data) {
      currentItemIndexState.dispatch({
        type: 'CALCULATE_CURRENT_ITEM_INDEX',
        payload: {
          itemsCount: props.data.length ?? 0,
          visibleItemsCount,
        },
      });
    }
  }, [visibleItemsCount]);

  useEffect(() => {
    if (props.data) {
      currentItemIndexState.dispatch({
        type: 'RESET',
        payload: {
          itemsCount: props.data.length ?? 0,
          visibleItemsCount,
        },
      });
    }
  }, [props.data]);

  return (
    <div ref={ref}>
      <ChartWithControlPresenter
        data={props.data}
        dateUnit={props.dateUnit}
        setDateUnit={(dateUnit) => props.setDateUnit(dateUnit)}
        currentItemIndex={currentItemIndexState.state.currentItemIndex}
        itemsCount={props.data?.length ?? 0}
        visibleItemsCount={visibleItemsCount}
        onClickNext={() =>
          currentItemIndexState.dispatch({ type: 'SLIDE_NEXT' })
        }
        onClickPrev={() =>
          currentItemIndexState.dispatch({ type: 'SLIDE_PREV' })
        }
      />
    </div>
  );
};
