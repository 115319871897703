import { StaffFilterModal } from '@/src/components/features/UtilModal/StaffFilterModal';
import { Chip } from '@/src/components/foundations/Buttons/Chip';
import { useModal } from '@/src/hooks/useModal';

type Props = {
  selectedStaffIds: string[];
  onSubmit: React.ComponentProps<typeof StaffFilterModal>['onSubmit'];
};

export const StaffFilterChipPresenter: React.FC<Props> = (props) => {
  const staffFilterModal = useModal();

  return (
    <>
      <Chip
        size="small"
        status={props.selectedStaffIds.length > 0 ? 'active' : 'normal'}
        onClick={staffFilterModal.show}
      >
        {props.selectedStaffIds.length > 0
          ? `担当者：${props.selectedStaffIds.length}`
          : '担当者'}
      </Chip>
      {staffFilterModal.isShown && (
        <StaffFilterModal
          isShown={staffFilterModal.isShown}
          onClose={staffFilterModal.hide}
          selectedStaffIds={props.selectedStaffIds}
          onSubmit={(values) => {
            staffFilterModal.hide();
            props.onSubmit(values);
          }}
        />
      )}
    </>
  );
};
