import {
  NullableReferral,
  GetOwnDepartmentsResponse,
  PostChartReferralsRequestUnitTypeEnum,
} from '@/src/api/generated';
import { formatDate } from '@/src/utils/formatDate';

import { useAction } from './action';
import { OwnDepartmentsUnitReferralsCardPresenter } from './presenter';
import { useChartState, actionType } from './reducer';

import { useEffect } from 'react';

const UNIT_TYPE = {
  month: PostChartReferralsRequestUnitTypeEnum.Month,
  year: PostChartReferralsRequestUnitTypeEnum.Year,
} as const;

type Props = {
  lastReferral: NullableReferral;
  defaultDepartments: GetOwnDepartmentsResponse['departments'];
  departments: GetOwnDepartmentsResponse['departments'];
};

export const OwnDepartmentsUnitReferralsCard: React.FC<Props> = (props) => {
  const { state, dispatch } = useChartState({
    referralDate: props.lastReferral.referralDate,
    departments: props.defaultDepartments,
  });
  const { request } = useAction();

  const reloadData = () => {
    request(
      {
        unitType: state.params.unitType,
        period: {
          startDate: formatDate(state.params.period.startDate),
          endDate: formatDate(state.params.period.endDate),
        },
        ownDepartmentIds: state.params.departments.map((_) => _.key),
      },
      (res) => {
        dispatch({
          type: actionType.setReferrals,
          payload: res.referrals,
        });
      },
    );
  };

  useEffect(reloadData, [state.params]);

  return (
    <OwnDepartmentsUnitReferralsCardPresenter
      dateRange={{
        ...state.params.period,
        onChange: (selectedRange) => {
          dispatch({
            type: actionType.setPeriod,
            payload: selectedRange,
          });
        },
      }}
      dateUnit={{
        selectedUnit: state.params.unitType,
        options: ['month', 'year'],
        onClickUnit: (selectedUnitType) => {
          dispatch({
            type: actionType.setUnitType,
            payload: UNIT_TYPE[selectedUnitType],
          });
        },
      }}
      departments={{
        selected: state.params.departments,
        onSubmit: (selectedDepartmentIds) => {
          dispatch({
            type: actionType.setDepartments,
            payload: props.departments.filter((_) =>
              selectedDepartmentIds.includes(_.key),
            ),
          });
        },
      }}
      searchContents={
        state.referrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              referrals: state.referrals,
            }
      }
    />
  );
};
