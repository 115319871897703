import { GetOwnDepartmentsResponse } from '@/src/api/generated';

import { useAction } from './action';
import { AnnualDepartmentsUnitReferralsCardPresenter } from './presenter';
import { useChartState, actionType } from './reducer';

import { useEffect } from 'react';

type Props = {
  defaultDepartments: GetOwnDepartmentsResponse['departments'];
  departments: GetOwnDepartmentsResponse['departments'];
};

export const AnnualDepartmentsUnitReferralsCard: React.FC<Props> = (props) => {
  const { state, dispatch } = useChartState({
    departments: props.defaultDepartments,
  });
  const { request } = useAction();

  useEffect(() => {
    request(
      {
        ownDepartmentIds: state.params.departments.map((_) => _.key),
      },
      (res) => {
        dispatch({
          type: actionType.setReferrals,
          payload: res.referrals,
        });
      },
    );
  }, [state.params]);

  return (
    <AnnualDepartmentsUnitReferralsCardPresenter
      departments={{
        selected: state.params.departments,
        onSubmit: (departments) => {
          dispatch({
            type: actionType.setDepartments,
            payload: props.departments.filter((_) =>
              departments.includes(_.key),
            ),
          });
        },
      }}
      contents={
        state.referrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              referrals: state.referrals,
            }
      }
    />
  );
};
