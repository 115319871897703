import { chartsApi } from '@/src/api';
import { PostChartReferralsComparisonDepartmentsResponse } from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    successCallback: (
      result: PostChartReferralsComparisonDepartmentsResponse,
    ) => void,
  ) => {
    try {
      const response =
        await chartsApi.postChartReferralsComparisonDepartments();

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
