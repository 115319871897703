import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { useGetStaffs } from '@/src/hooks/fetcher/useGetStaffs';

import { VisitsPresenter } from './presenter';

export const Visits: React.FC = () => {
  const staffsResponse = useGetStaffs();

  return staffsResponse.data ? (
    <VisitsPresenter staffs={staffsResponse.data.staffs} />
  ) : (
    <CenteringContainer>
      <LoadingCircle />
    </CenteringContainer>
  );
};
