import {
  GetOwnDepartmentsResponse,
  PostChartReverseReferralsComparisonMonthlyResponse,
} from '@/src/api/generated';
import {
  Loading,
  WIDE_CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  departments: {
    selected: GetOwnDepartmentsResponse['departments'];
    onSubmit: React.ComponentProps<typeof OwnDepartmentFilterChip>['onSubmit'];
  };
};

type LoadingProps = {
  status: 'loading';
};
type NormalProps = {
  status: 'normal';
  reverseReferrals: PostChartReverseReferralsComparisonMonthlyResponse['reverseReferrals'];
};

type Props = BaseProps & {
  contents: LoadingProps | NormalProps;
};

export const MonthlyReverseReferralsCardPresenter: React.FC<Props> = (
  props,
) => (
  <div className={styles.card}>
    <div className={styles.header}>
      <div className={styles.title}>月次での年度比較</div>
      <div className={styles.control}>
        <OwnDepartmentFilterChip
          selectedOwnDepartmentIds={props.departments.selected.map(
            (_) => _.key,
          )}
          onSubmit={props.departments.onSubmit}
        />
      </div>
    </div>
    <div className={styles.chart}>
      {(() => {
        switch (props.contents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={WIDE_CHART_SIZE}
                mode="simple"
                {...createChartData(props.contents.reverseReferrals)}
              />
            );
        }
      })()}
    </div>
  </div>
);
