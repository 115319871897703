import { chartsApi } from '@/src/api';
import {
  PostChartReferralsComparisonRequest,
  PostChartReferralsComparisonResponse,
} from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    params: PostChartReferralsComparisonRequest,
    successCallback: (result: PostChartReferralsComparisonResponse) => void,
  ) => {
    if (
      params.ownDepartmentIds === null ||
      params.ownDepartmentIds.length === 0
    )
      return;

    try {
      const response = await chartsApi.postChartReferralsComparison({
        postChartReferralsComparisonRequest: {
          ...params,
        },
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
