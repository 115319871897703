import { DateRange } from '@/src/types/date';
import { today } from '@/src/utils/date';

import { sub } from 'date-fns';

// 「年度始め日」を返す
export const thisYearAprilFirstDate = (): Date => {
  const now = today();
  return new Date(`${now.getFullYear()}-04-01`);
};

// 「年度始め 〜 今日」を返す
export const fromFiscalYearStartToToday = (): DateRange => {
  const now = today();
  return fromFiscalYearStartToTargetDate(now);
};

// 「年度始め ~ 指定日」を返す
export const fromFiscalYearStartToTargetDate = (date: Date) => {
  const thisYearAprilFirstDate = new Date(`${date.getFullYear()}-04-01`);
  const lastYearAprilFirstDate = sub(thisYearAprilFirstDate, { years: 1 });

  const startDate =
    date >= thisYearAprilFirstDate
      ? thisYearAprilFirstDate
      : lastYearAprilFirstDate;

  return { startDate, endDate: date };
};
