import { PostChartReverseReferralsComparisonMonthlyResponse } from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { LIST } from '@/src/utils/chartColors';

const FISCAL_YEAR_ARRAY = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
const FISCAL_YEAR_KEYS = FISCAL_YEAR_ARRAY.map((key) => `${key}月`);

const BAR_COUNT = 3;
const COLOR_LIST = LIST.slice(0, BAR_COUNT).reverse();

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  reverseReferrals: PostChartReverseReferralsComparisonMonthlyResponse['reverseReferrals'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const charts = reverseReferrals.map((r, index) => {
    return {
      key: r.label,
      name: r.label,
      color: COLOR_LIST[index % COLOR_LIST.length],
    };
  });

  return {
    data: FISCAL_YEAR_KEYS.map((key) => {
      return {
        name: key,
        stacks: reverseReferrals.reduce<Record<string, number>>((res, cur) => {
          // NOTE: { [対象年度]: [対象月を検索した値] } を作る
          res[`${cur.label}`] =
            cur.items.find((item) => item.key === key)?.value ?? 0;
          return res;
        }, {}),
      };
    }),
    charts,
    payload: charts.map((c) => ({
      value: c.name,
      color: c.color,
    })),
  };
};
