import { useReducer } from 'react';

type State = {
  currentItemIndex: number;
};

type Action =
  | { type: 'SLIDE_NEXT' }
  | { type: 'SLIDE_PREV' }
  | {
      type: 'CALCULATE_CURRENT_ITEM_INDEX';
      payload: {
        itemsCount: number;
        visibleItemsCount: number;
      };
    }
  | {
      type: 'RESET';
      payload: {
        itemsCount: number;
        visibleItemsCount: number;
      };
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SLIDE_NEXT':
      return {
        ...state,
        currentItemIndex: state.currentItemIndex + 1,
      };
    case 'SLIDE_PREV':
      return {
        ...state,
        currentItemIndex: Math.max(state.currentItemIndex - 1, 0),
      };
    case 'CALCULATE_CURRENT_ITEM_INDEX': {
      const { itemsCount, visibleItemsCount } = action.payload;

      if (visibleItemsCount + state.currentItemIndex > itemsCount) {
        return {
          currentItemIndex: Math.max(itemsCount - visibleItemsCount, 0),
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case 'RESET': {
      const { itemsCount, visibleItemsCount } = action.payload;
      return createInitialState(itemsCount, visibleItemsCount);
    }
    default:
      return state;
  }
};

export const useCurrentItemIndexState = (
  itemsCount: number,
  visibleItemsCount: number,
) => {
  const [state, dispatch] = useReducer(
    reducer,
    createInitialState(itemsCount, visibleItemsCount),
  );
  return { state, dispatch };
};

const createInitialState = (
  itemsCount: number,
  visibleItemsCount: number,
): State => ({
  currentItemIndex:
    itemsCount > visibleItemsCount ? itemsCount - visibleItemsCount : 0,
});
