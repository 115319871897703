import { useForm } from '@/src/hooks/useForm';
import { prefectures } from '@/src/utils/prefectures';

import { z } from 'zod';

const schema = z.object({
  prefecture: z.enum(['', ...prefectures.map(({ name }) => name)]).nullable(),
});
export type Schema = z.infer<typeof schema>;

type Props = {
  prefecture?: string;
};

export const useSearchForm = (props: Props) => {
  const { form } = useForm(schema, {
    shouldUnregister: false,
    defaultValues: {
      prefecture: props.prefecture ?? null,
    },
  });

  return { form };
};
