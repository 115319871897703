import {
  GetOwnDepartmentsResponse,
  PostChartReferralsResponse,
} from '@/src/api/generated';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { getColor } from '@/src/utils/chartColors';

type BarChartProps = React.ComponentProps<typeof BarChart>;

export const createChartData = (
  referrals: PostChartReferralsResponse['referrals'],
  selectedOwnDepartments: GetOwnDepartmentsResponse['departments'],
): Pick<BarChartProps, 'data' | 'charts' | 'payload'> => {
  const data = referrals.map((r) => {
    const stacks = r.items.reduce<Record<string, number>>((res, cur) => {
      if (cur.key) {
        res[cur.key] = cur.value;
      }
      return res;
    }, {});

    return {
      name: r.label,
      stacks,
    };
  });

  const charts = selectedOwnDepartments.map((item, index) => {
    return {
      key: item.key,
      name: item.value,
      color: getColor(index),
    };
  });

  const payload = selectedOwnDepartments.map((item, index) => {
    return {
      value: item.value,
      color: getColor(index),
    };
  });

  return { data, charts, payload };
};
