import {
  PostDataAnalysisEffectivenessVerificationCommunicationsResponse as Communications,
  PostDataAnalysisEffectivenessVerificationCommunicationsRequestSortEnum as SearchSort,
  CommunicationType,
} from '@/src/api/generated';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Link } from '@/src/components/foundations/Utils/Link';
import { emptyText } from '@/src/utils/emptyText';
import { toDisplayFormat } from '@/src/utils/runningStatus';

import styles from './styles.module.scss';

import LocationOn from '@mui/icons-material/LocationOn';
import clsx from 'clsx';

type Props = {
  isLoading: boolean;
  communications: Communications | null;
  communicationType: CommunicationType;
  sort: SearchSort;
  currentPage: number;
  onePageMaxCount: number;
  onChangeSortType: VoidFunction;
  aggregationPeriod: number;
};

type IncreaseCountSortResponse = () => string;
type IncreaseCountResponse = (increaseCount: number) => string;

export const TablePresenter: React.FC<Props> = (props) => {
  const increaseCountSortIcon: IncreaseCountSortResponse = () => {
    return props.sort === SearchSort.Desc
      ? clsx(styles.sortIcon, styles.sortIconDesc)
      : clsx(styles.sortIcon, styles.sortIconAsc);
  };
  const increaseCountClassName: IncreaseCountResponse = (increaseCount) => {
    if (increaseCount > 0)
      return clsx(styles.increaseCountText, styles.increaseCountTextPlus);
    if (increaseCount < 0)
      return clsx(styles.increaseCountText, styles.increaseCountTextMinus);
    return clsx(styles.increaseCountText);
  };
  const increaseCountText: IncreaseCountResponse = (increaseCount) => {
    if (increaseCount > 0) return `+${increaseCount}`;
    if (increaseCount < 0) return String(increaseCount);
    return String(increaseCount);
  };
  const serialNumber = (index: number): string => {
    return props.currentPage === 1
      ? String(index + 1)
      : String(props.onePageMaxCount * (props.currentPage - 1) + index + 1);
  };

  return (
    <table className={styles.base}>
      <thead className={styles.head}>
        <tr className={styles.tr}>
          <th className={styles.no}>NO</th>
          <th className={styles.medicalInstitutionName}>医療機関名</th>
          <th className={styles.targetDate}>対象日</th>
          {props.communicationType === CommunicationType.Visit && (
            <th className={styles.doctorAttend}>医師面談</th>
          )}
          <th className={styles.referralCountBefore}>
            前{props.aggregationPeriod}日紹介数
          </th>
          <th className={styles.referralCountAfter}>
            後{props.aggregationPeriod}日紹介数
          </th>
          <th className={styles.increaseCount}>
            <button
              onClick={props.onChangeSortType}
              className={styles.increaseCountSort}
            >
              <span className={styles.increaseCountSortText}>増減数</span>
              <div className={increaseCountSortIcon()} />
            </button>
          </th>
        </tr>
      </thead>
      <tbody className={styles.body}>
        {((): JSX.Element | JSX.Element[] => {
          if (props.isLoading || !props.communications) {
            return (
              <tr>
                <td colSpan={7} className={styles.borderTop}>
                  <CenteringContainer>
                    <LoadingCircle />
                  </CenteringContainer>
                </td>
              </tr>
            );
          }

          if (props.communications.effectivenessVerifications.length === 0) {
            return (
              <tr>
                <td colSpan={7} className={styles.borderTop}>
                  <CenteringContainer>
                    <h2 className={styles.emptyText}>
                      指定された月に、コミュニケーションデータはありません
                    </h2>
                  </CenteringContainer>
                </td>
              </tr>
            );
          }

          return props.communications.effectivenessVerifications.map(
            (effectivenessVerification, index) => (
              <tr className={styles.tr} key={index}>
                <td className={styles.noText}>{serialNumber(index)}</td>
                <td>
                  <div className={styles.medicalInstitutionNameText}>
                    {effectivenessVerification.medicalInstitution.name ? (
                      <Link
                        href={`/crms/${effectivenessVerification.medicalInstitution.id}`}
                        isOpenAnotherTab
                      >
                        {toDisplayFormat(
                          effectivenessVerification.medicalInstitution
                            .runningStatus,
                        )}
                        {effectivenessVerification.medicalInstitution.name}
                      </Link>
                    ) : (
                      emptyText()
                    )}
                  </div>
                  <div className={styles.address}>
                    {effectivenessVerification.medicalInstitution.address ? (
                      <>
                        <LocationOn className={styles.locationOn} />
                        <span className={styles.addressText}>
                          {effectivenessVerification.medicalInstitution.address}
                        </span>
                      </>
                    ) : (
                      emptyText()
                    )}
                  </div>
                </td>
                <td>{effectivenessVerification.visitedAt}</td>
                {props.communicationType === CommunicationType.Visit && (
                  <td>
                    {effectivenessVerification.isDoctorAttended
                      ? 'あり'
                      : 'なし'}
                  </td>
                )}
                <td>{effectivenessVerification.referralCountBefore}</td>
                <td>{effectivenessVerification.referralCountAfter}</td>
                <td
                  className={increaseCountClassName(
                    effectivenessVerification.increaseCount,
                  )}
                >
                  {increaseCountText(effectivenessVerification.increaseCount)}
                </td>
              </tr>
            ),
          );
        })()}
      </tbody>
    </table>
  );
};
