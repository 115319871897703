import {
  MedicalInstitutionRunningStatusEnum,
  NullableMedicalInstitutionRunningStatusEnum,
} from '../api/generated';

/**
 * RunningStatusEnumを文字列表現に変換する
 */
export const toString = (
  status:
    | MedicalInstitutionRunningStatusEnum
    | NullableMedicalInstitutionRunningStatusEnum
    | undefined
    | null,
): string => {
  switch (status) {
    case undefined:
    case null:
    case MedicalInstitutionRunningStatusEnum.Open:
    case NullableMedicalInstitutionRunningStatusEnum.Open:
      return '';
    case MedicalInstitutionRunningStatusEnum.Closed:
    case NullableMedicalInstitutionRunningStatusEnum.Closed:
      return '閉院';
    case MedicalInstitutionRunningStatusEnum.Suspended:
    case NullableMedicalInstitutionRunningStatusEnum.Suspended:
      return '休止';
    case MedicalInstitutionRunningStatusEnum.Unknown:
    case NullableMedicalInstitutionRunningStatusEnum.Unknown:
      return '不明';
  }
};

/**
 * RunningStatusEnumを画面表示用フォーマットへ変換する
 */
export const toDisplayFormat = (
  status:
    | MedicalInstitutionRunningStatusEnum
    | NullableMedicalInstitutionRunningStatusEnum
    | undefined
    | null,
): string => {
  const label = toString(status);

  return label ? `【${label}】` : '';
};
