import { PostChartVisitsDoctorInterviewRatesResponse } from '@/src/api/generated';
import { LineChart } from '@/src/components/foundations/Charts/LineChart';
import { COLOR } from '@/src/utils/chartColors';

type LineChartProps = React.ComponentProps<typeof LineChart>;

export const createChartData = (
  doctorInterviewRates: PostChartVisitsDoctorInterviewRatesResponse['doctorInterviewRates'],
): Pick<LineChartProps, 'data' | 'charts' | 'payload'> => {
  return {
    data: doctorInterviewRates.map((v) => {
      return {
        name: v.label,
        values: v.items.reduce<Record<string, number>>((res, cur) => {
          res['value'] = cur.value;
          return res;
        }, {}),
      };
    }),
    charts: [
      {
        key: 'value',
        name: '全担当者',
        color: COLOR.PRIMARY,
      },
    ],
    payload: [
      {
        value: '全担当者',
        color: COLOR.PRIMARY,
      },
    ],
  };
};
