import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { useGetLastReverseReferral } from '@/src/hooks/fetcher/useGetLastReverseReferral';
import { useGetOwnDepartments } from '@/src/hooks/fetcher/useGetOwnDepartments';
import { useDefaultOwnDepartmentId } from '@/src/hooks/useDefaultOwnDepartmentId';

import { ReverseReferralsPresenter } from './presenter';

export const ReverseReferrals: React.FC = () => {
  const lastReverseReferral = useGetLastReverseReferral();
  const defaultOwnDepartmentId = useDefaultOwnDepartmentId();
  const ownDepartments = useGetOwnDepartments();

  return lastReverseReferral.data &&
    ownDepartments.data &&
    defaultOwnDepartmentId ? (
    <ReverseReferralsPresenter
      lastReverseReferral={lastReverseReferral.data}
      defaultDepartments={ownDepartments.data.departments.filter(
        (_) => _.key === defaultOwnDepartmentId,
      )}
      departments={ownDepartments.data.departments ?? []}
    />
  ) : (
    <CenteringContainer>
      <LoadingCircle />
    </CenteringContainer>
  );
};
