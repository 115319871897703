import {
  NullableReferral,
  GetOwnDepartmentsResponse,
} from '@/src/api/generated';
import { formatDate } from '@/src/utils/formatDate';

import { useAction } from './action';
import { DateRangeAnnualOwnDepartmentsUnitReferralsCardPresenter } from './presenter';
import { useChartState, actionType } from './reducer';

import { useEffect } from 'react';

type Props = {
  lastReferral: NullableReferral;
  defaultDepartments: GetOwnDepartmentsResponse['departments'];
  departments: GetOwnDepartmentsResponse['departments'];
};

export const DateRangeAnnualOwnDepartmentsUnitReferralsCard: React.FC<Props> = (
  props,
) => {
  const { state, dispatch } = useChartState({
    referralDate: props.lastReferral.referralDate,
    departments: props.defaultDepartments,
  });
  const { request } = useAction();

  useEffect(() => {
    request(
      {
        period: {
          startDate: formatDate(state.params.period.startDate),
          endDate: formatDate(state.params.period.endDate),
        },
        ownDepartmentIds: state.params.departments.map((_) => _.key),
      },
      (res) => {
        dispatch({
          type: actionType.setReferrals,
          payload: res.referrals,
        });
      },
    );
  }, [state.params]);

  return (
    <DateRangeAnnualOwnDepartmentsUnitReferralsCardPresenter
      departments={{
        selected: state.params.departments,
        onSubmit: (departments) => {
          dispatch({
            type: actionType.setDepartments,
            payload: props.departments.filter((_) =>
              departments.includes(_.key),
            ),
          });
        },
      }}
      dateRange={{
        ...state.params.period,
        allowedDateRange: state.allowedDateRange,
        onChange: (period) => {
          dispatch({
            type: actionType.setPeriod,
            payload: period,
          });
        },
      }}
      contents={
        state.referrals === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              referrals: state.referrals,
            }
      }
    />
  );
};
