import {
  PostChartVisitsNextActionRatesRequestUnitTypeEnum as UnitType,
  PostChartVisitsNextActionRatesResponse,
  Staff,
} from '@/src/api/generated';
import { AccountState } from '@/src/hooks/useAccount';
import { oneYearAgo, today } from '@/src/utils/date';

import { useReducer } from 'react';

type Params = {
  unitType: UnitType;
  period: { startDate: Date; endDate: Date };
  staffs: Staff[];
};

type State = {
  params: Params;
  nextActionRates:
    | PostChartVisitsNextActionRatesResponse['nextActionRates']
    | null;
};

export const actionType = {
  setUnitType: 'setUnitType',
  setPeriod: 'setPeriod',
  setStaffs: 'setStaffs',
  setNextActionRates: 'setNextActionRates',
} as const;

type Action =
  | {
      type: typeof actionType.setUnitType;
      payload: Params['unitType'];
    }
  | {
      type: typeof actionType.setPeriod;
      payload: Params['period'];
    }
  | {
      type: typeof actionType.setStaffs;
      payload: Params['staffs'];
    }
  | {
      type: typeof actionType.setNextActionRates;
      payload: State['nextActionRates'];
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.setUnitType:
      return {
        params: {
          ...state.params,
          unitType: action.payload,
        },
        nextActionRates: null,
      };
    case actionType.setPeriod:
      return {
        params: {
          ...state.params,
          period: action.payload,
        },
        nextActionRates: null,
      };
    case actionType.setStaffs:
      return {
        params: {
          ...state.params,
          staffs: action.payload,
        },
        nextActionRates: null,
      };
    case actionType.setNextActionRates:
      return {
        ...state,
        nextActionRates: action.payload,
      };
    default:
      return state;
  }
};

type Props = {
  account: AccountState;
  staffs: Staff[];
};

const createInitialState = (props: Props): State => {
  return {
    params: {
      unitType: UnitType.Month,
      period: {
        startDate: oneYearAgo(),
        endDate: today(),
      },
      //NOTE: ログインユーザーを初期値とし、なければスタッフの先頭の要素を初期値とする
      staffs: [
        props.staffs.find((s) => (props.account?.id ?? 0) === s.id) ??
          props.staffs[0],
      ],
    },
    nextActionRates: null,
  };
};

export const useChartState = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, createInitialState(props));

  return { state, dispatch };
};
