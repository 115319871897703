import { GroupMedicalInstitution } from '@/src/api/generated';
import { Button } from '@/src/components/foundations/Buttons/Button';

import styles from './styles.module.scss';

type GroupMedicalInstitutionSelect = GroupMedicalInstitution & {
  isSelected: boolean;
};

type Props = {
  groupMedicalInstitutions: GroupMedicalInstitutionSelect[];
  selectGroupMedicalInstitution: (g: GroupMedicalInstitutionSelect) => void;
};

export const SelectGroupMedicalInstitutionsPresenter: React.FC<Props> = (
  props,
) => (
  <div className={styles.base}>
    {props.groupMedicalInstitutions.map((g) => (
      <Button
        key={g.id}
        color={g.isSelected ? 'secondary' : 'outlineSecondary'}
        isLink={false}
        onClick={() => props.selectGroupMedicalInstitution(g)}
      >
        {g.name}
      </Button>
    ))}
  </div>
);
