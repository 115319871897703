import { Staff, PostChartVisitsRequestUnitTypeEnum } from '@/src/api/generated';
import { useAccount } from '@/src/hooks/useAccount';
import { formatDate } from '@/src/utils/formatDate';

import { useAction } from './action';
import { StaffVisitsCardPresenter } from './presenter';
import { useChartState, actionType } from './reducer';

import { useEffect } from 'react';

const UNIT_TYPE = {
  month: PostChartVisitsRequestUnitTypeEnum.Month,
  year: PostChartVisitsRequestUnitTypeEnum.Year,
} as const;

type Props = {
  staffs: Staff[];
};

export const StaffVisitsCard: React.FC<Props> = (props) => {
  const account = useAccount().account;
  const { state, dispatch } = useChartState({
    account,
    staffs: props.staffs,
  });

  const { request } = useAction();

  useEffect(() => {
    request(
      {
        period: {
          startDate: formatDate(state.params.period.startDate),
          endDate: formatDate(state.params.period.endDate),
        },
        unitType: state.params.unitType,
        staffIds: state.params.staffs.map((_) => _.id.toString()),
      },
      (res) => {
        dispatch({
          type: actionType.setVisits,
          payload: res.visits,
        });
      },
    );
  }, [state.params]);

  return (
    <StaffVisitsCardPresenter
      dateRange={{
        ...state.params.period,
        onChange: (dateRange) => {
          dispatch({
            type: actionType.setPeriod,
            payload: dateRange,
          });
        },
      }}
      dateUnit={{
        selectedUnit: state.params.unitType,
        options: ['month', 'year'],
        onClickUnit: (unitType) => {
          dispatch({
            type: actionType.setUnitType,
            payload: UNIT_TYPE[unitType],
          });
        },
      }}
      staffs={{
        selected: state.params.staffs,
        onSubmit: (staffIds) => {
          dispatch({
            type: actionType.setStaffs,
            payload: props.staffs.filter((_) =>
              staffIds.includes(_.id.toString()),
            ),
          });
        },
      }}
      contents={
        state.visits === null
          ? {
              status: 'loading',
            }
          : {
              status: 'normal',
              visits: state.visits,
            }
      }
    />
  );
};
