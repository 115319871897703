import {
  PostChartReferralsResponse,
  GetOwnDepartmentsResponse,
} from '@/src/api/generated';
import {
  Card,
  Loading,
  CHART_SIZE,
} from '@/src/components/features/DataAnalytics/Charts';
import { SelectDateUnit } from '@/src/components/features/DataAnalytics/SelectDateUnit';
import { OwnDepartmentFilterChip } from '@/src/components/features/FilterChip/OwnDepartmentFilterChip';
import { BarChart } from '@/src/components/foundations/Charts/BarChart';
import { MonthRangePicker } from '@/src/components/foundations/Forms/MonthRangePicker';

import { createChartData } from './selector';
import styles from './styles.module.scss';

type BaseProps = {
  dateRange: Pick<
    React.ComponentProps<typeof MonthRangePicker>,
    'startDate' | 'endDate' | 'onChange'
  >;
  dateUnit: React.ComponentProps<typeof SelectDateUnit<'month' | 'year'>>;
  departments: {
    selected: GetOwnDepartmentsResponse['departments'];
    onSubmit: React.ComponentProps<typeof OwnDepartmentFilterChip>['onSubmit'];
  };
};
type ContentsLoading = {
  status: 'loading';
};
type ContentsNormal = {
  status: 'normal';
  referrals: PostChartReferralsResponse['referrals'];
};

type Props = BaseProps & {
  searchContents: ContentsLoading | ContentsNormal;
};

export const OwnDepartmentsUnitReferralsCardPresenter: React.FC<Props> = (
  props,
) => (
  <Card>
    <div className={styles.header}>
      <div className={styles.title}>診療科別推移</div>
      <div className={styles.control}>
        <MonthRangePicker {...props.dateRange} placement="bottomEnd" />
      </div>
    </div>
    <div className={styles.subControls}>
      <OwnDepartmentFilterChip
        selectedOwnDepartmentIds={props.departments.selected.map((_) => _.key)}
        onSubmit={props.departments.onSubmit}
      />
      <SelectDateUnit {...props.dateUnit} />
    </div>
    <div className={styles.chart}>
      {((): JSX.Element => {
        switch (props.searchContents.status) {
          case 'loading':
            return <Loading />;
          case 'normal':
            return (
              <BarChart
                size={CHART_SIZE}
                mode="stack"
                {...createChartData(
                  props.searchContents.referrals,
                  props.departments.selected,
                )}
              />
            );
        }
      })()}
    </div>
  </Card>
);
