import {
  GetOwnDepartmentsResponse,
  NullableReverseReferral,
} from '@/src/api/generated';
import { Stack } from '@/src/components/foundations/Layouts/Stack';

import { BothReferralsCard } from './components/BothReferralsCard';
import { MonthlyMedicalInstitutionReferrals } from './components/MonthlyMedicalInstitutionReferrals';
import { MonthlyReverseReferralsCard } from './components/MonthlyReverseReferralsCard';
import { OwnDepartmentReverseReferralsCard } from './components/OwnDepartmentReverseReferralsCard';

type Departments = GetOwnDepartmentsResponse['departments'];

type Props = {
  lastReverseReferral: NullableReverseReferral;
  defaultDepartments: Departments;
  departments: Departments;
};

export const ReverseReferralsPresenter: React.FC<Props> = (props) => {
  return (
    <Stack gap="24px" align="flex-start">
      <BothReferralsCard
        lastReverseReferral={props.lastReverseReferral}
        defaultDepartments={props.defaultDepartments}
        departments={props.departments}
      />
      <OwnDepartmentReverseReferralsCard departments={props.departments} />
      <MonthlyReverseReferralsCard
        defaultDepartments={props.defaultDepartments}
        departments={props.departments}
      />
      <MonthlyMedicalInstitutionReferrals
        lastReverseReferral={props.lastReverseReferral}
      />
    </Stack>
  );
};
