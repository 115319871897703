import { chartsApi } from '@/src/api';
import { PostChartReferralsComparisonAnnualResponse } from '@/src/api/generated';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';

export const useAction = () => {
  const throwFetchError = useFetchError();

  const request = async (
    successCallback: (
      result: PostChartReferralsComparisonAnnualResponse,
    ) => void,
  ) => {
    try {
      const response = await chartsApi.postChartReferralsComparisonAnnual({
        postChartReferralsComparisonAnnualRequest: {
          ownDepartmentIds: null,
        },
      });

      successCallback(response);
    } catch (error) {
      throwFetchError(500);
    }
  };

  return { request };
};
