import { format } from 'date-fns';

export const formatDate = (date: Date): string => format(date, 'yyy-MM-dd');

type SeparatorTypeProps = '-' | '/';
export const formatDateMonth = (
  date: Date,
  separatorType: SeparatorTypeProps = '-',
): string => format(date, `yyy${separatorType}MM`);

export const formatDateYear = (date: Date): string => format(date, 'yyy');
